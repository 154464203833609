import React from "react";
import "./index.scss";
import Wear from "./wear";

function itemWear(props) {
    let { wearValue, acStickers, market_item_detail, type } = props;
    let { stickers } = acStickers || (market_item_detail ? market_item_detail?.msg : []);
    wearValue || (wearValue = 0);
    
    return (
        <>
            { ! (type === "detailPopover") && stickers && stickers[0] && (
                <div className="sticker">
                    { stickers.map((item, idx) => {
                        return (
                            <div key={ idx } className="sticket-icon">
                                <img
                                    style={ { filter: "contrast(0.756504)" } }
                                    src={ `https://www.csgola.com/Public/img/csgo_stickers/${ item.material }.png` }
                                    alt=""
                                />
                            </div>
                        );
                    }) }
                </div>
            ) }
            <Wear type={ type } wearValue={ wearValue }/>
        </>
    );
}

export default itemWear;
