import { $login, $CodeLogin, $toReal } from 'react'
import axios from 'axios';
import { message as Message } from 'antd';
import BASE_URL from './base_url'
import UaParserJS from 'ua-parser-js'

const uaParserJS = new UaParserJS()
const userAgent = uaParserJS.getResult()
const navigatorStr = `${ userAgent.os.name } ${ userAgent.browser.name }`
// function getCookie(cname) {
//   var name = cname + "=";
//   var ca = document.cookie.split(';');
//   for (var i = 0; i < ca.length; i++) {
//     var c = ca[i].trim();
//     if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
//   }
//   return "";
// }

axios.defaults.withCredentials = true; // 允许携带cookie
let service = axios.create({
    timeout: 200000,
    baseURL: BASE_URL.fetchUrl // BASE_URL.fetchUrl
})

// 添加请求拦截器
service.interceptors.request.use(
    (config) => {
        // Cookies.load('authorization') && (config.headers['authorization'] = Cookies.load('authorization'));
        // console.log(config)
        const token = localStorage.getItem('token');
        token && (config.headers['authorization'] = token)
        config.headers['UserAgent'] = navigatorStr
        // 请求发送前进行处理
        return config
    },
    (error) => {
        // 请求错误处理
        Message.error('请求超时')
        return Promise.reject(error)
    }
)

// 添加响应拦截器
service.interceptors.response.use(
    (response) => {
        // status 为非 200 是抛错 可结合自己业务进行修改
        const { status, message } = response.data
        if (status !== 200 && status !== 401) {
            if (message === "用户需要重新登录steam!") {
                return Message.error("用户需要在 PZ APP内重新登录steam!");
            }
            
            if (message === '当前无法读取库存') {
                return Message.error("如果你的库存无法正常刷新，请在MOBI App重新登录Steam，并稍后再试。");
            }
            if (message === '请先登录！') {
                Message.warning(message);
                return $login()
            }
            if (message === 'code login') {
                Message.warning('您必须使用手机验证码登录，才可进行当前操作!');
                return $CodeLogin()
            }
            if (message === '请通过手机验证码登录才可使用该功能！') {
                Message.error(message);
                return setTimeout(() => $CodeLogin(), 300)
            }
            
            if (message === '请先完成实名认证！') {
                return setTimeout(() => $toReal(), 300)
            }
            
            if ([ '操作失败，请尝试重新绑定！', '该账号无法绑定Steam账号！', '用户信息超时或违规操作，请重新点击绑定',
                '该steam账号无法绑定网站账户！' ].includes(message)) {
                // 下两种情况  绑定的账户是已经有完整绑定信息的账户
                // steam登录 绑定账户        《 绑定失败，该账号已经绑定过steam或者其他原因 》
                // 手机号登录 绑定steam账户   《 该steam账号已被绑定，可以更换steam进行绑定或者直接登录该账号进行操作！ 》
                setTimeout(() => window.location.reload(), 500)
            }
            Message.error(message);
            if ([ '请尝试重新拖动滑块进行验证！', '订单不存在', '该饰品已不在卖家库存，请选择购买其它饰品!' ].includes(message)) {
                return response.data
            } else {
                return null
            }
        } else {
            return response.data
        }
    },
    (err) => {
        if (err && err.response) {
            switch (err.response.status) {
                case 400:
                    err.message = '请求错误(400)';
                    break;
                case 401:
                    err.message = '登录过期，请重新登录';
                    break;
                case 403:
                    err.message = '拒绝访问(403)';
                    break;
                case 404:
                    err.message = '请求出错(404)';
                    break;
                case 408:
                    err.message = '请求超时(408)';
                    break;
                case 500:
                    err.message = '服务器错误(500)';
                    break;
                case 501:
                    err.message = '服务未实现(501)';
                    break;
                case 502:
                    err.message = '网络错误(502)';
                    break;
                case 503:
                    err.message = '服务不可用(503)';
                    break;
                case 504:
                    err.message = '网络超时(504)';
                    break;
                case 505:
                    err.message = 'HTTP版本不受支持(505)';
                    break;
                default:
                    err.message = `连接出错(${ err.response.status })!`;
            }
        } else {
            err.message = '连接服务器失败!'
        }
        Message.error(err.message)
        return err;
    }
)

const get = function ({ url, params = {}, headers = {} }) {
    let options = {};
    params && (options.params = params);
    headers && (options.headers = headers);
    
    return service.get(url, options)
}

const post = function ({ url, data = {}, params = {}, headers = {} }) {
    let options = {};
    params && (options.params = params);
    headers && (options.headers = headers);
    
    return service.post(url, data, options);
}

export { get, post }
