import React from 'react';
import './index.scss'

function CustomerService() {
    return (
        <div className="customer-service">
            <div className="title">
                <h1>联系客服</h1>
            </div>
            
            
            <div className="customer-service-contaner">
                <div className="center">
                    <div className="icon">
                        <img src={ require('@/images/profile/qq@2x.png') } alt=""/>
                    </div>
                    <p className="title">QQ</p>
                    <div className="join-qq">加入QQ群:</div>
                    <div className="text">联系客服，工作时间都在，咨询量大的时候可能延迟</div>
                </div>
            </div>
        </div>
    )
}

export default CustomerService;
