import React, { antdUI, useEffect, useState } from "react";
import akData from '@/data/ak47-case-hardened'
import "./pattern.scss";
function Pattern(props) {
  const { patVal, setPatVal, setMarbleStyle, asHashName } = props;
  const { Input, Cascader } = antdUI;
  const { Search } = Input;
  const [template, setTemplate] = useState(  patVal?.[0]? [patVal] : '');
  const [isInt, setIsInt] = useState(false);
  const [selectData, setSelectData] = useState([]);

  const onChange = (value) => {
    setMarbleStyle('');
    setTemplate(value);
    let filValue = value[1] ? value[1] : value[0];
    typeof filValue === "string"
      ? setPatVal(JSON.stringify(JSON.parse(filValue)))
      : setPatVal(filValue);
  };

  const onSearch = (value) => {
    setMarbleStyle();
    setTemplate([value]);
    setPatVal(value);
  };

  useEffect(() => {
    if (isInt) {
      if (!patVal) {
        setTemplate()
      }
    } else {
      setIsInt(true);
    }
    // eslint-disable-next-line
  }, [patVal]);

  useEffect(() => {
    const akEgx = /AK-47 \| Case Hardened/
    akEgx.test(asHashName) && setSelectData(akData)
  }, [asHashName])

  function dropdownSearch(menus) {
    return (
      <div className="patternTemplate-select">
        <Search
          placeholder="图案模板编号"
          // size="small"
          onSearch={onSearch}
          allowClear
        ></Search>
        {
          /AK-47 \| Case Hardened/.test(asHashName) && menus
        }
      </div>
    );
  }

  return (
    <div className='sort-tab-pattern'>
      <Cascader
        changeOnSelect
        expandTrigger="hover"
        value={template}
        size="small"
        allowClear
        options={selectData}
        dropdownRender={dropdownSearch}
        placeholder="图案模板"
        onChange={onChange}
        style={{ width: "155px" }}
      ></Cascader>
    </div>
  );
}

export default Pattern;
