import React, { useEffect } from "react";
import AccessoriesView from '../../components/accessoriesList/index'


function AccessoriesList(props) {
  const { location, history } = props
  // if (!location.state) window.location.href = '/marketPlace'
  useEffect(() => {
    localStorage.removeItem('saveId');
    return () => { localStorage.removeItem("wearList"); }
  }, [])
  return (
    <AccessoriesView location={location} history={history} />
  );
}

export default AccessoriesList;
