import React, { antdUI, $api, $tools, useEffect, useState } from 'react';
import '../index.scss'
import { Link } from 'react-router-dom';

function IsBuying() {
    
    const [ dataSource, setDataSource ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ page, setPage ] = useState(1);
    const [ total, setTotal ] = useState(0);
    const [ activeOpen, setActiveOpen ] = useState(0);
    const pageSize = 10
    
    async function getData(_page = 1) {
        page && (_page = page)
        setLoading(true);
        const res = await $api.order.getPersonalBuyingData({ type: 'conduct', curPage: _page, pageSize });
        setLoading(false);
        let data;
        if ($tools.checkResult(res)) {
            data = res?.data.order;
            setTotal(res.data.total);
            setDataSource(data);
        }
    }
    
    async function stopBuying(id) {
        const res = await $api.putShelf.stopBuyingOrder({ id });
        if ($tools.checkResult(res)) {
            antdUI.message.warning('已终止该求购');
            await getData();
        }
    }
    
    
    const getPageType = (name) => {
        if (name.search('纪念品') !== -1) {
            return 3;
        } else if (name.search('StatTrak™') !== -1) {
            return 2;
        } else {
            return 1;
        }
    }
    
    
    function expansionHandle(item) {
        if (activeOpen !== item.id) {
            setActiveOpen(item.id);
        } else {
            setActiveOpen(0);
        }
    }
    
    useEffect(_ => {
        getData(page).then()
        // eslint-disable-next-line
    }, [ page ])
    
    return (
        <div className='buying-con'>
            <div className='table-header'>
                {
                    columns.map(item => {
                        return <div key={ item.key } style={ { width: item.width } }
                                    className='header-item'>{ item.title }</div>
                    })
                }
            </div>
            {
                loading ?
                    <antdUI.Spin style={ { margin: '50px auto' } }/>
                    : <div className='table-main'>
                        {
                            dataSource?.[0] && dataSource.map((item, index) => {
                                return (
                                    <div className='table-line' key={ item.id }>
                                        <div className='main'>
                                            <div style={ { width: '30%' } } className='table-item item-info'>
                                                <div className='icon-img'>
                                                    <img
                                                        className="item-img"
                                                        src={ item?.CsgoItem?.img_name ? `${ React.StaticBaseUrl }/market-csgo/${ item?.CsgoItem?.img_name }.png`
                                                            : $tools.BASE_STEAM_IMG + item.icon_url }
                                                        alt={ item.market_name }
                                                    />
                                                </div>
                                                <div>
                                                    <Link
                                                        target={ '_blank' }
                                                        to={
                                                            {
                                                                pathname: '/marketPlace/accessoriesList',
                                                                search: $tools.obj2strUrl({
                                                                    id: item.itemid,
                                                                    curGame: '730',
                                                                    curType: getPageType(item?.market_name),
                                                                }, '?'),
                                                            }
                                                        }
                                                    >
                                                        <p className='acc-name'>{ item.market_name }</p>
                                                    </Link>
                                                    
                                                    {
                                                        item?.buyingData?.[0] &&
                                                        <span onClick={ () => expansionHandle(item) }
                                                              style={ { color: '#EEA20E', cursor: 'pointer' } }>
                              { item.id === activeOpen ? '收起' : '展开' }
                            </span>
                                                    }
                                                </div>
                                            
                                            </div>
                                            <div className='table-item unit-price'>￥{ item.unitPrice }</div>
                                            <div
                                                className='table-item max-price'>￥{ Number(item.maxPrice) > 0 ? item.maxPrice : '暂无' }</div>
                                            <div className='table-item plan-num'>
                                                <span className='complete'>{ item.receiptTotal }</span>/
                                                <span>{ item.buyingTotal }</span>/
                                                <span>{ item.total }</span>
                                            </div>
                                            <div className='table-item create-time'>{ item.create_time }</div>
                                            <div className='table-item state'>
                                                <antdUI.Button onClick={ () => stopBuying(item.id) } type='primary'>终止求购
                                                </antdUI.Button>
                                            </div>
                                        </div>
                                        
                                        {
                                            item.id === activeOpen &&
                                            <div className='expansion'>
                                                {
                                                    item?.buyingData.map((item, index) => {
                                                        return (
                                                            <div key={ index } className='table-line-complete'>
                                                                <div style={ { width: '35%' } }
                                                                     className='table-item item-info'>
                                                                    <div className='icon-img'>
                                                                        <img src={ $tools.BASE_STEAM_IMG + item.icon_url }
                                                                             alt=""/>
                                                                    </div>
                                                                    <div>
                                                                        <p>{ item.market_name }</p>
                                                                    </div>
                                                                
                                                                </div>
                                                                <div className='seller-info'>
                                                                    卖家：
                                                                    <img height='30' src={ item?.UserSteam.avatarfull }
                                                                         alt=""/>
                                                                    <p>{ item?.user?.nickname }</p>
                                                                </div>
                                                                <div className='create-time'>
                                                                    { item.create_time }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
            }
            {
                ! loading && ! dataSource?.[0] && <antdUI.Empty image={ antdUI.Empty.PRESENTED_IMAGE_SIMPLE }/>
            }
            {
                total > 0 &&
                <div className='pager'>
                    <antdUI.Pagination current={ page } onChange={ (current) => setPage(current) } total={ total }
                                       pageSize={ pageSize }/>
                </div>
            }
        
        
        </div>
    )
}

export default IsBuying;

let titleStyle = { textAlign: 'center', fontWeight: 900, fontSize: '14px' };
const columns = [
    {
        title: <p style={ titleStyle }>正在求购饰品</p>,
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        width: '30%',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>你的求购饰品单价</p>,
        dataIndex: 'price',
        key: 'price',
        align: 'center',
        width: '14%',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>求购最高价</p>,
        dataIndex: 'maxPrice',
        key: 'maxPrice',
        align: 'center',
        width: '14%',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>已收货/供应中/计划求购</p>,
        dataIndex: 'planNum',
        key: 'planNum',
        align: 'center',
        width: '14%',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>订单发布时间</p>,
        dataIndex: 'createTime',
        key: 'createTime',
        align: 'center',
        width: '14%',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>操作</p>,
        dataIndex: 'operating',
        key: 'operating',
        align: 'center',
        width: '14%',
        render: text => text
    }
]

// const paginationProps = {
//   position: ["bottomCenter"],
//   pageSize: 5,
//   current: curPage,
//   total: total, //数据的总的条数
//   onChange: (current) => filterPage(current), //点击当前页码
// };
