import React, { antdUI, useState, useEffect, useImperativeHandle } from 'react';
import { csgo } from '@/data/classification.js';
import SearchFilter from './search'
import './csgo.scss'

let CascaderStyle = {
    width: '104px',
    height: '33px',
    marginRight: '20px'
};
const inventorySelect = [
    {
        value: 'all',
        label: '显示全部'
    },
    {
        value: 'tradable',
        label: '显示可交易'
    },
    {
        value: 'sale',
        label: '显示可出售'
    },
]
// 显示全部是 -> 冷却结束已上架和冷却结束未上架和未冷却结束
// 显示可交易是 -> 冷却结束已上架和未上架的
// 显示可出售是 -> 冷却结束未上架的

function CsgoFilter(props) {
    let {
        type,
        filterChange,
        cRef,
        fiType,
        weapon,
        setCurrentPage,
        priceSort,
        minPrice,
        maxPrice,
        sort,
        useType
    } = props;
    const remData = JSON.parse(localStorage.getItem('rememberFilter'));
    const {
        concentration: rConcentration,
        exterior: rExterior,
        quality: rQuality,
        rarity: rRarity,
        collection: rCollection
    } = remData || {};
    const [ isInt, setIsInt ] = useState(true),
        [ itemType, setitemType ] = useState([]),
        [ quality, setquality ] = useState(rQuality ? rQuality : []),
        [ exterior, setexterior ] = useState(rExterior ? rExterior : []),
        [ rarity, setRarity ] = useState(rRarity ? rRarity : []),
        [ searchVal, setSearchVal ] = useState(''),
        [ searchChangeSt, setSearchChangeSt ] = useState(0),
        [ _csgoData, set_csgoData ] = useState([]),
        [ itemStatus, setItemStatus ] = useState([]),
        [ concentration, setConcentration ] = useState(rConcentration ? rConcentration : []),
        [ collection, setCollection ] = useState(rCollection ? rCollection : []),
        [ dataRanking, setDataRanking ] = useState([]);

    useEffect(() => {
        let _csdata = JSON.parse(JSON.stringify(csgo))
        _csdata.forEach(item => {
            item.children && item.children.forEach(v => {
                v.label = <span style={ { color: v.color || '#222', } }>{ v.label }</span>
                if (Object.prototype.toString.call(v.children) === '[object Array]') {
                    v.children.forEach(_ => _.label = <span style={ { color: _.color || '#222', } }>{ _.label }</span>)
                }
            })
        })
        set_csgoData(_csdata)
    }, [])

    switch (type) {
        case 'tradingmarket':
            _csgoData.forEach((v, i) => {
                v.value === 'itemType' && _csgoData.splice(i, 1)
            });
            break;
        default:
            break;
    }


    useEffect(() => {
        if (isInt) {
            setIsInt(false)
        } else {
            let op = {
                minPrice, maxPrice, sort, itemType,
                quality, exterior,
                rarity, priceSort, itemStatus,
                concentration, collection,
                searchContent: searchVal.toUpperCase(), currentPage: 1
            }
            fiType && (op.type = fiType)
            weapon && (op.weapon = weapon)
            setCurrentPage && setCurrentPage(1)

            if (useType && useType === 'market') {
                let remFilterData = {
                    type: fiType, weapon, searchContent: searchVal.toUpperCase(), concentration,
                    exterior, quality, rarity, collection, priceSort
                };
                localStorage.setItem('rememberFilter', JSON.stringify(remFilterData));
            }
            filterChange(op)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ itemType, priceSort, sort, quality,
        exterior, rarity, itemStatus, fiType,
        weapon, searchChangeSt, minPrice, maxPrice, dataRanking, concentration, collection ])

    useImperativeHandle(cRef, () => ({
        setRarity,
        // getData 就是暴露给父组件的方法
        getData: (currentPage) => filterChange({
            type: fiType, weapon, itemType, quality, exterior, rarity, itemStatus,
            concentration, collection,
            searchContent: searchVal, currentPage, minPrice,
            maxPrice, priceSort, sort
        })
    }));
    const _Cascader = (v, i) => (
        <antdUI.Cascader
            key={ i }
            dropdownRender={ menus => <div className="mobi-item-filter-select">{ menus }</div> }
            changeOnSelect={ v.value !== 'rarity' && v.value !== 'collection' }
            displayRender={ label => label[label.length - 1] }
            expandTrigger="hover"
            style={ CascaderStyle } options={ v.children }
            value={ (() => {
                switch (v.value) {
                    case "itemType":
                        return itemType;
                    case "quality":
                        return quality;
                    case "exterior":
                        return exterior;
                    case "rarity":
                        return rarity;
                    case "concentration":
                        return concentration;
                    case "dataRanking":
                        return dataRanking;
                    case "collection":
                        return collection;
                    default:
                        break;
                }
            })() }
            onChange={ value => {
                let _v = value;
                switch (v.value) {
                    case "itemType":
                        setitemType(_v);
                        break;
                    case "quality":
                        setquality(_v);
                        break;
                    case "exterior":
                        setexterior(_v);
                        break;
                    case "rarity":
                        setRarity(_v);
                        break;
                    case "concentration":
                        setConcentration(_v);
                        break;
                    case "dataRanking":
                        setDataRanking(_v);
                        break;
                    case "collection":
                        setCollection(_v);
                        break;
                    default:
                        break;
                }
            } } placeholder={ v.label }
        />
    )
    return (
        <>
            {
                (type !== 'ship' && type !== 'buy') &&
                // type !== 'onSalePut' &&
                _csgoData.map((v, i) => {
                    if (type === 'tradingmarket') {
                        return _Cascader(v, i)
                    } else if (v.value === "dataRanking" || v.value === "concentration") {
                        return ''
                    } else {
                        return _Cascader(v, i)
                    }
                }) }

            { type === 'tradingmarket' || type === 'onSalePut' || type === 'sale' || type === 'ship' ||
            type === 'buy' ||
            <antdUI.Cascader
                options={ inventorySelect }
                value={ itemStatus }
                onChange={ value => setItemStatus(value) }
                style={ CascaderStyle } placeholder={ inventorySelect[0].label }/> }
            <SearchFilter setSearchVal={ setSearchVal } searchChange={ _ => {
                let num = ~~(Math.random() * 1000)
                setSearchChangeSt(num)
            } }/>
        </>
    )
}


export default CsgoFilter;
