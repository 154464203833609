import { get, post } from '@/utils/request.js';

export default {
    // 登录
    signIn(data) {
        return post({ url: '/users/signIn/', data })
    },
    
    // 登录之后使用验证码 登陆验证
    codeCheckSignIn(data) {
        return post({ url: '/users/codeCheck/signIn/', data })
    },
    // 注册登陆找回密码发送验证码
    signUpSendPhoneCode(data) {
        return post({ url: '/users/signUp/phoneCode/send/', data })
    },
    
    sendPhoneCode(data) {
        return post({ url: '/users/phoneCode/send/', data })
    },
    
    // 检查验证码
    checkPhoneCode(data) {
        return post({ url: '/users/phoneCode/check/', data })
    },
    
    // 获取用户资产
    getUserAssets() {
        return get({ url: '/users/assets/' })
    },
    
    // 修改用户名
    updateNickname(data) {
        return post({ url: '/users/nickname/update/', data })
    },
    
    // 注册
    signUp(data) {
        return post({ url: '/users/signUp/', data })
    },
    
    // 检查登录
    checklogin(params) {
        return get({ url: '/users/checklogin/', params })
    },
    
    // 设置邮箱
    setUpEmail(data) {
        return post({ url: '/users/mailbox/set/', data })
    },
    
    // 退出登录
    signOut(params) {
        return get({ url: '/users/signOut/', params })
    },
    
    // 获取代币记录
    getUserTokensRecord(params) {
        return get({ url: '/users/tokens/record/', params })
    },
    
    // 解绑steam
    unbindSteam() {
        return get({ url: '/users/unbind/steam/' })
    },
    
    bindSteamGetSInfo() {
        return get({ url: '/users/bindsteam/getinfo/' })
    },
    
    // 获取中介手续费
    getIntermediaryFees() {
        return get({ url: '/handlFee/get/', params: { type: 'intermediary' } })
    },
    
    //添加收藏
    addFavorite(data) {
        return post({ url: '/users/items/favorites/add/', data })
    },
    
    //取消收藏
    cancelFavorite(data) {
        return post({ url: '/users/items/favorites/cancel/', data })
    },
    
    //获取收藏
    getFavorites(params) {
        return get({ url: '/users/items/favorites/get/', params })
    },
    
    //获取相应代币发放形式（激励）
    getIssuance(params) {
        return get({ url: '/admin/tokens/UiGetIssuance/', params })
    },
    
    // 获取用户流水记录
    getFlowRecord(params) {
        return get({ url: '/users/flow/record/', params })
    },
    
    // 更新偏好设置
    updatePreferences(data) {
        return post({ url: '/users/preferences/update/', data })
    },
    // 获取用户消息
    getUserMessage(params) {
        return get({ url: '/users/get/messages/', params })
    },
    // 获取消息状态
    getUserMessageState(params) {
        return get({ url: '/users/get/messages/state/', params })
    },
    // 更新消息状态
    updateUserMessageState(data) {
        return post({ url: '/users/update/messages/state/', data })
    },
    
    // 支付宝充值
    alipay(data) {
        return post({ url: '/users/pay/alipay/', data })
    },
    // 充值记录
    rechargeRecord(params) {
        return get({ url: '/users/recharge/record/', params })
    },
    // 信誉积分变动记录
    creditRecord(params) {
        return get({ url: '/users/credit/record/', params })
    },
    // 提现记录
    withdrawRecord(params) {
        return get({ url: '/users/withdraw/record/', params })
    },
    
    //修改推广码
    setPromotionCode(data) {
        return post({ url: '/users/promotionCode/update/', data })
    },
    
    // 修改推广人
    setPromotionMan(data) {
        return post({ url: '/users/promotionMan/update/', data })
    },
    // 修改密码
    updatePassword(data) {
        return post({ url: '/users/update/password/', data })
    },
    
    
    getIpcity(params) {
        return get({ url: '/users/getIpcity/', params })
    },
    
    saveFrequentlyCity(data) {
        return post({ url: "/users/add/frequentlyCity/", data })
    },
    
    
    removeFrequentlyCity(data) {
        return post({ url: "/users/remove/frequentlyCity/", data })
    },
    
    
    unlockPassLogin(data) {
        return post({ url: "/users/unlock/passLogin/", data })
    },
    
    bindSteamLogin(data) {
        return post({ url: "/users/bindSteam/", data })
    },
    
    bindSteamAp(params) {
        return get({ url: "/users/bindSteam/app/", params })
    },
    
    mobiRealName(data) {
        return post({ url: '/users/realName/', data })
    },
    
    mobiZfbRealName(data) {
        return post({ url: '/pay/alipay/checkAccount/', data })
    },
    // 更换绑定手机 => 发送老手机验证码
    changeBindPhoneSendCodeOld(data) {
        return post({ url: '/users/changeBindPhone/sendCode/old/', data })
    },
    // 更换绑定手机 => 确认老手机验证码
    changeBindOldCheck(data) {
        return post({ url: '/users/changeBindPhone/oldCheck/', data })
    },
    // 更换绑定手机号 => 发送新手机验证码
    changeBindPhoneSendCodeNew(data) {
        return post({ url: '/users/changeBindPhone/sendCode/new/', data })
    },
    // 更换绑定手机号
    changeBindNewPhone(data) {
        return post({ url: '/users/phone/changeBind/', data })
    },
    userPromotionData() {
        return get({ url: '/users/promoted/data/' })
    }
}
