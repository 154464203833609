import React, { $api, $tools, antdUI, useState } from 'react';
import { connect } from 'react-redux';
import { setUserSignIn } from '@/store/actions/userInfo'

function CheckUnBind(props) {
    const { userInfo, setUserSignIn, setUnBindCheck } = props;
    const [ visible, setVisible ] = useState(props.visible)
    const [ btnLoading, setLoading ] = useState(false)
    return (
        <div className="P-code-check-BdSteam">
            <antdUI.Modal
                title="解绑确认" visible={ visible }
                footer={ [
                    <antdUI.Button key={ 1 } onClick={ () => {
                        setVisible(false);
                        setUnBindCheck(false)
                    } }>
                        取消
                    </antdUI.Button>,
                    <antdUI.Button key={ 2 } type="primary" loading={ btnLoading } onClick={ async () => {
                        setLoading(true)
                        const result = await $api.user.unbindSteam()
                        setLoading(false)
                        setVisible(false);
                        setUnBindCheck(false)
                        if ($tools.checkResult(result)) {
                            setUserSignIn();
                            localStorage.setItem(`csgoInventoryCacheTime`, "")
                            localStorage.setItem(`dota2InventoryCacheTime`, "")
                        }
                    } }>
                        确定
                    </antdUI.Button>
                ] }
                onCancel={ () => {
                    setVisible(false);
                    setUnBindCheck(false)
                } }
                width="393px" maskClosable={ false } destroyOnClose={ true } centered={ true }
            >
                <div className="apikey-box-text" style={ {
                    padding: '14px', width: '100%', backgroundColor: '#191919',
                    boxShadow: `0px 0px 6px 1px #191919`
                } }>
                    <p className="title"
                       style={ { fontSize: '14px', color: '#8c9298', marginBottom: '8px' } }>当前绑定账号信息：</p>
                    <p style={ { color: '#fff', fontSize: '14px' } }>Steam17ID：{ userInfo?.steam?.steamid }</p>
                    
                    <p style={ { color: '#fff', fontSize: '14px', } }>Steam昵称： <h6
                        style={ {
                            color: '#fff',
                            fontSize: '12px',
                            display: "inline-block"
                        } }>{ userInfo?.steam?.personaname }</h6></p>
                
                </div>
            </antdUI.Modal>
        </div>
    )
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    },
    function mapDispatchToProps(disatch) {
        return {
            setUserSignIn: () => disatch(setUserSignIn()),
        }
    }
)(CheckUnBind);
