const data = [
  {
    value: "[660,670,321,955,179,151,168,387,555,592,617,760,809,828]",
    label: "T1",
    children: [
      {
        value: 660,
        label: "660",
      },
      {
        value: 670,
        label: "670",
      },
      {
        value: 321,
        label: "321",
      },
      {
        value: 955,
        label: "955",
      },
      {
        value: 179,
        label: "179",
      },
      {
        value: 151,
        label: '151',
      },
      {
        value: 168,
        label: '168',
      },
      {
        value: 387,
        label: '387',
      },
      {
        value: 555,
        label: '555'
      },
      {
        value: 592,
        label: '592'
      },
      {
        value: 617,
        label: '617'
      },
      {
        value: 760,
        label: '809'
      },
      {
        value: 809,
        label: '809'
      },
      {
        value: 828,
        label: '828'
      }
    ],
  },
  { value: 382, label: "T2" },
  { value: 530, label: "T3" },
  { value: 16, label: "T4" },
];

export default data
