import { get, post } from '@/utils/request.js';

export default {
  // 接受一笔中介订单
  acceptIntOrder(data) {
    return post({ url: '/order/intermediary/accept/', data })
  },

  // 取消中介订单
  cancelOrder(data) {
    return post({ url: '/order/intermediary/cancel/', data })
  },

  // 取消自售订单
  saleOrderCancel(data) {
    return post({ url: '/order/sale/cancel/', data })
  },

  //获取个人求购数据
  getPersonalBuyingData(params) {
    return get({ url: '/order/buying/', params })
  }
}