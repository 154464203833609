import { post } from '@/utils/request.js';

export default {
    // 支付宝充值
    alipay(data) {
        return post({ url: '/pay/alipay/', data })
    },
    
    //修改用户提现
    changeWithdrawAccount(data) {
        return post({ url: '/users/withdraw/updateAccount/', data })
    },
    
    //提现
    widthDrawAccount(data) {
        return post({ url: '/pay/alipay/transfer/', data })
    },
    
    // 微信充值
    wechatPay(data) {
        return post({ url: '/pay/wechat/native/', data })
    },
    
    // 微信充值订单结果查询
    wechatCheckOrder(data) {
        return post({ url: '/pay/wechat/query/', data })
    },
    
    // 支付宝充值退款
    alipayRefund(data) {
        return post({ url: '/pay/alipay/refund/', data })
    },
}
