import React, { $tools, useEffect, useState, $api, $login } from 'react';
import './index.scss'
import { connect } from 'react-redux'
import { upDateFavoritesData } from '@/store/actions/favoritesData'

function Favorite(props) {
    const { curExId, curGame, favoritesData, upDateFavoritesData, useable, type, userInfo } = props;
    const [ isFavorite, setIsFavorite ] = useState(false);
    const [ item, setItem ] = useState({});
    const { debounce } = $tools;
    
    useEffect(() => {
        let _item
        switch (Number(curGame)) {
            case 730:
                _item = favoritesData.csgo.filter(_ => Number(_.itemid) === Number(curExId))[0];
                break;
            case 570:
                _item = favoritesData.dota2.filter(_ => Number(_.itemid) === Number(curExId))[0];
                break;
            default:
                break;
        }
        if (_item) {
            setItem(_item);
            setIsFavorite(true)
        } else {
            setIsFavorite(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ favoritesData, curExId ])
    
    const changeFavorite = async (active) => {
        if (userInfo.uid || userInfo.steamid) {
            switch (active) {
                case 'cancel':
                    await $api.user.cancelFavorite({ id: Number(item.id) });
                    break;
                case 'add':
                    await $api.user.addFavorite({ itemid: Number(curExId), appid: Number(curGame) });
                    break;
                default:
                    break;
            }
            upDateFavoritesData();
        } else {
            $login();
        }
    }
    
    return (
        <div className="goods-favorites">
            { isFavorite ?
                <span className='favorites-tab'
                      onClick={ useable ? debounce(changeFavorite.bind(null, 'cancel'), 2000, true) : () => { } }>
          <span>已关注</span><i className="iconfont icon-shixinxingxing"></i>
        </span>
                : type === 'main' ? <span className='favorites-tab'
                                          onClick={ useable ? debounce(changeFavorite.bind(null, 'cancel'), 2000, true) : () => { } }>
          <span>已关注</span><i className="iconfont icon-shixinxingxing"></i>
        </span> :
                    <span className='favorites-tab'
                          onClick={ useable ? debounce(changeFavorite.bind(null, 'add'), 2000, true) : () => { } }>
            <span>关注</span><i className="iconfont icon-xing"></i>
          </span>
            }
        </div>
    );
}

export default connect(
    function mapStateToProps(state) {
        return {
            favoritesData: state.favoritesData,
            userInfo: state.user.userInfo,
        };
    },
    function mapDispatchToProps(disatch) {
        return {
            upDateFavoritesData: () => disatch(upDateFavoritesData())
        };
    }
)(Favorite);
