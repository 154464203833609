import React, { antdUI, $tools, useEffect, useImperativeHandle, useRef, useState } from "react";
import "./index.scss";
import SortTab from "./sortTab";
import { Link } from "react-router-dom";
import { csgo } from '../../../../data/classification';

const toolData = csgo.filter(item => item.value === 'itemType')?.[0].children.filter(type => type.value === 'other')?.[0].children;

const dota2ListData = [
    { label: '基础', value: 'base' }, { label: '标准', value: 'unique' }, { label: '铭刻', value: 'strange' },
    { label: '纯正', value: 'genuine' }, { label: '尊享', value: 'exalted' }, {
        label: '英雄传世',
        value: 'tournament'
    },
    { label: '融合', value: 'infused' }, { label: '吉祥', value: 'lucky' }, { label: '冥灵', value: 'corrupted' },
    { label: '亲笔签名', value: 'autographed' }, { label: '独特', value: 'unusual' }, {
        label: '凶煞',
        value: 'haunted'
    },
    { label: '上古', value: 'vintage' }, { label: '冻人', value: 'frozen' },
    //  { label: '自制', value: '1' }, { label: '绝版', value: '12' }
]


function FilterTab(props) {
    const {
        searchData,
        listType,
        setListType,
        processFilterData,
        wearTabData,
        totalOnSale,
        cRef,
        curExId,
        curType,
        asHashName,
        curGame,
        hashData,
        history,
        buyingTotal,
        type     //物品类型
    } = props;
    
    const childRef = useRef();
    const [ statTrakList, setStatTrakList ] = useState([]);
    const [ normalList, setNormalList ] = useState([]);
    const [ tourList, setTourList ] = useState([]);
    const [ wearTabList, setWearTabList ] = useState([]);
    const [ activeType, setActiveType ] = useState(Number(curType)); //1:普通   2:statTrak
    const [ listData, setListData ] = useState([]);
    const [ bulkTipsView, setBulkTipsView ] = useState(false);
    
    
    useImperativeHandle(cRef, () => ({
        filterPage: childRef.current?.filterPage,
    }));
    
    useEffect(() => {
        if (Number(curGame) === 730) {
            if (filterShow(asHashName, type)) {
                setListData([
                    { label: "出售中", value: 1 },
                    // { label: "求购中", value: 2 },
                    { label: "成交记录", value: 3 },
                    { label: "磨损排行", value: 4 },
                    { label: "价格趋势", value: 5 }
                ])
            } else {
                setListData([
                    { label: "出售中", value: 1 },
                    // { label: "求购中", value: 2 },
                    { label: "成交记录", value: 3 },
                    { label: "价格趋势", value: 5 }
                ])
            }
        } else {
            setListData([
                { label: "出售中", value: 1 },
                { label: "成交记录", value: 3 },
                { label: "价格趋势", value: 5 }
            ])
        }
        
    }, [ asHashName, type, curGame ])
    
    const separateData = (value, id, hashName, price, quality) => {
        // 处理音乐盒
        if (hashName.indexOf('Music Kit') !== -1) {
            if (quality === "strange" || quality === "unusual_strange") {
                return { id, label: "StatTrak™", sId: 0, hashName, price }
            } else {
                return { id, label: "普通", sId: 8, hashName, price }
            }
        }
        
        
        switch (value) {
            case "WearCategory0":
                return { id, label: "崭新出厂", sId: 1, hashName, price };
            case "WearCategory1":
                return { id, label: "略有磨损", sId: 2, hashName, price };
            case "WearCategory2":
                return { id, label: "久经沙场", sId: 3, hashName, price };
            case "WearCategory3":
                return { id, label: "破损不堪", sId: 4, hashName, price };
            case "WearCategory4":
                return { id, label: "战痕累累", sId: 5, hashName, price };
            case "WearCategoryNA":
                return { id, label: "无涂装", sId: 6, hashName, price };
            default:
                return { id, label: "无涂装", hashName, price };
        }
    };
    
    const getQuality = (quality) => {
        for (let item of dota2ListData) {
            if (quality === item.value) {
                return item.label
            }
        }
    }
    
    const listTypeChange = (tab) => {
        
        let hashObj = { ...hashData }
        hashObj.tab = tab;
        
        history.push({
            pathname: "/marketPlace/accessoriesList",
            search: $tools.obj2strUrl(searchData, '?'),
            hash: $tools.obj2strUrl(hashObj, '#')
        })
        setListType(tab);
    }
    
    const getConcentration = () => {
        let data = JSON.parse(localStorage.getItem('rememberFilter'));
        if (data) {
            const { concentration } = data;
            return $tools.obj2strUrl({
                concentration: concentration?.[0] || 'unlimited'
            }, '#')
        }
        return ''
    }
    
    const getEx = (data) => {
        let statArr = [],
            normalArr = [],
            tourArr = [];
        if (curGame === '730') {
            data.forEach((item) => {
                const { quality } = item || {};
                
                if (quality === "strange" || quality === "unusual_strange") {
                    statArr.push(separateData(item.value, item.id, item.hashName, item.price, quality));
                } else if (quality === 'tournament') {
                    tourArr.push(separateData(item.value, item.id, item.hashName, item.price))
                } else {
                    normalArr.push(separateData(item.value, item.id, item.hashName, item.price, quality));
                }
                
            });
            normalArr.sort(function (a, b) {
                return a.sId - b.sId
            })
            statArr.sort(function (a, b) {
                return a.sId - b.sId
            })
            tourArr.sort(function (a, b) {
                return a.sId - b.sId
            })
            
            setNormalList(normalArr);
            setStatTrakList(statArr);
            setTourList(tourArr);
            
            if (activeType === 1) {
                setWearTabList(normalArr);
                setActiveType(1);
            } else if (activeType === 2) {
                setWearTabList(statArr);
                setActiveType(2);
            } else {
                setWearTabList(tourArr);
                setActiveType(3)
            }
        } else {
            data.forEach((item) => {
                const QLabel = getQuality(item.quality);
                normalArr.push({ label: QLabel, id: item.id });
            })
            setWearTabList(normalArr);
        }
        
    };
    
    const typeChange = (type, hasTour) => {
        type = Number(type)
        if (type !== 1) {
            setWearTabList(statTrakList);
            setActiveType(1);
            history.push({
                pathname: "/marketPlace/accessoriesList",
                search: $tools.obj2strUrl({
                    id: normalList[0].id,
                    curGame: '730',
                    curType: 1
                }, '?'),
                hash: getConcentration()
            })
        } else {
            if (hasTour) {
                setWearTabList(tourList);
                setActiveType(3);
                history.push({
                    pathname: "/marketPlace/accessoriesList",
                    search: $tools.obj2strUrl({
                        id: tourList[0].id,
                        curGame: '730',
                        curType: 3
                    }, '?'),
                    hash: getConcentration()
                })
            } else {
                setWearTabList(statTrakList);
                setActiveType(2);
                history.push({
                    pathname: "/marketPlace/accessoriesList",
                    search: $tools.obj2strUrl({
                        id: statTrakList[0].id,
                        curGame: '730',
                        curType: 2
                    }, '?'),
                    hash: getConcentration()
                })
            }
            
        }
    };
    
    useEffect(() => {
        getEx(wearTabData);
        // eslint-disable-next-line
    }, [ wearTabData ]);
    
    return (
        <div className="filter-tab">
            {
                asHashName && type && filterShow(asHashName, type) &&
                <div className="wear-tab">
                    { wearTabList.map((item, index) => {
                        return (
                            <Link
                                to={ {
                                    pathname: "/marketPlace/accessoriesList",
                                    search: $tools.obj2strUrl({
                                        id: item.id,
                                        curGame: '730',
                                        curType: activeType
                                    }, '?'),
                                    hash: getConcentration()
                                } }
                                key={ index }
                                className={
                                    Number(curExId) === item.id ? "wear-tab-btn-active" : "wear-tab-btn"
                                }
                            >
                                <span>{ item.label }</span>
                                <span>￥{ item.price || 0 }</span>
                            </Link>
                        );
                    }) }
                    { (isShowChange(curType, Boolean(normalList?.[0]), Boolean(statTrakList?.[0]), Boolean(tourList?.[0]))) && curGame === '730' && (
                        <button
                            className="wear-tab-btn"
                            onClick={ () => {
                                typeChange(curType, Boolean(tourList?.[0]));
                            } }
                        >
                            <i className="iconfont icon-web-switch"></i>
                            { getChangeName(curType, Boolean(tourList?.[0])) }
                        </button>
                    ) }
                </div>
            }
            
            
            <div className="other-tab">
                <div className="other-tab-left">
                    { listData.map((item, index) => {
                        return (
                            <button
                                key={ index }
                                onClick={ () => listTypeChange(item.value) }
                                className={
                                    `list-tab-btn ${ listType === item.value ? "list-tab-btn-active" : "" }`
                                }
                            >
                                { item.label }
                                { item.value === 1 && listType === 1 && <span>({ totalOnSale || 0 })</span> }
                                { item.value === 2 && listType === 2 && <span>({ buyingTotal || 0 })</span> }
                            </button>
                        );
                    }) }
                </div>
                <div className="other-tab-search">
                    {
                        !<antdUI.Button type="primary" onClick={ _ => setBulkTipsView(true) }
                                       className="bulk-buy-btn">批量购买
                        </antdUI.Button>
                    }
                </div>
            </div>
            { listType === 1 && (
                <SortTab
                    curExId={ curExId }
                    curType={ curType }
                    asHashName={ asHashName }
                    cRef={ childRef }
                    curGame={ curGame }
                    changeFilter={ processFilterData }
                    hashData={ hashData }
                ></SortTab>
            ) }
            
            <antdUI.Modal
                title="提示"
                centered
                visible={ bulkTipsView }
                width={ 368 }
                footer={ [
                    <antdUI.Button type="primary" onClick={ _ => setBulkTipsView(false) }>
                        确定
                    </antdUI.Button>
                ] }
                onOk={ _ => setBulkTipsView(false) }
                onCancel={ _ => setBulkTipsView(false) }
                maskClosable={ false }
            >
                <div>
                    <p>批量购买请前往MOBI平台APP进行操作，支持合并发报价功能</p>
                </div>
            </antdUI.Modal>
        </div>
    );
}


function getChangeName(type, isTour = false) {
    type = Number(type)
    if (type === 2 || type === 3) {
        return '切换为普通';
    } else {
        if (isTour) {
            return '切换为纪念品';
        } else {
            return '切换为statTrak™';
        }
    }
}

function isShowChange(type, hasNormal, hasStar, hasTour) {
    if (Number(type) === 1) {
        return hasTour || hasStar;
    } else {
        return hasNormal;
    }
}

/**
 *
 * @param name  物品名字
 * @param type  物品类型
 * 如果监测名字包含Sticker则为印花，印花以及类型为工具子类则返回false不显示磨损筛选
 */
function filterShow(name, type) {
    if (name.indexOf('Music Kit') !== -1) return true;
    return name.search('Sticker') === -1 && toolData.findIndex(item => item.value === type) === -1;
}

export default FilterTab;

