import React, { useState, $api, useEffect } from 'react';
import { connect } from 'react-redux';
import { BasicInfo, AccountInfo, TradingInfo, Preferences, SecuritySettings } from './configItem/index';
import './index.scss';
import PCodeCheckBdSteam from '@/components/PCodeCheckBdSteam'
function PersonalContent(props) {
  const { userInfo } = props
  const bindSteam = localStorage.getItem('bindSteam')
  const [_locSearch, set_locSearch] = useState({})
  useEffect(() => {
    (async () => {
      if (bindSteam) {
        let result = await $api.user.bindSteamGetSInfo()
        if (!result?.data?.steamid) {
          window.localStorage.setItem('bindSteam', '')
        } else { set_locSearch(result.data); }
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (<div className="personal-content">
    {<PCodeCheckBdSteam visible={true} title="绑定确认" _locSearch={_locSearch} />}
    <BasicInfo />
    <AccountInfo />
    <TradingInfo />
    {userInfo?.uid ? <>
      {false &&  <Preferences />}
      <SecuritySettings />
    </> : ""}
  </div>)
}



export default connect(
  function mapStateToProps(state) {
    return {
      userInfo: state.user.userInfo
    }
  }
)(PersonalContent);
