import React, { $api, $tools, antdUI, useEffect, useState } from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { setUserSignIn } from '@/store/actions/userInfo'
import moment from 'moment'

let titleStyle = { marginRight: '20px', color: '#666666', fontSize: '14px', fontWeight: '500' }

function ShopSettingBox(props) {
    const { userInfo, settingView, setSettingView, setUserSignIn } = props;
    const [ radioVal, setRadioVal ] = useState(false)
    const [ turnOnVal, setTurnOnVal ] = useState('')
    const [ timePickerShow, setTimePickerShow ] = useState(false)
    const [ offLineDate, setOffLineDate ] = useState('')
    const [ defaultValue, setDefaultValue ] = useState('')
    const [ suLoading, setSuLoading ] = useState(false)
    const onChangeRadio = ({ target: { value } }) => {
        setRadioVal(value);
    }
    
    const turnOn = ({ target: { checked } }) => {
        setTurnOnVal(checked)
        setTimePickerShow(checked)
        if (checked) {
            setOffLineDate("0:0")
        } else {
            setOffLineDate('')
        }
    }
    
    const saveSetting = async () => {
        const { suspended } = userInfo
        if (
            radioVal && suspended?.endDate &&
            new Date(suspended?.endDate).getTime() >= new Date().getTime()
        )
            return antdUI.message.warning(`您的账号处于暂挂状态，请等待暂挂结束(${ suspended?.endDate })再上架店铺`);
        setSuLoading(true)
        let options = { autoOffLine: turnOnVal ? 1 : 2 }
        if (turnOnVal && offLineDate) {
            options.autoOffLineDate = offLineDate
        }
        let res = await $api.market.storeOnline({ storeSwitchVal: radioVal, ...options })
        setSuLoading(false)
        if ($tools.checkResult(res)) {
            antdUI.message.success('店铺修改设置成功');
            setUserSignIn();
        }
        setSettingView(false);
    }
    useEffect(() => {
        setRadioVal(userInfo.isOnline === 1)
        setTurnOnVal(userInfo.autoOffLine === 1)
        setTimePickerShow(userInfo.autoOffLine === 1)
        if (userInfo.autoOffLine) {
            setOffLineDate(userInfo.autoOffLineDate)
            setDefaultValue(`2021-01-01 ${ userInfo.autoOffLineDate }`)
        }
    }, [ userInfo ])
    
    
    return (
        <antdUI.Modal
            title="店铺设置"
            className="shop-setting-modal"
            visible={ settingView }  // 是否展示的布尔值
            footer={ false } // 是否需要按钮
            onCancel={ () => {
                setRadioVal(userInfo.isOnline === 1)
                setTurnOnVal(userInfo.autoOffLine)
                setTimePickerShow(userInfo.autoOffLine)
                if (userInfo.autoOffLineDate) {
                    setOffLineDate(userInfo.autoOffLineDate)
                    setDefaultValue(`2021-01-01 ${ userInfo.autoOffLineDate }`)
                }
                setSettingView(false);
            } }
            maskClosable={ false }  // 点击蒙层是否可以关闭
            destroyOnClose={ true } // 关闭之后销毁子节点
            centered={ true } // 居中
            width="600px"
        >
            <div className="shop-setting">
                <div style={ { marginTop: '40px' } } className="onLine-box flex ait">
                    <p style={ titleStyle }>店铺状态</p>
                    <antdUI.Radio.Group onChange={ onChangeRadio } value={ radioVal }>
                        <antdUI.Radio value={ false }>离线</antdUI.Radio>
                        <antdUI.Radio value={ true }>在线</antdUI.Radio>
                    </antdUI.Radio.Group>
                </div>
                <div style={ { marginTop: '20px' } } className="onLine-box flex ait">
                    <p style={ titleStyle }>自动离线</p>
                    <antdUI.Checkbox
                        checked={ turnOnVal }
                        onChange={ turnOn }>开启
                    </antdUI.Checkbox>
                    { timePickerShow ?
                        <antdUI.TimePicker
                            defaultValue={ defaultValue ? moment(defaultValue) : '' } format="HH:mm"
                            placeholder="请选择时间" onChange={ value => {
                            const hours = new Date(value).getHours();
                            const minutes = new Date(value).getMinutes();
                            setOffLineDate(`${ hours }:${ minutes }`)
                        } } showNow={ false }
                        />
                        : '' }
                </div>
                
                <div style={ { marginTop: '40px', width: '80%', fontSize: '14px', color: '#FF8801' } }
                     className="tips-text">
                    店铺（自动）离线后，在售中的饰品将被隐藏且无法购买，直到手动上线。自售功能禁用时，店铺离线且无法手动上线。
                </div>
                
                <div className="btn flex ait" style={ { marginTop: '30px' } }>
                    <antdUI.Button style={ { padding: '0 25px', margin: '0 20px' } }
                                   ghost type="primary" onClick={ () => setSettingView(false) }>取消
                    </antdUI.Button>
                    <antdUI.Button loading={ suLoading } style={ { padding: '0 25px', margin: '0 20px' } }
                                   type="primary" onClick={ () => saveSetting() }>确定
                    </antdUI.Button>
                </div>
            </div>
        </antdUI.Modal>
    )
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo,
        }
    },
    function mapDispatchToProps(disatch) {
        return {
            setUserSignIn: () => disatch(setUserSignIn())
        }
    }
)(ShopSettingBox);
