import {
    Carousel, Radio, Popconfirm,
    Input, Modal, Avatar, Button, Table, Skeleton, Switch,
    Tag, Collapse, Cascader, Select, Checkbox, Divider, TimePicker,
    message, Pagination, Spin, Empty, Menu, Dropdown, Popover, Slider,
    Tabs, Drawer, Space, Image, Upload, InputNumber, Badge
} from 'antd';

export default {
    Carousel, Radio, Popconfirm, Switch,
    Input, Modal, Avatar, Button, TimePicker,
    Table, Tag, Collapse, Cascader, Skeleton,
    Select, Checkbox, message, Pagination,
    Spin, Empty, Menu, Dropdown, Popover, Divider,
    Slider, Tabs, Drawer, Space, Image, Upload, InputNumber, Badge
}
