import React, { Component, antdUI } from 'react';
import './index.scss';
import MenuItem from './menuItem';
import { connect } from 'react-redux';

class PersnCtMenu extends Component {
  render() {
    let { menuData, userInfo } = this.props;
    const nickname = userInfo.nickname || userInfo?.steam?.personaname;
    return (
      <div className="personal-center-menu">
        <div className="head">
          <div className="avatar">
            {userInfo?.steam?.steamid ? <img className="avatar-icon" src={userInfo.steam.avatarfull} alt="" /> :
              <img className="avatar-icon" src={require('@/images/home/moren touxiang@2x.png')} alt="" />
            }
          </div>
          <antdUI.Popconfirm
            title={nickname}
            icon={false}
          >
            <p className="user-name one-text-flow">{nickname}</p>
          </antdUI.Popconfirm>

        </div>
        <div className="menu-container">
          {menuData.map((v, i) => {
            return <MenuItem key={i} index={i} item={v} />
          })}
        </div>
      </div>
    );
  }
}
export default connect(
  function mapStateToProps(state) {
    return {
      userInfo: state.user.userInfo
    }
  }
)(PersnCtMenu);