import React, { useEffect, antdUI } from 'react';
import './summaryGraph.scss'
import * as echarts from 'echarts/core'
import {
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    DataZoomComponent
} from 'echarts/components'
import {
    LineChart
} from 'echarts/charts'
import {
    CanvasRenderer
} from 'echarts/renderers'

echarts.use(
    [ TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, LegendComponent, DataZoomComponent, LineChart, CanvasRenderer ]
)

let myChart;


function formatTime(time) {
    if (! time) return ''
    let times = new Date(time?.replace(/-/g, '/'))
    return `${ times.getFullYear() }/${ times.getMonth() + 1 }/${ times.getDate() }`
}

function getType(data, type) {
    const startTime = data[0]?.[0];
    const endTime = data[data.length - 1]?.[0];
    return `${ formatTime(startTime) }-${ formatTime(endTime) }`;
}


function init(_statisticalData, pastDays) {
    if (myChart != null && myChart !== '' && myChart !== undefined) {
        myChart.dispose();
    }
    myChart = echarts.init(document.getElementById('priceStatistics'));
    myChart.setOption({
        title: { text: `价格趋势(${ getType(_statisticalData, pastDays) })`, left: 'center' },
        tooltip: {
            trigger: 'axis',
            position(pt) {
                return [ pt[0], '10%' ]
            }
        },
        xAxis: { type: 'time' },
        yAxis: { type: 'value' },
        dataZoom: [ { start: 0, end: 100 } ],
        series: [ {
            name: '价格',
            smooth: true, symbol: 'none',
            type: 'line', data: _statisticalData.filter(item => item[1] !== '0.00')
        } ]
    })
}

function SummaryGraph(props) {
    const { pastDays, statisticalData, setPastDays } = props;

    const { Select } = antdUI;
    const { Option } = Select;

    function handleChange(val) {
        setPastDays(val)
    }

    useEffect(() => {
        if (statisticalData?.[0]) {
            init(statisticalData, pastDays)
        }

    }, [ statisticalData, pastDays ])

    return (
        <>
            {
                statisticalData?.[0] ?
                    <>
                        <div className='intervalScreening'>
                            <Select value={ pastDays } defaultValue={ 30 } style={ { width: 100 } }
                                    onChange={ handleChange }>
                                <Option value={ 1 }>日数据</Option>
                                <Option value={ 7 }>周数据</Option>
                                <Option value={ 30 }>月数据</Option>
                                <Option value={ 180 }>六个月数据</Option>
                            </Select>
                        </div>
                        <div id='priceStatistics' style={ { width: '100%', height: '200px' } }></div>
                    </> :
                    <antdUI.Skeleton style={ { marginTop: '80px' } } active></antdUI.Skeleton>
            }
        </>
    )
}

export default SummaryGraph
