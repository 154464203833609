import React, { $login, antdUI } from 'react';
import './index.scss';
import { Switch, Route } from "react-router-dom";
import {
    PesnCtMenu,
    UserConfig,
    MyPurse,
    // Reputation,
    // TransactionFeeRate,
    Favorites,
    News,
    CustomerService
} from '@/components/personalCenter';
import { connect } from 'react-redux';
import { checkUserSignIn } from '@/store/actions/userInfo'

const menuData = [
    {
        title: '账户设置',
        icon: require('@/images/profile/wode.png'),
        select_icon: require('@/images/profile/wodes.png'),
        path: '/profile/userConfig',
        component: UserConfig
    },
    {
        title: '我的钱包',
        icon: require('@/images/profile/qianbao.png'),
        select_icon: require('@/images/profile/qianbaos.png'),
        path: '/profile/wallet',
        component: MyPurse
    },
    // {
    //     title: '信誉系统',
    //     icon: require('@/images/profile/xinyu.png'),
    //     select_icon: require('@/images/profile/xinyus.png'),
    //     path: '/profile/reputation',
    //     component: Reputation
    // },
    // {
    //     title: '交易费率',
    //     icon: require('@/images/profile/jiaoyifeilv.png'),
    //     select_icon: require('@/images/profile/jiaoyifeilvs.png'),
    //     path: '/profile/rate',
    //     component: TransactionFeeRate
    // },
    {
        title: '关注饰品',
        icon: require('@/images/profile/soucang.png'),
        select_icon: require('@/images/profile/soucangs.png'),
        path: '/profile/favorites',
        component: Favorites
    },
    {
        title: '我的消息',
        icon: require('@/images/profile/xiaoxi.png'),
        select_icon: require('@/images/profile/xiaoxis.png'),
        path: '/profile/news',
        component: News
    },
    {
        title: '联系客服',
        icon: require('@/images/profile/kefu.png'),
        select_icon: require('@/images/profile/kefus.png'),
        path: '/profile/customerService',
        component: CustomerService
    }
];

function RouteWithSubRoutes(route) {
    return (
        <Route
            path={ route.path }
            render={ props => (
                <route.component key={ route.path } { ...props } routes={ route.routes }/>
            ) }
        />
    );
}

function PersonalCenter(props) {
    const { userInfo, checkUserSignIn } = props;
    if (window.location.href.match('profile/userConfig#login')) {
        setTimeout(_ => {
            antdUI.message.warning('请先绑定手机号');
            $login()
        })
        window.history.pushState('', '', '/profile/userConfig')
    }
    return (
        <div className="personal-center">
            {/*{false && <Router />}*/ }
            <Switch>
                <PesnCtMenu checkUserSignIn={ checkUserSignIn } userInfo={ userInfo } menuData={ menuData }/>
            </Switch>
            <div className="personal-container">
                <Switch>
                    { menuData.map((route, i) => (
                        <RouteWithSubRoutes key={ i } { ...route } />
                    )) }
                </Switch>
            </div>
        </div>
    );
}


export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    },
    function mapDispatchToProps(disatch) {
        return {
            checkUserSignIn: () => disatch(checkUserSignIn())
        }
    }
)(PersonalCenter);
