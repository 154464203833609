import React, { antdUI } from "react";
import "./index.scss";
import ItemContainer from "./itemContainer/index";

function TraMarContainer(props) {
    const { itemData, itemType, currentPage, total, getDataForPage, marketLoading, contentType, pageSize } = props;
    
    
    const changePage = (currentPage) => {
        getDataForPage(currentPage);
    };
    
    return (
        <div className="trading-market-container">
            <div style={ { marginTop: "17px" } }>
                { itemData.length !== 0 ? (
                    <ItemContainer contentType={ contentType } itemData={ itemData } itemType={ itemType }/>
                ) : (! marketLoading &&
                    <antdUI.Empty
                        description="暂无数据"
                        style={ { width: "100%", margin: "100px auto 0 auto" } }
                    />
                ) }
            </div>
            < antdUI.Spin tip="正在加载 ..."
                          style={ { margin: '150px auto 0 auto', width: '100%' } }
                          size="large" spinning={ marketLoading }/>
            <div className="inventory-pagination">
                { total ? (
                    <antdUI.Pagination
                        onChange={ (curPage) => changePage(curPage) }
                        showSizeChanger={ false }
                        current={ currentPage }
                        defaultCurrent={ 1 }
                        total={ total }
                        pageSize={ pageSize }
                    />
                ) : (
                    ""
                ) }
            </div>
        </div>
    );
}

export default TraMarContainer;
