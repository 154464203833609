import React, { antdUI, useState } from 'react';
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import { setUserSignIn, upDateUserAssets } from '@/store/actions/userInfo'
import ShopSettingBox from '../shopSetting/shopSetting.js'
import './sass/cNav.scss';
import MobiLoginModal from '@/components/login'
import MainPop from './mainPop'

const navData = [
    { title: '饰品交易市场', path: '/marketPlace' },
    // { title: 'MOBI联盟计划', path: '/equityPlan' },
    // { title: 'MOBI权益计划', path: '/affiliateProgram' },
    // { title: '饰品互换', path: '/itemExchange' },
    { title: '我的库存', path: '/inventory' },
    { title: '我的出售', path: '/mySale' },
    { title: '我的购买', path: '/myPur' },
    { title: '个人中心', path: '/profile' }
];

function CNav(props) {
    const [ loginMo, setLoginM ] = useState(false),
        [ loginTitle, setLoginTitle ] = useState('登录/注册');
    const [ settingView, setSettingView ] = useState(false)
    const { userInfo, userAssets, setUserSignIn, upDateUserAssets } = props;
    const isLogin = userInfo.uid || userInfo.steam.steamid;
    const nickname = userInfo.nickname || userInfo.steam?.personaname;
    
    return (
        <div className="c-nav">
            { settingView ? <ShopSettingBox settingView={ settingView } setSettingView={ setSettingView }/> : '' }
            <div className="nav-logo">
                {/*<img src={ require('@/images/home/logo@2x.png') } alt=""/>*/ }
            </div>
            
            <div className="nav-container">
                { navData.map((v, i) => {
                    // if (v.title === "我的出售" || v.title === "我的购买") {
                    //     return <NavLink
                    //         onClick={ _ => {
                    //             antdUI.message.warning("功能正在维护中")
                    //         } }
                    //         className="nav-item" key={ i } to="/home">{ v.title }
                    //     </NavLink>
                    // } else
                    if (v.title === '饰品互换') {
                        return <NavLink
                            onClick={ _ => {
                                antdUI.message.success("请前往MOBI APP，使用该功能")
                            } }
                            className="nav-item" key={ i } to="/home">{ v.title }
                        </NavLink>
                    } else {
                        return (
                            <NavLink activeClassName="current"
                                     className="nav-item" key={ i } to={ v.path }>{ v.title }
                            </NavLink>
                        )
                    }
                }) }
            </div>
            
            <div className="nav-profile">
                <NavLink to="/profile/news#trade">
                    <div className="main-msg">
                        <img src={ require('@/images/home/xiaoxi@2x.png') } alt=""/>
                        { userInfo.newsTips ? <span className="news-tips"></span> : '' }
                    </div>
                </NavLink>
                
                { isLogin ?
                    <antdUI.Popover
                        placement="bottomRight"
                        content={ <MainPop setUserSignIn={ setUserSignIn }
                                           upDateUserAssets={ upDateUserAssets } setSettingView={ setSettingView }
                                           userInfo={ userInfo }
                                           userAssets={ userAssets }/> }
                    >
                        <NavLink to="/profile/userConfig">
                            <div className="main-info ant-dropdown-link">
                                <div className={ [ userInfo.isOnline === 1 ? 'avatar online' : 'avatar' ] }>
                                    { userInfo.steam.steamid ? <img
                                            className={ [ userInfo.isOnline === 1 ? 'avatar-icon online' : 'avatar-icon' ] }
                                            src={ userInfo.steam.avatarfull } alt=""/> :
                                        <img className="avatar-icon"
                                             src={ require('@/images/home/moren touxiang@2x.png') } alt=""/>
                                    }
                                </div>
                                <p className="text-ov1" style={ { width: '90px' } }>{ nickname }</p>
                            </div>
                        </NavLink>
                    </antdUI.Popover>
                    : ""
                }
                {
                    <>
                        <div className="login" style={ { display: isLogin && 'none' } } onClick={ _ => {
                            setLoginTitle('登录/注册')
                            setLoginM(true)
                        } }>
                            <div className="avatar">
                                <img className="avatar-icon"
                                     src={ require('@/images/home/moren touxiang@2x.png') } alt=""/>
                            </div>
                            <p>注册/登录</p>
                        </div>
                        <div className="code-login" style={ { display: 'none' } } onClick={ _ => {
                            setLoginTitle('手机验证码登录认证')
                            setLoginM(true)
                        } }>
                        </div>
                    </>
                    
                }
            </div>
            
            <MobiLoginModal title={ loginTitle } loginMo={ loginMo } setLoginM={ setLoginM }
                            setLoginTitle={ setLoginTitle }/>
        </div>
    );
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo,
            userAssets: state.user.userAssets
        }
    },
    function mapDispatchToProps(disatch) {
        return {
            setUserSignIn: () => disatch(setUserSignIn()),
            upDateUserAssets: () => disatch(upDateUserAssets())
        }
    }
)(CNav);
