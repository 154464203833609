import React, { $api, $tools, antdUI, useState } from 'react';
import './index.scss'
import { setUserSignIn } from '@/store/actions/userInfo';
import { connect } from "react-redux";
import CountryCode from '@/data/countryCode.js'
import Script from 'react-load-script';

function SignUp(props) {
    const promotedCode = window.location.href.match(/#promotedCode=(\S+)/)?.[1]?.slice(0, 6)
    const [ schedule, setSchedule ] = useState('phone'),  // next password
        [ phone, setPhone ] = useState(),
        [ phoneCode, setPhoneCode ] = useState(),
        [ password, setPassword ] = useState(),
        [ repeatPassword, setRepeatPassword ] = useState(),
        [ invitationCode, setInvitationCode ] = useState(promotedCode),
        [ selectVal, setSelectVal ] = useState('+86'),
        [ codeLoa, setCodeLoa ] = useState(false),
        [ signUpLoad, setSignUpLoad ] = useState(false),
        [ btnText, setBtnText ] = useState('发送验证码'),
        [ ticket, setTicket ] = useState(''),
        [ randstr, setRandstr ] = useState('');
    
    const [ sure, setSure ] = useState(false)
    const [ scriptLoaded, setScriptLoaded ] = useState(false);
    const { signUpSendPhoneCode, checkPhoneCode, signUp: mobiSignUp, updatePassword } = $api.user;
    const { debounce, inputValueLimit, checkResult, md5Encryption } = $tools;
    
    const sendCode = async () => {
        if (btnText !== '发送验证码') return
        let countdown = 60,
            timer = null;
        if (! phone) return antdUI.message.warning('您输入的手机号有误')
        setCodeLoa(true)
        let result = await signUpSendPhoneCode({
            phone, ticket, randstr, type: props.type,
            countryCode: selectVal.replace('+', '')
        });
        if (result?.message === "请尝试重新拖动滑块进行验证！") setSure(false)
        setCodeLoa(false)
        if (! checkResult(result)) return
        
        antdUI.message.success("发送成功");
        setBtnText(`重新发送(${ countdown }s)`);
        timer = setInterval(() => {
            countdown--
            if (countdown === 0) {
                clearInterval(timer)
                setBtnText('发送验证码');
            } else {
                setBtnText(`重新发送(${ countdown }s)`);
            }
        }, 1000)
    }
    
    const nextStep = async () => {
        if (! phone) return antdUI.message.warning('您输入的手机号有误')
        if (! phoneCode || ! /[0-9]{6}/.test(phoneCode)) return antdUI.message.error('您输入的验证码格式有误');
        
        let result = await checkPhoneCode({ phone, code: phoneCode, type: props.type })
        if (checkResult(result)) setSchedule('password')
    }
    
    const signUp = async () => {
        if (password === repeatPassword) {
            if (password?.length <= 7) return antdUI.message.warning('密码要大于8位')
            if (password?.replace(/[A-z\d~!@#$%^&*_+><?/.,]/g, '')) return antdUI.message.warning('请勿输出非法字符！');
            let result
            setSignUpLoad(true)
            switch (props.type) {
                case "updatePass":
                    result = await updatePassword({
                        phone, password: md5Encryption(password), code: phoneCode,
                        countryCode: selectVal.replace('+', '')
                    });
                    break;
                case "signUp":
                    let bdVid
                    if (promotedCode === 'BAIDUU') bdVid = localStorage.getItem('bdVid') || undefined;
                    result = await mobiSignUp({
                        phone, phoneCode, password: md5Encryption(password), promotionCode: invitationCode,
                        countryCode: selectVal.replace('+', ''), bdVid
                    });
                    break;
                default:
                    break;
            }
            setSignUpLoad(false)
            if (! checkResult(result)) return
            localStorage.setItem('token', result.data.token)
            antdUI.message.success(`${ props.type === 'updatePass' ? '修改' : '注册' }成功`);
            if (promotedCode === 'SOUL66') {
                window._baq.track("customer_effective", {
                    assets_id: 1767, product_name: '注册'
                })
            }
            if (localStorage.getItem('bdVid')) localStorage.removeItem('bdVid');
            setTimeout(_ => {
                props.setLoginM(false)
                props.setUserSignIn()
            }, 300)
        } else {
            antdUI.message.warning('两次输入的密码不一致');
        }
    }
    const handleScriptError = () => {
        antdUI.message.error("组件加载失败")
    }
    
    const handleScriptLoad = () => {
        setScriptLoaded(true)
    }
    const showView = () => {
        const captcha1 = new window.TencentCaptcha('195039738', function (res) {
            // res（用户主动关闭验证码）= {ret: 2, ticket: null}
            // res（验证成功） = {ret: 0, ticket: "String", randstr: "String"}
            if (res.ret === 0) {
                setTicket(res.ticket)
                setRandstr(res.randstr)
                setSure(true)
            }
        });
        captcha1.show(); // 显示验证码
    }
    const checkPhone = (<div className="mobi-sign-up schedule-phone">
        <antdUI.Input.Group size="large" compact>
            <antdUI.Select onChange={ val => {setSelectVal(`+${ val }`)} }
                           dropdownMatchSelectWidth={ 200 } style={ { width: '90px', fontSiez: '16px' } }
                           value={ selectVal }>
                { CountryCode.map(({ countryCN, countryCode }, idx) => {
                    return <antdUI.Select.Option key={ idx } value={ countryCode }
                    > { countryCN } +{ countryCode }
                    </antdUI.Select.Option>
                }) }
            </antdUI.Select>
            <antdUI.Input
                value={ phone }
                onChange={ (e) => setPhone(inputValueLimit(e.target.value, 11)) }
                style={ { width: '238px' } }
                placeholder="请输入手机号"
            />
        </antdUI.Input.Group>
        
        { scriptLoaded ? <antdUI.Button disabled={ sure } onClick={ _ => showView() }>{ sure ?
            <p><i style={ { color: 'rgb(45, 175, 97)' } } className="iconfont icon-gou"></i>通过
            </p> : "人机滑块验证" }</antdUI.Button> : '' }
        
        <antdUI.Input.Search
            placeholder="请输入验证码"
            value={ phoneCode }
            onChange={ (e) => setPhoneCode(inputValueLimit(e.target.value, 6)) }
            enterButton={ <antdUI.Button style={ { backgroundColor: "#2DAF61", color: "#fff" } }
                                         loading={ codeLoa }>{ btnText }</antdUI.Button> }
            size="large"
            onSearch={ debounce(sendCode, 1500, true) }
        />
        <div className="mobi-sign-up-btn-next" onClick={ debounce(nextStep, 1000, true) }>下一步</div>
    </div>);
    
    const checkPassword = (<div className="mobi-sign-up schedule-password">
        <div className="set-mobi-password">
            <p className="s-password">设置密码</p>
            <antdUI.Input
                size="large"
                value={ password }
                type="password"
                onChange={ (e) => setPassword(inputValueLimit(e.target.value, 16)) }
                // onBlur={(e) => {
                //   if (e.target.value.length <= 5) { antdUI.message.warning('密码太短'); return }
                //   if (password !== repeatPassword) { antdUI.message.warning('两次输入的密码不一致'); return }
                // }}
                placeholder="请输入密码"
                prefix={ <img alt="" style={ { width: '20px' } } src={ require('@/images/login/suo@2x.png') }/> }
            />
            <antdUI.Input
                size="large"
                value={ repeatPassword }
                type="password"
                onChange={ (e) => setRepeatPassword(inputValueLimit(e.target.value, 16)) }
                // onBlur={() => password !== repeatPassword && antdUI.message.warning('两次输入的密码不一致')}
                placeholder="再次输入密码"
                prefix={ <img alt="" style={ { width: '20px' } } src={ require('@/images/login/suo@2x.png') }/> }
            />
        </div>
        { props.type !== 'updatePass' && <div className="set-mobi-invitation">
            <p className="s-invitation">邀请码(选填)</p>
            <antdUI.Input
                size="large"
                value={ invitationCode }
                type="text"
                onChange={ (e) => setInvitationCode(inputValueLimit(e.target.value, 16)) }
                placeholder="输入邀请码"
            />
        </div> }
        <antdUI.Button type="primary" size={ "large" } loading={ signUpLoad }
                       onClick={ debounce(signUp, 1000, true) }>{ props.type === 'updatePass' ? '修改密码' : '注册' }</antdUI.Button>
    </div>);
    
    const registrationSuccess = (<div className="mobi-sign-up">
        <p className="sign-up-success-title">{ props.type === "updatePass" ? "修改" : "已经注册" }成功，3秒后跳转登录</p>
        <div className="icon-success">
            <i className="iconfont icon-gou" style={ { fontSize: '60px', color: '#2DAF61' } }></i>
        </div>
    </div>);
    
    const scheduleRender = function () {
        switch (schedule) {
            case 'phone':
                return checkPhone;
            case 'password':
                return checkPassword;
            case 'success':
                return registrationSuccess;
            default:
                break;
        }
    }
    
    return <>
        <Script
            url="https://turing.captcha.qcloud.com/TCaptcha.js"
            onError={ handleScriptError }
            onLoad={ handleScriptLoad }
        />
        { scheduleRender() }
    </>;
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    },
    function mapDispatchToProps(disatch) {
        return {
            setUserSignIn: () => disatch(setUserSignIn())
        }
    }
)(SignUp);
