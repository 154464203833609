import React, { $csgoWearColor, $tools, useState } from 'react';
import './csBox.scss'
import BuyingDialog from '../../../../../accessoriesList/accessoriesContainer/typePage/buying/buyingDialog';
import { Link } from 'react-router-dom';

function CsBox(props) {
    let { item: v } = props,
        img_bs = "https://steamcommunity-a.akamaihd.net/economy/image/";
    
    
    const { color: exterior_bcc, name: exteriorLabel } = $csgoWearColor(v.exterior)
    const [ releaseDialog, setReleaseDialog ] = useState(false);
    
    const getPageType = (quality) => {
        if (quality === "strange" || quality === "unusual_strange") {
            return 2;
        } else if (quality === 'tournament') {
            return 3;
        } else {
            return 1;
        }
    }
    
    
    return (
        <>
            <div
                className="csgo-box"
            >
                <div onClick={ () => setReleaseDialog(true) } className="_img"
                     onLoad={ (e) => (e.currentTarget.style.opacity = 1) }>
                    { exterior_bcc && (
                        <div
                            style={ { backgroundColor: exterior_bcc } }
                            className="subscript"
                            alt={ exteriorLabel }
                        >
                            { exteriorLabel }
                        </div>
                    ) }
                    <img
                        className="item-img"
                        src={ v?.img_name ? `${ React.StaticBaseUrl }/market-csgo/${ v.img_name }.png`
                            : img_bs + v.icon_url }
                        alt={ v.market_name }
                    />
                
                </div>
                
                <div className='other-info'>
                    <Link
                        target={ '_blank' }
                        to={
                            {
                                pathname: '/marketPlace/accessoriesList',
                                search: $tools.obj2strUrl({
                                    id: v.id,
                                    curGame: '730',
                                    curType: getPageType(v.quality),
                                }, '?')
                            }
                        }
                    >
                        <p title={ v.market_name } className="item-name text-ov1">{ v.market_name }</p>
                    </Link>
                    
                    <p className="price">
                        { Number(v.maxPrice) ? <><small>￥</small>{ v.maxPrice }</> : "暂无" }
                    </p>
                </div>
            
            </div>
            {
                releaseDialog &&
                <BuyingDialog csData={ v } releaseDialog={ releaseDialog } setReleaseDialog={ setReleaseDialog }/>
            }
        </>
    
    );
}

export default CsBox;
