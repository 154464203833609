import React, {
    $api,
    $bindSteamApiKey,
    $bindSteamLink,
    $login,
    $toBindSteam,
    $tools,
    antdUI,
    useEffect,
    useState
} from "react";
import "../index.scss";
import ItemWear from "../../../itemContainer/itemWear";
import ItemDetailPopover from "@/components/itemDetailPopover";
import Sticker from "./sticker";
import OnShelfContainer from '@/components/onShelf/onShelf'
import Gem from "./gem";
import Payment from "../payment";
import { Doppler, GammaDoppler } from '../../../../data/dopplerData';
import { connect } from 'react-redux'
import CsgoItems2DModal from '@/components/csgoItems2D'

const csgoOtherData = [ "CSGO_Tool_Sticker", "CSGO_Type_Spray", "CSGO_Type_Tool", "CSGO_Type_Collectible", "CSGO_Type_Collectible", "CSGO_Type_Ticket", "CSGO_Tool_GiftTag", "CSGO_Type_MusicKit", "CSGO_Type_WeaponCase", "Type_CustomPlayer", "CSGO_Tool_Patch" ]

const dopplerData = [ ...Doppler.children, ...GammaDoppler.children ];

function matchDoppler(value) {
    return dopplerData.filter(item => item.value === value)?.[0] || '';
}

function OnSale(props) {
    const {
        total, curPage, curExId, filterPage, userInfo,
        saleData, alipay_amount, searchData, getDetailList,
        upDateUserAssets, curGame, tableLoading, navData, setSaleData
    } = props;
    const { curType } = searchData;
    const [ isModalVisible, setIsModalVisible ] = useState(false),
        [ buyPrice, setBuyPrice ] = useState(199.8),
        [ curOderNum, setCurOrderNum ] = useState(0), [ _onShelfData, set_onShelfData ] = useState([]),
        [ isInt, setIsInt ] = useState(false), [ modalShow, setModalShow ] = useState(false),
        [ notWorth, setNotWorth ] = useState(false),
        [ buyIdx, setBuyIdx ] = useState(''),
        [ dataSource, setDataSource ] = useState([]),
        [ checkDialog, setCheckDialog ] = useState(false),
        [ curCheckData, setCurCheckData ] = useState(''),
        [ checkLoading, setCheckLoading ] = useState(false),
        [ curOrderIsOnlyState, setCurOrderIsOnlyState ] = useState(false);
    
    const [ activePay ] = useState("alipay"); // 1:支付宝  2：其他
    const [ Loading, setLoading ] = useState(false);
    const [ sucVisible, setSucVisible ] = useState(false);
    
    const [ finalColumns, setFinalColumns ] = useState([]);
    
    const [ paymentItemInfo, setPaymentItemInfo ] = useState({});
    
    const [ CsgoItems2DModalData ] = useState({})
    const [ CsgoItems2DModalView, setView ] = useState(false)
    const paySubmit = async () => {
        setLoading(true);
        let res = await $api.market.itemBuy({
            orderNumber: curOderNum,
            paymethod: activePay,
            appid: curGame,
        });
        setTimeout(_ => setLoading(false), 500)
        if (
            $tools.checkResult(res) &&
            res.data &&
            res.data.message === "buy success"
        ) {
            setTimeout(() => {
                upDateUserAssets();
                setCurOrderIsOnlyState(Boolean(saleData[buyIdx]?.userInfo?.onlyBuyerSendoffer));
                setSucVisible(true);
                setTimeout(() => {
                    setIsModalVisible(false);
                    antdUI.message.success('购买成功！')
                    getDetailList({ id: curExId, curPage: 1 });
                }, 200)
            }, 500);
        } else {
            getDetailList({ id: curExId, curPage: 1 });
        }
    };
    
    const closeChoose = () => {
        setSucVisible(false);
        // antdUI.message.warning("请选择发起报价方式，如执意不选择发起报价方式，默认为买家发起报价");
    }
    
    const checkItIsInStock = async () => {
        setCheckLoading(true);
        try {
            const res = await $api.market.checkItemStock({ orderNumber: curCheckData })
            if ($tools.checkResult(res)) {
                const { message } = res.data || {};
                antdUI.message.info(message)
            } else {
                const { message } = res || {};
                const refreshMessage = [ '订单不存在', '该饰品已不在卖家库存，请选择购买其它饰品!' ];
                if (refreshMessage.includes(message)) {
                    getDetailList({ id: curExId, curPage: 1 });
                }
            }
            setCheckDialog(false);
            setCheckLoading(false);
        } catch (e) {
            setCheckDialog(false);
            setCheckLoading(false);
        }
    }
    
    const atSellerSendoffsText = (
        <div className="apikey-box-text" style={ {
            padding: '14px', width: '200px', backgroundColor: '#191919',
            boxShadow: `0px 0px 6px 1px #191919`
        } }>
            <p className="title" style={ { fontSize: '14px', color: '#8c9298', marginBottom: '8px' } }>
                关于请卖家发送报价</p>
            <p style={ { color: '#fff', fontSize: '14px' } }>
                当前卖家选择了只能买家向其发送报价，因此你只能选择前往App发送报价，
                如果不做选择，或强制关闭，默认是买家发起报价
            </p>
        </div>
    )
    
    const chooseDelivery = async (deliveryMethod = 'buyer') => {
        if (deliveryMethod === 'buyer') {
            antdUI.message.success("请在5分钟内前往MOBI APP我的购买发送报价~");
            setSucVisible(false);
        } else {
            let postData = { deliveryMethod, orderNumber: curOderNum, appid: curGame };
            let res = await $api.market.updateDeliverMethod(postData);
            if ($tools.checkResult(res) && res?.status === 200) {
                antdUI.message.success("请等待卖家发起报价，后续需确认收货~")
                setSucVisible(false);
            }
        }
    }
    
    const updatePrice = (item) => {
        set_onShelfData([ item ])
        setModalShow(true)
    }
    
    useEffect(() => {
        let _saleData = JSON.parse(JSON.stringify(saleData))
        _saleData.forEach(_ => _.price = navData.price)
        setSaleData(_saleData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ navData ])
    
    const createOnSaleData = () => {
        let arr = [];
        if (curGame === "730") {
            navData.type && ! filterShow(navData.type) ?
                (columnscs[1].title = '') :
                (columnscs[1].title =
                    <p style={ { textIndent: "70px", fontWeight: 900, fontSize: "14px" } }>磨损度</p>);
            setFinalColumns(columnscs);
            saleData.forEach((item, _index) => {
                const {
                    id,
                    icon_url, floatvalue, onShelfDesc,
                    sellingPrice, stickers, create_time, userInfo: _userInfo = {}, orderNumber,
                    paintindex, market_name
                } = item;
                const { img_name } = navData
                //注入数据
                item.market_item_detail = {
                    error: 0,
                    msg: {
                        floatvalue,
                        stickers: stickers || [],
                        paintseed: item.paintseed,
                        paintindex: item.paintindex,
                        customname: item.customname,
                    },
                };
                item.user_info = {
                    name: _userInfo?.nickname || _userInfo?.UserSteam?.personaname,
                    avatar: _userInfo?.UserSteam?.avatarfull,
                };
                arr.push({
                    name: (
                        <div className="goods-icon" style={ { margin: onShelfDesc ? `0px auto 30px auto` : `0 auto` } }>
                            <antdUI.Popover
                                placement="rightTop"
                                content={ <ItemDetailPopover csData={ item } firstType={ navData.type }/> }
                            >
                                <img
                                    src={ img_name ? `${ React.StaticBaseUrl }/market-csgo/${ img_name }.png`
                                        : $tools.BASE_STEAM_IMG + icon_url }
                                    alt={ "饰品" }
                                />
                            </antdUI.Popover>
                            {
                                onShelfDesc &&
                                <div className='remark'>
                                    <i className='iconfont icon-message'></i>
                                    <p className='text-ov1'>{ onShelfDesc }</p>
                                </div>
                            }
                        </div>
                    
                    ),
                    floatvalue: (
                        navData.type && filterShow(navData.type) ?
                            <div style={ { display: "flex", alignItems: "center" } }>
                                <div className="wear-container"
                                     style={ { marginLeft: matchDoppler(paintindex) && '-30px' } }>
                                    <ItemWear
                                        style={ { paddingTop: "20px" } }
                                        wearValue={ floatvalue }
                                        curType={ curType }
                                        acStickers={ JSON.parse(JSON.stringify(stickers)) }
                                    ></ItemWear>
                                </div>
                                { matchDoppler(paintindex) && <div className='style-name'
                                                                   style={ { backgroundColor: matchDoppler(paintindex).color } }>{ matchDoppler(paintindex).label }</div> }
                                
                                <Sticker stickers={ stickers }></Sticker>
                            </div> :
                            <div style={ { textAlign: 'center' } }> { market_name }</div>
                    ),
                    sellingPrice: (
                        <span className="feature-price">
              <span className="feature-price-icon">￥</span>
                            { sellingPrice }
            </span>
                    ),
                    time: <p>{ create_time }</p>,
                    seller: (
                        <div>
                            <img
                                className="avatar"
                                src={ _userInfo?.UserSteam?.avatarfull }
                                alt=""
                            />
                            <span className="seller">
                { _userInfo?.nickname || _userInfo?.UserSteam?.personaname }
              </span>
                            {
                                _userInfo.onlyBuyerSendoffer ? <> <br/> <span className="seller">
                  该卖家只支持买家发送报价
                </span></> : ''
                            }
                        </div>
                    ),
                    key: id,
                    operating: (
                        <> {
                            (userInfo.uid && userInfo.uid === _userInfo.uid) ? <antdUI.Button
                                style={ {
                                    width: "76px", borderRadius: "5px", color: '#2daf61',
                                    backgroundColor: 'transparent'
                                } }
                                type={ "primary" }
                                onClick={ () => {
                                    if (! userInfo?.steam?.steamid && ! userInfo.uid) return $login() // 没登录
                                    if (userInfo?.steam?.steamid && ! userInfo.uid) return window.location.href = '/profile/userConfig#login'
                                    if (! userInfo?.steam?.steamid && userInfo.uid) return $toBindSteam()
                                    // if (userInfo.real_name_status !== 1) return $toReal()
                                    if (! userInfo?.steam?.transactionLink) return $bindSteamLink()
                                    if (! userInfo?.steam?.apiKey) return $bindSteamApiKey()
                                    updatePrice(item)
                                } }
                            >改价
                            </antdUI.Button> : <antdUI.Button
                                style={ { width: "76px", borderRadius: "5px" } }
                                type={ "primary" }
                                onClick={ () => {
                                    if (! userInfo?.steam?.steamid && ! userInfo.uid) return $login() // 没登录
                                    if (userInfo?.steam?.steamid && ! userInfo.uid) return window.location.href = '/profile/userConfig#login'
                                    if (! userInfo?.steam?.steamid && userInfo.uid) return $toBindSteam()
                                    // if (userInfo.real_name_status !== 1) return $toReal()
                                    if (! userInfo?.steam?.transactionLink) return $bindSteamLink()
                                    toBuy(sellingPrice, orderNumber, _index, {
                                        icon_url,
                                        floatvalue,
                                        img_name,
                                        market_name
                                    })
                                } }
                            >
                                购买
                            </antdUI.Button>
                        }
                            { (userInfo.uid && userInfo.uid !== _userInfo.uid) &&
                                <p onClick={ () => {
                                    setCheckDialog(true);
                                    setCurCheckData(orderNumber)
                                } } className='inventory-inspection'>在库检测</p>
                            }
                        </>
                    ),
                    // credit: <h3 style={{ color: "#FFA800" }}>极好</h3>,
                });
            });
        } else {
            setFinalColumns(columnsd2);
            saleData.forEach((item, _index) => {
                const {
                    id,
                    icon_url,
                    sellingPrice,
                    create_time,
                    userInfo: _userInfo = {},
                    orderNumber,
                    market_name,
                    descriptions,
                    onShelfDesc,
                } = item;
                item.user_info = {
                    name: _userInfo?.nickname || _userInfo?.UserSteam?.personaname,
                    avatar: _userInfo?.UserSteam?.avatarfull,
                };
                const { img_name } = navData
                arr.push({
                    name: (
                        <>
                            <div className='goods-name-gem'
                                 style={ { margin: onShelfDesc ? `0px auto 30px auto` : `0 auto` } }>
                                <antdUI.Popover
                                    // trigger="click"
                                    placement="rightTop"
                                    content={ <ItemDetailPopover csData={ item }
                                        // firstType={}
                                    /> }
                                >
                                    <div className="goods-icon" style={ { background: 'none', margin: '0 20px' } }>
                                        <img
                                            src={ img_name ? `${ React.StaticBaseUrl }/market-csgo/${ img_name }.png`
                                                : $tools.BASE_STEAM_IMG + icon_url }
                                            alt={ "饰品" }
                                        />
                                    </div>
                                </antdUI.Popover>
                                <div className='gem-con-main'>
                                    <Gem name={ market_name } descriptions={ descriptions }></Gem>
                                </div>
                            
                            </div>
                            {
                                onShelfDesc &&
                                <div className='remark dota2'>
                                    <i className='iconfont icon-message'></i>
                                    <p className='text-ov1'>{ onShelfDesc || '暂无描述' }</p>
                                </div>
                            }</>
                    ),
                    sellingPrice: (
                        <span className="feature-price">
              <span className="feature-price-icon">￥</span>
                            { sellingPrice }
            </span>
                    ),
                    time: <p>{ create_time }</p>,
                    seller: (
                        <div>
                            <img
                                className="avatar"
                                src={ _userInfo?.UserSteam?.avatarfull }
                                alt=""
                            />
                            <span className="seller">
                { _userInfo?.nickname || _userInfo?.UserSteam?.personaname }
              </span>
                            {
                                _userInfo.onlyBuyerSendoffer ? <> <br/> <span className="seller">
                  该卖家只支持买家发送报价
                </span></> : ''
                            }
                        </div>
                    ),
                    key: id,
                    operating:
                        <> {
                            userInfo.uid === _userInfo.uid ? <antdUI.Button
                                style={ { width: "76px", borderRadius: "5px" } }
                                type={ "primary" }
                                onClick={ () => {
                                    if (! userInfo?.steam?.steamid && ! userInfo.uid) return $login() // 没登录
                                    if (userInfo?.steam?.steamid && ! userInfo.uid) return window.location.href = '/profile/userConfig#login'
                                    if (! userInfo?.steam?.steamid && userInfo.uid) return $toBindSteam()
                                    // if (userInfo.real_name_status !== 1) return $toReal()
                                    if (! userInfo?.steam?.transactionLink) return $bindSteamLink()
                                    if (! userInfo?.steam?.apiKey) return $bindSteamApiKey()
                                    updatePrice(item)
                                } }
                            >
                                改价
                            </antdUI.Button> : <antdUI.Button
                                style={ { width: "76px", borderRadius: "5px" } }
                                type={ "primary" }
                                onClick={ () => {
                                    if (! userInfo?.steam?.steamid && ! userInfo.uid) return $login() // 没登录
                                    if (userInfo?.steam?.steamid && ! userInfo.uid) return window.location.href = '/profile/userConfig#login'
                                    if (! userInfo?.steam?.steamid && userInfo.uid) return $toBindSteam()
                                    // if (userInfo.real_name_status !== 1) return $toReal()
                                    if (! userInfo?.steam?.transactionLink) return $bindSteamLink()
                                    toBuy(sellingPrice, orderNumber, _index, { icon_url, market_name })
                                } }
                            >
                                购买
                            </antdUI.Button>
                        }</>
                    ,
                    // credit: <h3 style={{ color: "#FFA800" }}>极好</h3>,
                });
            });
        }
        setDataSource(arr);
    };
    
    const toBuy = (sellingPrice, orderNumber, _index, itemInfo) => {
        if (userInfo.uid || userInfo.steamid) {
            setPaymentItemInfo(itemInfo)
            setIsModalVisible(true);
            setBuyPrice(sellingPrice);
            setCurOrderNum(orderNumber);
            setBuyIdx(_index)
            if ((alipay_amount ? Number(alipay_amount) : 0) < sellingPrice) {
                setNotWorth(true);
            } else {
                setNotWorth(false);
            }
        } else {
            $login();
        }
    }
    
    const paginationProps = {
        position: [ "bottomCenter" ],
        pageSize: 10,
        current: curPage,
        total: total, //数据的总的条数
        onChange: (current) => filterPage(current), //点击当前页码
    };
    
    useEffect(() => {
        if (isInt) {
            createOnSaleData();        //创建在售中数据
        } else {
            setIsInt(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ saleData, alipay_amount ]);
    
    return (
        <>
            <antdUI.Modal
                width="600px"
                maskClosable={ false } // 点击蒙层是否可以关闭
                footer={ false } // 是否需要按钮
                title="支付订单"
                className="buy-payment-modal"
                visible={ isModalVisible }
                onCancel={ (_) => {
                    setIsModalVisible(false);
                    setLoading(false)
                } }>
                <Payment
                    onlyBuyerSendoffer={ saleData[buyIdx]?.userInfo?.onlyBuyerSendoffer }
                    alipay_amount={ alipay_amount }
                    buyPrice={ buyPrice }
                    notWorth={ notWorth }
                    Loading={ Loading }
                    paySubmit={ paySubmit }
                    setSucVisible={ setSucVisible }
                    curOderNum={ curOderNum }
                    appid={ curGame }
                    paymentItemInfo={ paymentItemInfo }
                ></Payment>
            </antdUI.Modal>
            <antdUI.Modal
                bodyStyle={ { minHeight: '200px' } }
                width="480px"
                maskClosable={ true } // 点击蒙层是否可以关闭
                footer={ false } // 是否需要按钮
                title={ <p style={ modalTitle }>支付成功</p> }
                visible={ sucVisible }
                onCancel={ closeChoose }>
                <div className='accessories-buyer-choose'>
                    <p className='buyer-choose-title'>等待卖家发起报价</p>
                    {
                        false && <>
                            <div className='buyer-choose-tab'>
                                <button onClick={ () => chooseDelivery() } className='buyer-choose-btn'>前往App发报价
                                </button>
                                <p className='buyer-choose-tip'>你发起报价，卖家接受报价；无需收货</p>
                            </div>
                            <div className='buyer-choose-tab'>
                                { curOrderIsOnlyState ?
                                    <antdUI.Popover
                                        placement="right"
                                        content={ atSellerSendoffsText }
                                    ><i style={ {
                                        position: 'absolute',
                                        right: '25px'
                                    } } className="iconfont icon-wenhao"></i>
                                    </antdUI.Popover> : ''
                                }
                                <button style={ { background: curOrderIsOnlyState ? '#69c98f' : '#2DAF61' } }
                                        disabled={ curOrderIsOnlyState }
                                        onClick={ () => chooseDelivery('seller') }
                                        className='buyer-choose-btn'>请卖家发报价
                                </button>
                                <p className='buyer-choose-tip'>卖家发起报价，需要你收货，未收货将扣2%金额</p>
                            </div>
                        </>
                    }
                </div>
            </antdUI.Modal>
            <antdUI.Modal
                title="改价"
                visible={ modalShow }  // 是否展示的布尔值
                footer={ false } // 是否需要按钮
                onCancel={ () => {
                    setModalShow(false);
                    set_onShelfData([]);
                } }
                maskClosable={ false }  // 点击蒙层是否可以关闭
                destroyOnClose={ true } // 关闭之后销毁子节点
                centered={ true } // 居中
                width="1100px"
            >
                <OnShelfContainer
                    navData={ navData }
                    type="changePrice" set_onShelfData={ set_onShelfData }
                    setModalShow={ setModalShow }
                    onShelfType="onSale"
                    getDetailList={ () => {
                        getDetailList({ id: curExId, curPage });
                    } }
                    currentGame={ curGame }
                    onShelfData={ _onShelfData }/>
            </antdUI.Modal>
            { CsgoItems2DModalView && <CsgoItems2DModal marketData={ CsgoItems2DModalData }
                                                        view={ CsgoItems2DModalView }
                                                        setView={ setView }></CsgoItems2DModal> }
            <antdUI.Table
                loading={ tableLoading }
                pagination={ paginationProps }
                dataSource={ dataSource }
                columns={ finalColumns }
            ></antdUI.Table>
            
            <antdUI.Modal
                title="在库检测"
                visible={ checkDialog }  // 是否展示的布尔值
                confirmLoading={ checkLoading }
                onCancel={ () => {
                    setCheckDialog(false);
                } }
                onOk={ async () => {
                    await checkItIsInStock()
                } }
                cancelText={ '取消' }
                okText={ '确定' }
                maskClosable={ false }  // 点击蒙层是否可以关闭
                destroyOnClose={ true } // 关闭之后销毁子节点
                centered={ true } // 居中
                width="400px"
            >
                是否检查该饰品存在于卖家库存?本功能每日可使用5次。查询有效性会因Steam网络波动受到影响。
            </antdUI.Modal>
        </>
    );
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    }
)(OnSale);


let titleStyle = { textAlign: "center", fontWeight: 900, fontSize: "14px" };
let columnscs = [
    {
        title: <p style={ titleStyle }>饰品名称</p>,
        dataIndex: "name",
        key: "name",
        align: "center",
    },
    {
        title: (
            <p style={ { textIndent: "70px", fontWeight: 900, fontSize: "14px" } }>
                磨损度
            </p>
        ),
        dataIndex: "floatvalue",
        key: "floatvalue",
        // width: 330,
        require: true,
    },
    {
        title: <p style={ titleStyle }>卖家</p>,
        dataIndex: "seller",
        key: "seller",
        align: "center",
    },
    // {
    //   title: <p style={titleStyle}>信誉</p>,
    //   dataIndex: "credit",
    //   key: "credit",
    //   align: "center",
    // },
    {
        title: <p style={ titleStyle }>上架时间</p>,
        dataIndex: "time",
        key: "time",
        align: "center",
    },
    {
        title: <p style={ titleStyle }>价格</p>,
        dataIndex: "sellingPrice",
        key: "sellingPrice",
        align: "center",
    },
    {
        title: <p style={ titleStyle }>操作(购买)</p>,
        dataIndex: "operating",
        key: "operating",
        align: "center",
    },
];

let columnsd2 = [
    {
        title: <p style={ { textIndent: "34px", fontWeight: 900, fontSize: "14px" } }>饰品名称</p>,
        dataIndex: "name",
        key: "name",
        // align: "center",
    },
    {
        title: <p style={ titleStyle }>卖家</p>,
        dataIndex: "seller",
        key: "seller",
        align: "center",
    },
    // {
    //   title: <p style={titleStyle}>信誉</p>,
    //   dataIndex: "credit",
    //   key: "credit",
    //   align: "center",
    // },
    {
        title: <p style={ titleStyle }>上架时间</p>,
        dataIndex: "time",
        key: "time",
        align: "center",
    },
    {
        title: <p style={ titleStyle }>价格</p>,
        dataIndex: "sellingPrice",
        key: "sellingPrice",
        align: "center",
    },
    {
        title: <p style={ titleStyle }>操作(购买)</p>,
        dataIndex: "operating",
        key: "operating",
        align: "center",
    },
];

const modalTitle = {
    fontSize: '16px',
    fontWeight: '600',
}

function filterShow(type) {
    return csgoOtherData.indexOf(type) === -1;
}
