import React, { $api, $csgoWearColor, $tools, antdUI, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./csgo.scss";
import ItemWear from "../itemWear";
import ItemDetailPopover from "@/components/itemDetailPopover";
import { _MarbleFade, Doppler, GammaDoppler } from '@/data/dopplerData.js'
import { allCustomItemData, allCustomItemDataTree } from '@/data/replacingAnalogData.js'

// const _tags = [
//   { category: "Type", localized_category_name: "类型", localized_tag_name: "匕首" },
// { category: "Weapon", localized_category_name: "武器", localized_tag_name: "蝴蝶刀" },
// { category: "ItemSet", localized_category_name: "收藏品", localized_tag_name: "突围收藏品" },
// { category: "Quality", localized_category_name: "类别", localized_tag_name: "★", color: "8650AC" },
// { category: "Rarity", localized_category_name: "品质", localized_tag_name: "隐秘", color: "eb4b4b" },
// { category: "Exterior", localized_category_name: "外观", localized_tag_name: "无涂装" }]

function CsgoItems(props) {
    let {
            style: csgo_st, item: v, setItemDetail, selected: _sel, changeItem: _changeItem,
            index, inventoryBoxType, setMaterialData, materialData, setRarity, setView, setModalData
        } = props,
        img_bs = "https://steamcommunity-a.akamaihd.net/economy/image/", tStyle;
    if (v.market_name.match(' 多普勒 ')) {
        tStyle = Doppler.children;
    } else if (v.market_name.match(' 伽玛多普勒 ')) {
        tStyle = GammaDoppler.children;
    } else if (v.market_name.match('爪子刀（★） | 渐变大理石 ') || v.market_name.match('刺刀（★） | 渐变大理石 ')
        || v.market_name.match('折叠刀（★） | 渐变大理石 ') || v.market_name.match('穿肠刀（★） | 渐变大理石 ')) {
        tStyle = _MarbleFade.children;
    }
    let itemsStyle = {}
    if (v.market_item_detail &&
        v.market_item_detail?.msg &&
        v.market_item_detail?.msg.paintindex && tStyle) {
        if (tStyle[0].value[0] === '[') {
            itemsStyle = tStyle.filter(_ => JSON.parse(_.value).indexOf(Number(v.market_item_detail?.msg.paintseed)) !== -1)[0]
        } else {
            itemsStyle = tStyle.filter(_ => _.value === Number(v.market_item_detail?.msg.paintindex))[0]
        }
    }
    const [ floatval, setFloatval ] = useState(),
        [ parsingLod, setParsing ] = useState(false);
    const { obj2strUrl } = $tools
    const _filterData = (type) => {
        return v.tags.filter((item) => {
            let category = item.category.toLowerCase();
            return category === type;
        });
    };
    
    useEffect(() => {
        v.market_item_detail &&
        Number(v.market_item_detail.error) === 0 &&
        v.market_item_detail?.msg.floatvalue &&
        v.market_item_detail?.msg.floatvalue !== "0" &&
        setFloatval(v.market_item_detail?.msg.floatvalue);
    }, [ v.market_item_detail ]);
    
    let exterior = _filterData("exterior")[0],
        quality = _filterData("quality"),
        exterior_bcc;
    if (quality.length >= 2) {
        quality = quality.filter(
            (item) => item.internal_name === "unusual_strange"
        )[0];
    } else {
        quality = quality[0];
    }
    const handleTime = () => {
        let { day, hours, minutes } = $tools.countDown({
            time: new Date(v._classInfo.owner_descriptions[1].value).getTime(),
            eight: 25200000
        });
        
        return `${ day }天${ hours }时${ minutes }分`;
    };
    
    const getMarketItemDetail = async (inspecturl, assetid, type) => {
        ! type && setParsing(true);
        let data = await $api.steam.getMarketItemDetail({ inspecturl });
        let market_item_detail = data?.data || {};
        setParsing(false);
        if (Number(market_item_detail.error) === 0) {
            setItemDetail(market_item_detail, assetid);
        } else {
            antdUI.message.warning("解析超时，请稍后重试");
        }
    };
    const getPageType = (quality) => {
        if (quality === "strange" || quality === "unusual_strange") {
            return 2
        } else {
            return 1
        }
    }
    const changeItem = async function (e) {
        if (inventoryBoxType === 'myInventory') {
            if (! _sel) return
            let targName = e.target.className;
            if (! targName || targName === 'gun' || targName === 'describe' ||
                targName === 'item price' || targName === 'buy-msg' || targName === 'checkView') return
            if (targName === "item-name") {
                // window.location.href = `/marketPlace/accessoriesList?curExId=724&curGame=730&asHashName=${v.market_hash_name}&curType=${getPageType(v.quality)}`
            } else if (targName === "parsing-btn") {
                const intName = v.tags.filter(item => item.category === 'Type')[0]?.internal_name
                if ([ 'CSGO_Type_Knife', 'CSGO_Type_Pistol', 'CSGO_Type_Rifle', 'CSGO_Type_SniperRifle', 'CSGO_Type_SMG', 'CSGO_Type_Shotgun', 'CSGO_Type_Machinegun', 'Type_Hands' ].indexOf(intName) !== -1) {
                    ! parsingLod && await getMarketItemDetail(v.actions[0].link, v.assetid);
                } else {
                    return antdUI.message.warning("无法解析");
                }
                if (v.market_item_detail?.msg?.screenshot?.status === 'DONE') {
                    setModalData({
                        weapon: v.tags.filter(_ => _.category === 'Weapon')[0]?.internal_name,
                        ...v, type: v.tags.filter(_ => _.category === 'Type')[0]?.internal_name,
                        rarity: v.tags.filter(_ => _.category === 'Rarity')[0]?.internal_name,
                        quality: v.tags.filter(_ => _.category === 'Quality')[0]?.internal_name
                    })
                    setView(true)
                } else {
                }
            } else if (targName === 'd3-btn') {
                const result_3d = await $api.steam.texture3D({ url: v.actions[0].link })
                if (! result_3d?.data) return antdUI.message.warning('监视失败')
                const { body, code } = result_3d.data
                if (code === 0) {
                    if (body.status === 'DONE') {
                        window.open(`https://www.mobi2077.com/item_3d/?url=${ v.actions[0].link }`)
                    } else {
                    }
                } else {
                    return antdUI.message.warning('监视失败！')
                }
            } else {
                if (v.tradable && v.putShelf !== 1 && v.putShelf !== 2) {
                    if (_sel[0]) {
                        _changeItem(v, "cancel");
                    } else {
                        _changeItem(v, "select");
                    }
                }
            }
        } else if (inventoryBoxType === 'replacing') {
            let met = allCustomItemData.filter(_ => v.name.replace(/（StatTrak™） \|/g, ' |') === _.name)[0]
            if (met) {
                const { len: _len } = met;
                if (_len === 0) return antdUI.message.warning('该物品无法进行汰换！')
                const _tData = JSON.parse((JSON.stringify(materialData)));
                if (materialData[0] && met.en_rarity !== materialData[0].en_rarity) return antdUI.message.warning(`您当前只能选择"${ materialData[0].rarity }"品质的饰品进行汰换模拟！`)
                if (materialData.length >= 10) return antdUI.message.warning('最多选择10件物品进行汰换！')
                const materWearVal = v?.market_item_detail?.msg?.floatvalue;
                if (! materWearVal) {
                    antdUI.message.warning('请等待解析完成再进行汰换模拟！')
                    return await getMarketItemDetail(v.actions[0].link, v.assetid);
                }
                ! _tData[0] && setRarity([ met.en_rarity ])
                setMaterialData([ ..._tData, {
                    ...JSON.parse(JSON.stringify(met)),
                    targetBoxName: allCustomItemDataTree.filter(treeI => treeI.box_value === met.target_box)?.[0].name,
                    wearVal: materWearVal
                } ])
                antdUI.message.success('选择成功')
            } else {
                antdUI.message.warning('该物品无法进行汰换！')
            }
        }
    };
    if (exterior?.localized_tag_name) {
        exterior_bcc = $csgoWearColor(exterior.localized_tag_name);
    }
    const fraudWarnings = v?.fraudwarnings?.[0]
    return (
        <div style={ csgo_st } className="csgo-items" onClick={ changeItem }>
            <div className="_img" onLoad={ (e) => (e.currentTarget.style.opacity = 1) }>
                <div className="item-quality" style={ { alignItems: "start" } }>
                    <p
                        value={ quality?.internal_name }
                        style={ {
                            color: `#${ quality?.color }`,
                            padding: "0 2px",
                            height: "18px",
                            lineHeight: "18px"
                        } }
                        className="item_quality"
                    >
                        { quality?.localized_tag_name }
                    </p>
                    { v?.market_item_detail?.msg?.paintseed && (
                        <p className="item_paint_seed"
                           style={ { padding: "0 2px", height: "18px", lineHeight: "18px" } }>
                            { v?.market_item_detail?.msg?.paintseed }
                        </p>
                    ) }
                    { itemsStyle?.value && tStyle && (
                        <p className="item_paint_index" style={ {
                            padding: "0 2px",
                            height: "18px",
                            lineHeight: "18px",
                            color: itemsStyle?.color
                        } }>{ itemsStyle?.label }</p>
                    ) }
                    <img
                        title="游戏中检视"
                        className="link-icon" style={ { height: "11px", marginTop: '3.5px' } }
                        onClick={ () => window.open(`${ v.actions[0].link }`) }
                        src={ require("@/images/inventory/yanjing@2x.png") }
                        alt=""
                    />
                </div>
                
                { exterior_bcc && (
                    <div
                        style={ {
                            backgroundColor: exterior_bcc,
                            padding: "0 2px",
                            height: "18px",
                            lineHeight: "18px"
                        } }
                        className="subscript"
                        alt={ exterior.internal_name }
                    >
                        { exterior.localized_tag_name }
                    </div>
                ) }
                <antdUI.Popover
                    placement="rightTop"
                    // trigger="click"
                    content={ <ItemDetailPopover type="onSale" index={ index } csData={ v }/> }
                >
                    
                    <img
                        className="item-img"
                        src={ img_bs + v.icon_url }
                        alt={ v.market_name }
                    />
                </antdUI.Popover>
                
                { floatval && (
                    <div className="item-wear-container">
                        <ItemWear wearValue={ floatval } market_item_detail={ v.market_item_detail }/>
                    </div>
                ) }
                
                {/*{ [ 'CSGO_Type_Knife', 'CSGO_Type_Pistol', 'CSGO_Type_Rifle', 'CSGO_Type_SniperRifle',*/ }
                {/*    'CSGO_Type_SMG', 'CSGO_Type_Shotgun', 'CSGO_Type_Machinegun', 'Type_Hands' ].indexOf(v.tags.filter(item => {*/ }
                {/*    return item.category === 'Type'*/ }
                {/*})[0]?.internal_name) !== -1 && <div className="d2-btn"*/ }
                {/*                                     style={ { bottom: ! floatval && "54px" } }>2D</div> }*/ }
                
                
                { fraudWarnings && <antdUI.Popover
                    placement="top"
                    content={ <p style={ {
                        maxWidth: 300, padding: "6px 8px", background: "#212327", color: "orange"
                    } }>{ fraudWarnings }</p> }>
                    <div className="name-label" style={ { bottom: ! floatval && "52px" } }>
                        
                        <i className="iconfont icon-zhuyi"></i>
                    </div>
                </antdUI.Popover> }
                { false && [ 'CSGO_Type_Knife', 'CSGO_Type_Pistol', 'CSGO_Type_Rifle', 'CSGO_Type_SniperRifle',
                        'CSGO_Type_SMG', 'CSGO_Type_Shotgun', 'CSGO_Type_Machinegun', 'Type_Hands' ].includes(
                        v.tags.filter(item => {
                            return item.category === 'Type'
                        })[0]?.internal_name) &&
                    <div className="d3-btn" style={ { bottom: ! floatval && "34px" } }>3D</div> }
                
                {
                    [ 'CSGO_Type_Knife', 'CSGO_Type_Pistol', 'CSGO_Type_Rifle', 'CSGO_Type_SniperRifle',
                        'CSGO_Type_SMG', 'CSGO_Type_Shotgun', 'CSGO_Type_Machinegun', 'Type_Hands' ].includes(v.tags.filter(item => {
                        return item.category === 'Type'
                    })[0]?.internal_name) && <div className="parsing-btn"
                                                  style={ { bottom: ! floatval && "10px" } }>解析{ parsingLod && "中" }</div>
                }
            
            
            </div>
            
            <Link
                to={
                    {
                        pathname: '/marketPlace/accessoriesList',
                        search: obj2strUrl({
                            id: v.itemid,
                            curGame: '730',
                            curType: getPageType(v.tags.filter(item => item.category === 'Quality')[0].internal_name),
                        }, '?')
                    }
                }
                target='_blank'
            ><p className="item-name">{ v.market_name }</p></Link>
            {
                v.price ? (
                    <p className="price">
                        <small>￥</small>
                        { parseInt(v.price) }
                        <small>
                            { String(v.price).indexOf(".") > 0 &&
                                String(v.price).slice(String(v.price).indexOf(".")) }
                        </small>
                    </p>
                ) : v.refPrice ? <p className="price">
                    <small>￥</small>
                    { parseInt(v.refPrice) }
                    <small>
                        { String(v.refPrice).indexOf(".") > 0 &&
                            String(v.refPrice).slice(String(v.refPrice).indexOf(".")) }
                    </small>
                </p> : <p className="price"></p>
            }
            {
                (v.tradable ? (
                    v.putShelf === 1 || v.putShelf === 2 ? (
                        <div className="end-time">
                            <p>{ v.putShelf === 1 ? "换货锁定" : v.orderType === 'buying' ? '供应中' : "自售上架" }</p>
                        </div>
                    ) : (
                        <div
                            className={ [ `item-select ${ _sel && _sel[0] ? "selected" : "" }` ] }
                        >
                            <i className="iconfont icon-hook-selected"></i>
                        </div>
                    )
                ) : (
                    <div className="end-time" style={ { bottom: "8px", right: "10px" } }>
                        <img
                            className="icon-time"
                            src={ require("@/images/home/time.png") }
                            alt=""
                        />
                        <p>
                            { v._classInfo && v._classInfo.owner_descriptions
                                ? handleTime()
                                : "无法交易" }
                        </p>
                    </div>
                ))
            }
        </div>
    );
}

export default CsgoItems;
