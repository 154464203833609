import React, { $tools, antdUI } from 'react';
import { Link } from "react-router-dom";
import './dota2.scss'
import ItemDetailPopover from "@/components/itemDetailPopover";
import Rune from '../../mySale/SaleContainer/saleItemContainer/rune';

function Dota2Items(props) {
    let { style: st, index, item: v, selected: _sel, changeItem: _changeItem } = props,
        img_bs = 'https://steamcommunity-a.akamaihd.net/economy/image/';
    
    const _filterData = (type) => {
        return v.tags.filter(item => {
            let category = item.category.toLowerCase()
            return category === type
        })[0]
    }
    // let quality = _filterData('quality');
    let rarity = _filterData('rarity');
    const getPageType = (quality) => {
        if (quality === "strange" || quality === "unusual_strange") {
            return 2
        } else {
            return 1
        }
    }
    const handleTime = (time) => {
        let _y, _month, _day, _cDate;
        time.replace(/，(.*?) \(/, (_, key) => { _y = key })
        time.replace(/：(.*?)月/, (_, key) => { _month = key })
        time.replace(/月 (.*?)，/, (_, key) => { _day = key })
        time.replace(/\((.*?)\)/, (_, key) => { _cDate = key })
        let { day, hours, minutes } = $tools.countDown({
            time: new Date(`${ _y }/${ _month }/${ _day } ${ _cDate }`).getTime(),
            eight: 54000000  // 加上 15 个小时
        })
        
        return `${ day }天${ hours }时${ minutes }分`
    }
    
    const changeItem = function (e) {
        if (! _sel) return
        let targName = e.target.className;
        if (! targName || targName === 'gun' || targName === 'describe' ||
            targName === 'item price' || targName === 'buy-msg' || targName === 'checkView') return
        if (targName === 'item-name') {
        
        } else {
            if (v.tradable && v.putShelf !== 1 && v.putShelf !== 2) {
                if (_sel[0]) {
                    _changeItem(v, 'cancel')
                } else {
                    _changeItem(v, 'select')
                }
            }
        }
    }
    
    return (
        
        <div style={ st } className="dota2-items" onClick={ changeItem }>
            <antdUI.Popover
                placement="rightTop" content={ <ItemDetailPopover type="onSale" index={ index } csData={ v }/> }
                // trigger="click"
            >
                <div className="_img" onLoad={ e => e.currentTarget.style.opacity = 1 }>
                    {/*<div className="item-quality" >*/ }
                    {/*  {quality && quality.color && <p style={{ color: `#${quality.color}` }} className="item_quality">{quality.localized_tag_name}</p>}*/ }
                    {/*</div>*/ }
                    
                    <div className="item-quality">
                        { rarity && <p style={ { color: `#${ rarity.color }` } }
                                       className="item_quality">{ rarity.localized_tag_name }</p> }
                    </div>
                    <img className="item-img" style={ { height: '100%' } } src={ `${ img_bs + v.icon_url }` }
                         alt={ v.market_name }/>
                    {
                        v.descriptions && <Rune descriptions={ v.descriptions }></Rune>
                    }
                </div>
            </antdUI.Popover>
            
            
            <Link
                to={
                    {
                        pathname: '/marketPlace/accessoriesList',
                        state: {
                            curType: getPageType(v.quality),
                            asHashName: v.market_hash_name,
                            curGame: '570'
                        }
                    }
                }><p className="item-name">{ v.market_name }</p>
            </Link>
            {
                v.price ? (
                    <p className="price">
                        <small>￥</small>
                        { parseInt(v.price) }
                        <small>
                            { String(v.price).indexOf(".") > 0 &&
                                String(v.price).slice(String(v.price).indexOf(".")) }
                        </small>
                    </p>
                ) : <p className="price"></p>
            }
            {
                (v.tradable ?
                        (v.putShelf === 1 || v.putShelf === 2 ? (
                                <div className="end-time">
                                    <p>{ v.putShelf === 1 ? "换货锁定" : "自售上架" }</p>
                                </div>
                            ) : (
                                <div
                                    className={ [ `item-select ${ _sel && _sel[0] ? "selected" : "" }` ] }
                                >
                                    <i className="iconfont icon-hook-selected"></i>
                                </div>)
                        ) : (
                            <div className="end-time">
                                <img
                                    className="icon-time"
                                    src={ require("@/images/home/time.png") }
                                    alt=""
                                />
                                <p>
                                    { v.descriptions && v.descriptions.filter(_ => _.value.match('开放交易时间'))[0]
                                        ? handleTime(v.descriptions.filter(_ => _.value.match('开放交易时间'))[0].value)
                                        : "无法交易" }
                                </p>
                            </div>)
                )
            }
        </div>
    );
}

export default Dota2Items;
