import React, { useState, antdUI, useRef, useEffect, $tools, $api } from 'react';
import './index.scss';
import { CsgoFilter, Dota2Filter } from '@/components/itemFilter'
import { NavLink } from 'react-router-dom';
import Favorite from '../../accessoriesList/accessoriesLNav/favorite';
import { connect } from "react-redux";

function Favorites(props) {
    const [ currentBar, setcurrentBar ] = useState('730');
    const [ favoriteList, setFavoriteList ] = useState([]);
    const [ confirm, setConfirm ] = useState(false);
    const [ confirmId, setConfirmId ] = useState();
    const [ total, setTotal ] = useState(0);
    const [ curPage, setCurPage ] = useState(1);
    const childRef = useRef()
    const { BASE_STEAM_IMG, obj2strUrl } = $tools
    useEffect(_ => {
        localStorage.removeItem("wearList");
    }, [])
    const { userInfo } = props
    useEffect(_ => {
        if (userInfo.steam.steamid && ! userInfo.uid) {
            window.location.href = '/profile/userConfig#login'
        }
    }, [ userInfo ])
    const cancelAttention = async () => {
        await $api.user.cancelFavorite({ id: Number(confirmId) });
        setConfirm(false)
        await getData();
    }
    
    const getPageType = (quality) => {
        if (quality === "strange" || quality === "unusual_strange") {
            return 2;
        } else {
            return 1;
        }
    }
    const getFavoritesData = (data) => {
        let fArr = [];
        setFavoriteList([]);
        data?.[0] && data.forEach(item => {
            const {
                icon_url,
                market_name,
                id,
                // market_hash_name,
                quality
            } = (item?.CsgoItem || item?.Dota2Item);
            fArr.push({
                key: item.id,
                item: (
                    <div style={ favoritesItemStyle } className="favorites-item">
                        <div className={ currentBar === '730' ? 'items-image' : '' }>
                            <img style={ tabImg }
                                 src={ BASE_STEAM_IMG + icon_url }
                                 alt={ market_name }
                            />
                        </div>
                        <p style={ tabItemName } className="item-name text-ov1">{ market_name }</p>
                    </div>
                ),
                price: <div style={ tabBox }>{ item.price ?
                    <h1 style={ tabH }>{ item.price }¥</h1> : "当前暂无售卖" }</div>,
                operating: (
                    <div className='favorite-operating'>
                        
                        <NavLink
                            to={
                                {
                                    pathname: '/marketPlace/accessoriesList',
                                    search: obj2strUrl({
                                        id: item.itemid,
                                        curGame: currentBar,
                                        curType: getPageType(quality),
                                    }, '?'),
                                }
                            }
                            
                            className="tab-btn"
                            style={ tabBtn }
                        >前往购买</NavLink>
                        <div onClick={ () => {
                            setConfirm(true);
                            setConfirmId(item.id)
                        } }>
                            <Favorite curExId={ id } type="main" curGame={ currentBar } useable={ false }></Favorite>
                        </div>
                    </div>
                )
            })
        })
        setTimeout(() => {
            setFavoriteList([ ...fArr ]);
        }, 200)
    }
    
    
    const getData = async (config) => {
        const { rarity, exterior, itemType, quality, searchContent, hero, currentPage: _curPage } = config || {};
        let postData = {};
        if (rarity && rarity[0] && rarity[0] !== 'unlimited') {
            if (rarity[0] === 'Type_CustomPlayer') {
                postData.rarity = rarity[1]
            } else {
                postData.rarity = rarity[0]
            }
        }
        searchContent && (postData.searchContent = searchContent);
        exterior &&
        exterior[0] &&
        exterior[0] !== "unlimited" &&
        (postData.exterior = exterior[0]);
        quality &&
        quality[0] &&
        quality[0] !== "unlimited" &&
        (postData.quality = quality[0]);
        if (itemType?.[1]) {
            postData.type = itemType[0];
            postData.weapon = itemType[1];
        } else {
            itemType?.[0] && (postData.type = itemType[0]);
        }
        hero?.[1] && (postData.hero = hero[1]);
        
        currentBar && (postData.appid = currentBar);
        if (_curPage) {
            postData.curPage = _curPage;
            setCurPage(_curPage);
        } else {
            postData.curPage = 1;
            setCurPage(1);
        }
        let res = await $api.user.getFavorites({ count: 1, curPage: 1, pageSize: 5, ...postData });
        setTotal(res.data?.total);
        getFavoritesData(res.data?.items);
    }
    
    useEffect(() => {
        getData().then()
        // eslint-disable-next-line
    }, [ currentBar ])
    
    const paginationProps = {
        position: [ "bottomCenter" ],
        pageSize: 5,
        showSizeChanger: false,
        current: curPage,
        total: total, //数据的总的条数
        onChange: (current) => childRef.current.getData(current), //点击当前页码
    };
    
    
    return (
        <div className="favorites">
            
            <div className="title">
                <h1>关注饰品</h1>
            </div>
            
            <div className="favorites-clsfict">
                { clsfictData.map((v, i) => {
                    let icon = currentBar === v.type ? v.select_icon : v.icon,
                        _cls = currentBar === v.type ? 'clsfict-item current' : 'clsfict-item';
                    return (
                        <div key={ i } onClick={ () => {
                            setcurrentBar(v.type)
                        } } className={ _cls }>
                            <img className="clsfict-icon" src={ icon } alt=""/>
                            <p className="title">{ v.title }</p>
                        </div>
                    )
                }) }
            </div>
            
            <div className="favorites-item-head">
                {
                    currentBar === '730' ?
                        <CsgoFilter type='onSalePut' cRef={ childRef } filterChange={ getData }/> :
                        currentBar === '570' ?
                            <Dota2Filter type='onSalePut' cRef={ childRef } filterChange={ getData }/> : ''
                }
            </div>
            
            <div className="favorites-item-container">
                <antdUI.Table pagination={ paginationProps } dataSource={ favoriteList }
                              columns={ columns }></antdUI.Table>
            </div>
            
            <antdUI.Modal
                width='380px'
                title="确定界面"
                visible={ confirm }
                onOk={ cancelAttention }
                onCancel={ () => setConfirm(false) }
                okText='确定'
                cancelText='取消'
            >
                确定要取消关注吗？
            </antdUI.Modal>
        </div>
    )
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    }
)(Favorites);

let tabBtn = {
        display: 'block',
        cursor: 'pointer',
        width: '76px',
        height: '33px',
        background: '#2EB062',
        borderRadius: '4px',
        fontSize: '1rem',
        color: '#FFFFFF',
        lineHeight: '33px',
        marginRight: '15px',
        textAlign: 'center'
    },
    tabBox = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#2eb062'
    },
    tabH = {
        fontSize: '18px',
        fontWeight: 800,
        color: '#2eb062'
    },
    favoritesItemStyle = {
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center'
    },
    tabImg = {
        width: '65px'
    },
    tabItemName = {
        width: '200px',
        fontSize: '1rem',
        color: '#222222',
        margin: '0 10px'
    };


const clsfictData = [
    {
        title: 'CSGO',
        type: '730',
        icon: require('@/images/profile/csgo.png'),
        select_icon: require('@/images/profile/csgo_c.png')
    },
    // {
    //     title: 'DOTA2饰品',
    //     type: '570',
    //     icon: require('@/images/profile/dota2.png'),
    //     select_icon: require('@/images/profile/dota2_c.png')
    // }
]

let titleStyle = { textAlign: 'center', fontWeight: 900, fontSize: '14px' }

const columns = [
    {
        align: 'left',
        title: <p style={ titleStyle }>饰品</p>,
        dataIndex: 'item',
        key: 'item',
        width: 200
    },
    {
        title: <p style={ titleStyle }>当前价格(￥)</p>,
        dataIndex: 'price',
        key: 'price',
        render: text => text
    },
    {
        title: <p style={ titleStyle }>操作</p>,
        dataIndex: 'operating',
        key: 'operating',
        width: '15%',
        render: text => text
    }
];

