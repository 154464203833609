import React, { antdUI, useState, $tools, $api, useEffect } from 'react';
import { connect } from 'react-redux';
import { setUserSignIn } from '@/store/actions/userInfo'

function PCodeCheckBdSteam(props) {
    setTimeout(_ => window.localStorage.setItem('bindSteam', ''), 2000)
    let { userInfo, setUserSignIn, _locSearch } = props;
    let { steamid, steamname } = _locSearch
    const [ visible, setVisible ] = useState(props.steamid)
    const [ title, setTitle ] = useState(props.title)
    const [ phoneCode, setPhoneCode ] = useState('')
    const [ btnText, setBtnText ] = useState('发送验证码')
    const [ codeLoa, setCodeLoa ] = useState(false)
    const [ onOkDel, setOnOkDel ] = useState(true)
    const [ btnLoading, setLoading ] = useState(false)
    const sendCode = async () => {
        if (btnText !== '发送验证码') return
        let countdown = 60,
            timer = null;
        setCodeLoa(true)
        let result = await $api.user.sendPhoneCode({ type: 'checkBindSteam' });
        setCodeLoa(false)
        if (! $tools.checkResult(result)) return
        setBtnText(`重新发送(${ countdown }s)`);
        timer = setInterval(() => {
            countdown--
            if (countdown === 0) {
                clearInterval(timer)
                setBtnText('发送验证码');
            } else {
                setBtnText(`重新发送(${ countdown }s)`);
            }
        }, 1000)
    }
    useEffect(() => {
        if (_locSearch.steamid) setVisible(true)
    }, [ _locSearch ])
    const onOk = async () => {
        if (onOkDel) {
            setOnOkDel(false)
            setTimeout(_ => setOnOkDel(true), 2000)
            if (title === '绑定确认') {
                setTitle('输入验证码');
                await sendCode();
            }
            if (title === '输入验证码') {
                setLoading(true)
                const result = await $api.user.bindSteamLogin({ code: phoneCode })
                setLoading(false)
                if ($tools.checkResult(result)) {
                    setUserSignIn();
                    antdUI.message.success('绑定成功！')
                    setTimeout(_ => { setVisible(false); }, 200)
                }
            }
        } else {
            antdUI.message.warning('请勿频繁操作！')
        }
    }
    
    return (
        <div className="P-code-check-BdSteam" style={ { position: 'absolute' } }>
            <antdUI.Modal
                title={ title } visible={ visible }
                footer={ [
                    <antdUI.Button key="1" onClick={ () => {
                        setVisible(false)
                    } }>
                        取消
                    </antdUI.Button>,
                    <antdUI.Button key="2" type="primary" loading={ btnLoading }
                                   onClick={ $tools.debounce(onOk, 2000) }>
                        确定
                    </antdUI.Button>
                ] }
                onCancel={ () => { setVisible(false) } }
                width="393px" maskClosable={ false } destroyOnClose={ true } centered={ true }>
                { title === '绑定确认' && <div style={ { fontSize: '15px' } }>
                    <p style={ { marginBottom: '5px' } }>确认绑定到这个steam账号吗？</p>
                    <p style={ { marginBottom: '5px' } }>Steam昵称：{ steamname }</p>
                    <p>Steam ID：{ steamid }</p>
                </div> }
                { title === '输入验证码' && <div>
                    <p style={ { marginBottom: '10px', fontSize: '16px' } }>
                        请进行安全确认，输入{ userInfo.phone }手机收到的验证码</p>
                    <antdUI.Input.Search
                        placeholder="请输入验证码"
                        value={ phoneCode } type="number"
                        onChange={ (e) => setPhoneCode(e.target.value) }
                        enterButton={ <antdUI.Button style={ { backgroundColor: "#2DAF61", color: "#fff" } }
                                                     loading={ codeLoa }>{ btnText }</antdUI.Button> } size="large"
                        onSearch={ $tools.debounce(sendCode, 2000, true) }
                    />
                </div> }
            </antdUI.Modal>
        </div>
    )
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    },
    function mapDispatchToProps(disatch) {
        return {
            setUserSignIn: () => disatch(setUserSignIn()),
        }
    }
)(PCodeCheckBdSteam);
