import React, { $tools, antdUI, useState, $login, $toBindSteam, $bindSteamLink } from 'react';
import "./index.scss";
import Favorite from './favorite';
import { NavLink } from 'react-router-dom';
import SummaryGraph from './summaryGraph';
import { csgo } from '@/data/classification.js';
import BuyingDialog from '../accessoriesContainer/typePage/buying/buyingDialog';
import { connect } from "react-redux";

const csgoType = csgo.filter(_ => _.value === 'itemType')[0].children;
const otherType = csgoType.filter(_ => _.value === 'other')[0].children;

function getCsType(type) {
    if (otherType.map(_ => _.value).indexOf(type) !== -1) {
        return otherType.filter(item => item.value === type)?.[0]?.label || '';
    } else {
        if (type === 'CSGO_Type_SniperRifle') type = 'CSGO_Type_Rifle'
        return csgoType.filter(item => item.value === type)?.[0]?.label || '';
    }
}

function AccessoriesNav(props) {
    const { navData, curGame, curExId, statisticalData, setPastDays, pastDays, userInfo } = props;
    const [ releaseDialog, setReleaseDialog ] = useState(false);
    
    const examineSteam = () => {
        window.open(`https://steamcommunity.com/market/listings/${ curGame }/${ navData.market_hash_name }`)
    }
    
    return (
        <div className="accessories-nav">
            <div className="accessories-name">
                <div className='accessories-head'>
                    <p className="name-title">
                        <NavLink to="/marketPlace">自售交易市场</NavLink>
                        { `->` } { navData.market_name }</p>
                    <Favorite useable={ true } curExId={ curExId } curGame={ curGame }/>
                </div>
                <div className="name-detail">
                    <div className={ curGame === '730' ? 'detail-img-csgo' : 'detail-img-dota2' }>
                        <img
                            className="item-img"
                            src={ $tools.BASE_STEAM_IMG + navData.icon_url }
                            alt={ navData.market_name }
                        />
                    </div>
                    <div className="detail-msg">
                        <h2>{ navData.market_name }</h2>
                        {
                            Number(curGame) === 730
                                ? <div className="detail-msg-feature-p">
                                    <p>品质：{ rarityData[navData.rarity] || "隐秘" }</p>
                                    <p>类型：{ getCsType(navData.type) }</p>
                                    <p>类别：{ qualityData[navData.quality] || "暂无" }</p>
                                </div>
                                : <div className="detail-msg-feature-p">
                                    <p>稀有度：{ rarityData2[navData.rarity] || "隐秘" }</p>
                                    <p>品质：{ qualityData2[navData.quality] || "暂无" }</p>
                                </div>
                        }
                        <div
                            style={ { display: "flex", alignItems: "center" } }
                            className="detail-msg-feature"
                        >
                            <p>
                                参考价：
                                <span className="feature-price">
                  <span className="feature-price-icon">￥</span>
                                    { navData.price || '暂无' }
                </span>
                            </p>
                            <p>
                                7天均价：
                                <span onClick={ examineSteam } className="feature-price-average">查看steam</span>
                            </p>
                        </div>
                        <div className="detail-msg-operate">
                            {
                                Number(curGame) === 730 && <antdUI.Button onClick={ () => {
                                    if (! userInfo?.steam?.steamid && ! userInfo.uid) return $login() // 没登录
                                    if (userInfo?.steam?.steamid && ! userInfo.uid) return window.location.href = '/profile/userConfig#login'
                                    if (! userInfo?.steam?.steamid && userInfo.uid) return $toBindSteam()
                                    // if (userInfo.real_name_status !== 1) return $toReal()
                                    if (! userInfo?.steam?.transactionLink) return $bindSteamLink()
                                    setReleaseDialog(true)
                                }
                                } className="operate-btn">我要求购</antdUI.Button>
                            }
                            <antdUI.Button onClick={ () => window.location.href = '/inventory' }
                                           className="operate-btn">我要出售
                            </antdUI.Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="accessories-split"></div>
            <div className="accessories-summaryGraph">
                {/*<img*/ }
                {/*  style={{ width: "95%", marginTop: "20px" }}*/ }
                {/*  src={require("@/images/items/graph.png")}*/ }
                {/*  alt=""*/ }
                {/*/>*/ }
                <SummaryGraph pastDays={ pastDays } setPastDays={ setPastDays } statisticalData={ statisticalData }/>
            </div>
            
            {
                releaseDialog &&
                <BuyingDialog csData={ navData } releaseDialog={ releaseDialog } setReleaseDialog={ setReleaseDialog }/>
            }
        </div>
    );
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    },
)(AccessoriesNav);

let qualityData = {
    normal: "普通",
    strange: "StatTrak™",
    tournament: "纪念品",
    unlimited: "不限",
    unusual: "★",
    unusual_strange: "★ StatTrak™",
};

let qualityData2 = {
    base: '基础',
    unique: '标准',
    strange: '铭刻',
    genuine: '纯正',
    exalted: '尊享',
    tournament: '英雄传世',
    infused: '融合',
    lucky: '吉祥',
    corrupted: '冥灵',
    autographed: '亲笔签名',
    unusual: '独特',
    haunted: '凶煞',
    vintage: '上古',
    frozen: '冻人'
}

let rarityData = {
    Rarity_Ancient: "非凡",
    Rarity_Ancient_Weapon: "隐秘",
    Rarity_Common: "普通级",
    Rarity_Common_Weapon: "消费级",
    Rarity_Contraband: "违禁",
    Rarity_Legendary: "奇异",
    Rarity_Legendary_Weapon: "保密",
    Rarity_Mythical: "卓越",
    Rarity_Mythical_Weapon: "受限",
    Rarity_Rare: "高级",
    Rarity_Rare_Weapon: "军规级",
    Rarity_Uncommon_Weapon: "工业级",
    unlimited: "不限",
};

let rarityData2 = {
    Rarity_Common: "普通级",
    Rarity_Rare: "稀有",
    Rarity_Immortal: "不朽",
    Rarity_Legendary: "传说",
    Rarity_Arcana: "至宝",
    Rarity_Uncommon: "罕见",
    Rarity_Mythical: "神话"
}
