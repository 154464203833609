import handleGradientColor from './handleGradientColor'
import md5 from 'md5'

// 函数防抖（debounce）
// 函数防抖，就是指触发事件后在 n 秒内函数只能执行一次，如果在 n 秒内又触发了事件，则会重新计算函数执行时间。
// 简单的说，当一个动作连续触发，则只执行最后一次。
// 打个比方，坐公交，司机需要等最后一个人进入才能关门。每次进入一个人，司机就会多等待几秒再关门。

// 函数节流（throttle）
// 限制一个函数在一定时间内只能执行一次。
// 举个例子，乘坐地铁，过闸机时，每个人进入后3秒后门关闭，等待下一个人进入。
// 节流
function throttle(fn, delay) {
    let t = null,
        begin = new Date().getTime();
    
    return function () {
        let self = this,
            args = arguments,
            cur = new Date().getTime();
        
        clearTimeout(t)
        // console.log(args, begin, cur)
        // console.log(cur - begin >= delay)
        if (cur - begin >= delay) {
            fn.apply(self, args);
            begin = cur;
        } else {
            t = setTimeout(function () {
                fn.apply(self, args);
            }, delay)
        }
    }
}


function md5Encryption(text) {
    return md5(text)
}

// 防抖
function debounce(fn, delay, triggerNow = false) {
    let t = null;
    return function () {
        let self = this,
            args = arguments;
        if (t) clearTimeout(t);
        if (triggerNow) {
            let exec = ! t;
            
            t = setTimeout(function () {
                t = false;
            }, delay)
            
            if (exec) {
                fn.apply(self, args)
            }
        } else {
            t = setTimeout(fn.apply(self, args), delay)
        }
    }
}

// 输入框的验证
function inputValueLimit(value, length = 40) {
    let val = value.replace(/\s+/g, '');
    val.length >= length && (val = val.slice(0, length))
    return val
}

// 取手续费比率
function getHandlingFee(number, reputationPoints, fixed) {
    if (fixed) {
        if (reputationPoints < 70) return 0.02
        let proportion;
        number = Number(number);
        let handlingFee = [
            { proportion: 0.0075, num: 10 },
            { proportion: 0.01, num: 50 },
            { proportion: 0.0125, num: 150 },
            { proportion: 0.015, num: 2000 },
            { proportion: 0.0175, num: 5000 },
            { proportion: 0.02, num: Infinity }
        ]
        handlingFee.forEach((item, index, arr) => {
            if (index === 0 && item.num >= number) {
                proportion = item.proportion;
            } else {
                if (item.num >= number && arr[index - 1].num < number) {
                    proportion = item.proportion
                }
            }
        })
        if (reputationPoints > 100 && proportion > 0.015) {
            return 0.015
        } else {
            return proportion
        }
    } else {
        return 0.0075;
    }
}

// 检查result返回值
function checkResult(target) {
    if (Object.prototype.toString.call(target) === '[object Object]') {
        return target.status === 200 && target.message.toLowerCase() === 'success'
    } else {
        return false
    }
}

// 处理计算代币
function tokensTotal({ target, type }) {
    switch (type) {
        case 'main':
            if (target && target.promoted_tokens) {
                return ((Number(target.promoted_tokens) * 100) +
                    (Number(target.subscribe_tokens) * 100) +
                    (Number(target.trading_tokens) * 100)) / 100;
            } else {
                return 0
            }
        
        case 'all':
            if (target && target[0]) {
                return (
                    (Number(target[0].remaining) * 100)
                    + (Number(target[1].remaining) * 100)
                    // + (Number(target[2].remaining) * 100)
                ) / 100;
            } else {
                return 0
            }
        default:
            break;
    }
}

// 计算倒计时时间
function countDown({ time, eight = 0, type }) {
    if (time === " ") return ""
    let tTime = new Date().getTime(),
        val = (time + eight - tTime) / 1000;
    // console.log(time, eight, tTime)
    if (val < 0) {
        switch (type) {
            case 'order':
                return `结算中...`
            default:
                return { day: 0, hours: 0, minutes: 0, seconds: 0 }
        }
    }
    let day = Math.floor(val / 60 / 60 / 24),
        hours = Math.floor(val / 60 / 60 % 24),
        minutes = Math.floor(val / 60 % 60),
        seconds = Math.floor(val % 60);
    hours < 10 && (hours = '0' + hours)
    minutes < 10 && (minutes = '0' + minutes)
    seconds < 10 && (seconds = '0' + seconds)
    switch (type) {
        case 'order':
            return `${ Number(hours) === 0 ? '' : `${ hours }小时` }${ minutes }分${ seconds }秒`
        default:
            return { day, hours, minutes, seconds }
    }
}

// 计算 目标时间(倒计时)
function calculationTargetTime(op) {
    const { orderItemsStatus, duetime, offSendTime, deliveryMethod, orderType, suspendedTime } = op;
    let sixTime = deliveryMethod === 'seller' ? 12 * 60 : 5;
    const buyTargTime = new Date(duetime?.replace(/-/g, '/')).getTime();
    const sendOfferTime = new Date(offSendTime?.replace(/-/g, '/')).getTime();
    if (orderType === 'buying') sixTime = 30
    switch (Number(orderItemsStatus)) {
        case 1:
            return " "
        case 2:
            return " "
        case 3:
            return " "
        case 4:
            return buyTargTime + 60 * (orderType === 'buying' ? 30 : 60 * 12) * 1000;
        case 6:
            return buyTargTime + 60 * sixTime * 1000
        case 7:
            return sendOfferTime + 60 * 60 * 12 * 1000;
        case 8:
            return sendOfferTime + 60 * 60 * 24 * 1000;
        case 9:
            return " "
        case 10:
            return sendOfferTime + 60 * 60 * 12 * 1000;
        case 11:
            return new Date(suspendedTime?.replace(/-/g, '/')).getTime()
        default:
            break;
    }
}

function cancelOrderTime(op) {
    const {
        orderItemsStatus, duetime, deliveryMethod,
        // offSendTime
    } = op;
    // if (orderItemsStatus === 4 || orderItemsStatus === 6 || orderItemsStatus === 7) console.log(op)
    const buyTargTime = new Date(duetime).getTime()
    // const sendOfferTime = new Date(offSendTime).getTime()
    switch (orderItemsStatus) {
        // case 4: return buyTargTime + 60 * 1 * 1000;
        case 6:
            if (deliveryMethod === 'seller') return buyTargTime + 60 * 30 * 1000;
            break;
        // case 7: return sendOfferTime + 60 * 15 * 1000;
        // case 10: return sendOfferTime + 60 * 15 * 1000;
        default:
            break;
    }
}


function checkInputValNumber(target) {
    return target.replace(/[^0-9]/g, '')
}

function checkInputValNumberAndPoint(target) {
    return target.replace(/[^0-9.]/g, '')
}

function Sum(a, b) {
    if (isNaN(a)) {
        a = 0
    } else {
        a = a || 0
    }
    if (isNaN(b)) {
        b = 0
    } else {
        b = b || 0
    }
    return (Math.round((Number(a) * 100) + Math.round(Number(b) * 100)) / 100).toFixed(2)
}

function Less(a, b) {
    a = a || 0;
    b = b || 0;
    return (Math.round(Number(a) * 100) - Math.round(Number(b) * 100)) / 100
}

function checkUserBindInfo(userInfo) {
    let {
        steam, steamid,
        phone,
        // real_name_status
    } = userInfo
    return phone
        && steamid && steam.steamid
        && steam.apiKey
        && steam.transactionLink
    // && real_name_status
}

function tplReplace({ target, options }) {
    let rep = /{(.*?)}/g
    return target.replace(rep, (node, key) => {
        return options[key]
    })
    
}

function orderStatusMessage({ type, status, deliveryMethod, orderMsg, main, cancelStatus }) {
    let textStyle = "width: 100%;text-align:center;";
    let intTpl = '<p style="color:{color}; font-weight: 700;">{msg}</p>'
    let saleTpl = `<p style="color:{color};${ textStyle }font-weight: 700;
  display:flex;justify-content:center;align-items:center;">{msg}</p>
  <p style="${ textStyle }">{deliveryMethod}</p>
  {sixErr}`
    
    let msg = ''
    switch (type) {
        case 'int':
            switch (String(status)) {
                case '-1':
                    msg = intTpl.replace(/{(.*?)}/g, (_, k) => ({ color: '#FD6161', msg: '已失败' }[k]));
                    
                    break;
                case '0':
                    msg = intTpl.replace(/{(.*?)}/g, (_, k) => ({ color: '#FD6161', msg: '已取消' }[k]));
                    
                    break;
                case '1':
                    msg = intTpl.replace(/{(.*?)}/g, (_, k) => ({ color: '#222', msg: '待接单' }[k]));
                    
                    break;
                case '2':
                    msg = intTpl.replace(/{(.*?)}/g, (_, k) => ({ color: '#222', msg: '已接单 待买家付款' }[k]));
                    
                    break;
                case '3':
                    msg = intTpl.replace(/{(.*?)}/g, (_, k) => ({ color: '#222', msg: '已付款 待卖家发货' }[k]));
                    
                    break;
                case '4':
                    msg = intTpl.replace(/{(.*?)}/g, (_, k) => ({ color: '#222', msg: '已发货 待收货' }[k]));
                    
                    break;
                case '5':
                    msg = intTpl.replace(/{(.*?)}/g, (_, k) => ({ color: '#222', msg: '已收货 待打款' }[k]));
                    
                    break;
                case '6':
                    msg = intTpl.replace(/{(.*?)}/g, (_, k) => ({ color: '#2DAF61', msg: '订单已完成' }[k]));
                    
                    break;
                default:
                    break;
            }
            
            break;
        case 'sale':
            switch (String(status)) {
                case '-1':
                    msg = saleTpl.replace(/{(.*?)}/g, (_, k) => ({
                        sixErr: '',
                        color: '#FD6161', msg: '已失败', deliveryMethod: ''
                    }[k]));
                    
                    break;
                case '0':
                    msg = tplReplace({
                        target: saleTpl, options: {
                            sixErr: '',
                            color: '#222', msg: '已下架', deliveryMethod: ''
                        }
                    });
                    break;
                case '1':
                    msg = tplReplace({
                        target: saleTpl, options: {
                            sixErr: '',
                            color: 'red', msg: `<i class="iconfont icon-cha" style="margin-right: 5px;font-size: 14px">
            </i>${ main === 'buyer' ? '购买' : '出售' }失败`, deliveryMethod: orderMsg
                        }
                    });
                    break;
                case '2':
                    msg = tplReplace({
                        target: saleTpl, options: {
                            sixErr: '',
                            color: 'orange', msg: '报价发送中', deliveryMethod: ''
                        }
                    });
                    break;
                case '3':
                    msg = tplReplace({
                        target: saleTpl, options: {
                            sixErr: '',
                            color: 'orange', msg: '报价发送中', deliveryMethod: ''
                        }
                    });
                    break;
                case '4':
                    msg = cancelStatus === 2 ?
                        tplReplace({
                            target: saleTpl, options: {
                                sixErr: '',
                                color: '#222', msg: '校验中...',
                                deliveryMethod: ''
                            }
                        })
                        : tplReplace({
                            target: saleTpl, options: {
                                sixErr: '',
                                color: '#222', msg: '等待卖家steam令牌确认',
                                deliveryMethod: ''
                            }
                        });
                    break;
                case '5':
                    msg = tplReplace({
                        target: saleTpl, options: {
                            sixErr: '',
                            color: '#222', msg: '上架中', deliveryMethod: ''
                        }
                    });
                    break;
                case '6':
                    msg = (cancelStatus === 1 && main === 'buyer') ? saleTpl.replace(/{(.*?)}/g, (_, k) => (
                        {
                            color: 'red', msg: '取消中...',
                            deliveryMethod: '',
                            sixErr: `<div style="${ textStyle }color:red;"
            >${ orderMsg ? orderMsg : '' }</div>`
                        }[k])
                    ) : saleTpl.replace(/{(.*?)}/g, (_, k) => (
                        {
                            color: '#222', msg: '已付款 待发货',
                            deliveryMethod: deliveryMethod === 'buyer' ? `等待买家发起报价` :
                                deliveryMethod === 'seller' ? `等待卖家发起报价` : '',
                            sixErr: `<div style="${ textStyle }color:red;"
            >${ orderMsg ? orderMsg : '' }</div>`
                        }[k])
                    );
                    break;
                case '7':
                    msg = saleTpl.replace(/{(.*?)}/g, (_, k) => ({
                        sixErr: '',
                        color: '#222', msg: '等待卖家回应报价', deliveryMethod: ''
                    }[k]));
                    
                    break;
                case '8':
                    msg = saleTpl.replace(/{(.*?)}/g, (_, k) => ({
                        sixErr: '',
                        color: '#222', msg: '等待买家接受报价', deliveryMethod: ''
                    }[k]));
                    
                    break;
                case '9':
                    msg = saleTpl.replace(/{(.*?)}/g, (_, k) => ({
                        sixErr: '',
                        color: '#508F50', msg: `<i class="iconfont icon-gou" style="margin-right: 5px;font-size: 14px">
          </i>${ main === 'buyer' ? '购买' : '出售' }成功`, deliveryMethod: ''
                    }[k]));
                    break;
                case '10':
                    msg = tplReplace({
                        target: saleTpl, options: {
                            sixErr: '',
                            color: '#222', msg: '卖家已接受报价',
                            deliveryMethod: '等待卖家steam令牌确认'
                        }
                    });
                    break;
                case '11':
                    msg = saleTpl.replace(/{(.*?)}/g, (_, k) => ({
                        sixErr: '',
                        color: '#222', msg: '该报价已暂挂', deliveryMethod: ''
                    }[k]));
                    break;
                default:
                    break;
            }
            
            break;
        default:
            break;
    }
    return msg
}

function levelText(level = 0) {
    let targetText = [ "白银用户", "黄金用户", "铂金用户", "钻石用户" ]
    const getLevelType = (level) => {
        let arr = [], list = [], i;
        for (let idx = 0; idx < 201; idx++) {
            list.push(idx);
            if ((idx + 1) % 50 === 0) {
                if ((idx + 1) === 200) list.push(200)
                arr.push(list);
                list = []
            }
            
        }
        arr.forEach((item, idx) => { if (item.indexOf(level) !== -1) i = idx })
        return i
    }
    return targetText[getLevelType(level)] || (level >= 200 && "钻石用户") || ''
}

function levelIcon(level = 0) {
    let targetText = [ require("@/images/profile/silver.png"),
        require("@/images/profile/gold.png"),
        require("@/images/profile/platinum.png"),
        require("@/images/profile/diamond.png") ]
    const getLevelType = (level) => {
        let arr = [], list = [], i;
        for (let idx = 0; idx < 201; idx++) {
            list.push(idx);
            if ((idx + 1) % 50 === 0) {
                if ((idx + 1) === 200) list.push(200)
                arr.push(list);
                list = []
            }
            
        }
        arr.forEach((item, idx) => { if (item.indexOf(level) !== -1) i = idx })
        return i
    }
    return targetText[getLevelType(level)] || (level >= 200 && require("@/images/profile/diamond.png")) || ''
}

function getCredibilityIcon(reputationPoints) {
    const credibility_icon = {
        top: require('@/images/profile/jihao.png'),
        excellent: require('@/images/profile/youliang@2x.png'),
        medium: require('@/images/profile/zhong@2x.png'),
        poor: require('@/images/profile/cha.png')
    }
    if (reputationPoints < 70) {
        return credibility_icon.poor
    } else if (reputationPoints > 100) {
        return credibility_icon.top
    } else {
        let i,
            tar = [
                [ 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85 ],
                [ 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100 ]
            ];
        tar.forEach((item, idx) => { if (item.indexOf(reputationPoints) !== -1) i = idx })
        if (i === 0) {
            return credibility_icon.medium
        } else if (i === 1) {
            return credibility_icon.excellent
        }
    }
}

function getCredibilityInfoData(reputationPoints) {
    const credibility_icon = {
        top: { text: [ "极好", "" ], quality: 20, all: 100, level: 0 },
        excellent: { text: [ "优良", "极好" ], quality: 15, all: 85, level: 1 },
        medium: { text: [ "中", "优良" ], quality: 16, all: 69, level: 2 },
        poor: { text: [ "差", "中" ], quality: 69, all: 0, level: 3 }
    }
    if (reputationPoints < 70) {
        return credibility_icon.poor
    } else if (reputationPoints > 100) {
        return credibility_icon.top
    } else {
        let i, tar = [
            [ 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85 ],
            [ 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100 ]
        ];
        tar.forEach((item, idx) => { if (item.indexOf(reputationPoints) !== -1) i = idx })
        if (i === 0) {
            return credibility_icon.medium
        } else if (i === 1) {
            return credibility_icon.excellent
        }
    }
}

function obj2strUrl(obj, match = '?') {
    try {
        let str = "";
        for (let key in obj) {
            if (! obj[key]) continue;         //如果属性为空，则不添加到地址栏
            str = `${ str }${ key }=${ obj[key] }&`;
        }
        str = str.substring(0, str.length - 1);
        return match + str;
    } catch (err) {
        window.location.href = '/marketPlace'
    }
    
}

function strUrl2obj(url, match = '?') {
    try {
        if (! url) return {};
        let obj = {}
        let resArr = []
        let urlArr = url.split(match)
        let newUrl = urlArr[1]
        let arr = newUrl.split('&')
        for (let i = 0; i < arr.length; i++) {
            let res = arr[i].split('=')
            resArr.push(res)
        }
        for (let i = 0; i < resArr.length; i++) {
            obj[resArr[i][0]] = resArr[i][1]
            obj[resArr[i][0]] = obj[resArr[i][0]].replace(/%20/g, ' ')    //将参数里的%20转化为空格
        }
        return obj;
    } catch (err) {
        window.location.href = '/marketPlace'
    }
    
}

function getUrlQueryValue(key, str) {
    //以空开头或者&开头加上key属性名接上=判断非空非&出现多次 然后以&或者空结尾 i全局匹配
    const reg = new RegExp('(^|&)' + key + '=([^&]*)(&|$)', 'i'),
        // window.location.search里的是从?开始的页面地址 然后 substr(1)去掉第一位问号 然后进行match匹配正则 返回匹配的字符串
        res = str ? str.substr(1).match(reg) : window.location.search.substr(1).match(reg);
    //match匹配正则返回的是一个数组 数组的第三项值是匹配结果
    //decodeURIComponent解码
    return res != null ? decodeURIComponent(res[2]) : null;
}

function averageWear(wearList, max, min) {
    const result = Math.fround(
        wearList.reduce((prev, curr) => {
            return Math.fround(Math.fround(prev) + Math.fround(curr))
        }) / Math.fround(10.0)
    )
    
    const min32 = Math.fround(min)
    const avg = Math.fround(max) - min32
    
    return Math.fround(Math.fround(result * avg) + min32)
}

function getStyle(elem, prop) {
    if (window.getComputedStyle) {
        if (prop) {
            return window.getComputedStyle(elem, null)[prop];
        } else {
            return window.getComputedStyle(elem, null);
        }
    } else {
        if (prop) {
            return elem.currentStyle[prop];
        } else {
            return elem.currentStyle;
        }
    }
}

function SHA256(s) {
    const chrsz = 8
    const hexcase = 0
    
    function safe_add(x, y) {
        const lsw = (x & 0xFFFF) + (y & 0xFFFF)
        const msw = (x >> 16) + (y >> 16) + (lsw >> 16)
        return (msw << 16) | (lsw & 0xFFFF)
    }
    
    function S(X, n) {
        return (X >>> n) | (X << (32 - n))
    }
    
    function R(X, n) {
        return (X >>> n)
    }
    
    function Ch(x, y, z) {
        return ((x & y) ^ ((~x) & z))
    }
    
    function Maj(x, y, z) {
        return ((x & y) ^ (x & z) ^ (y & z))
    }
    
    function Sigma0256(x) {
        return (S(x, 2) ^ S(x, 13) ^ S(x, 22))
    }
    
    function Sigma1256(x) {
        return (S(x, 6) ^ S(x, 11) ^ S(x, 25))
    }
    
    function Gamma0256(x) {
        return (S(x, 7) ^ S(x, 18) ^ R(x, 3))
    }
    
    function Gamma1256(x) {
        return (S(x, 17) ^ S(x, 19) ^ R(x, 10))
    }
    
    function core_sha256(m, l) {
        const K = [ 0x428A2F98, 0x71374491, 0xB5C0FBCF, 0xE9B5DBA5, 0x3956C25B, 0x59F111F1, 0x923F82A4, 0xAB1C5ED5, 0xD807AA98, 0x12835B01, 0x243185BE, 0x550C7DC3, 0x72BE5D74, 0x80DEB1FE, 0x9BDC06A7, 0xC19BF174, 0xE49B69C1, 0xEFBE4786, 0xFC19DC6, 0x240CA1CC, 0x2DE92C6F, 0x4A7484AA, 0x5CB0A9DC, 0x76F988DA, 0x983E5152, 0xA831C66D, 0xB00327C8, 0xBF597FC7, 0xC6E00BF3, 0xD5A79147, 0x6CA6351, 0x14292967, 0x27B70A85, 0x2E1B2138, 0x4D2C6DFC, 0x53380D13, 0x650A7354, 0x766A0ABB, 0x81C2C92E, 0x92722C85, 0xA2BFE8A1, 0xA81A664B, 0xC24B8B70, 0xC76C51A3, 0xD192E819, 0xD6990624, 0xF40E3585, 0x106AA070, 0x19A4C116, 0x1E376C08, 0x2748774C, 0x34B0BCB5, 0x391C0CB3, 0x4ED8AA4A, 0x5B9CCA4F, 0x682E6FF3, 0x748F82EE, 0x78A5636F, 0x84C87814, 0x8CC70208, 0x90BEFFFA, 0xA4506CEB, 0xBEF9A3F7, 0xC67178F2 ]
        const HASH = [ 0x6A09E667, 0xBB67AE85, 0x3C6EF372, 0xA54FF53A, 0x510E527F, 0x9B05688C, 0x1F83D9AB, 0x5BE0CD19 ]
        const W = new Array(64)
        let a, b, c, d, e, f, g, h, i, j
        let T1, T2
        m[l >> 5] |= 0x80 << (24 - l % 32)
        m[((l + 64 >> 9) << 4) + 15] = l
        for (i = 0; i < m.length; i += 16) {
            a = HASH[0]
            b = HASH[1]
            c = HASH[2]
            d = HASH[3]
            e = HASH[4]
            f = HASH[5]
            g = HASH[6]
            h = HASH[7]
            for (j = 0; j < 64; j++) {
                if (j < 16) {
                    W[j] = m[j + i]
                } else {
                    W[j] = safe_add(safe_add(safe_add(Gamma1256(W[j - 2]), W[j - 7]), Gamma0256(W[j - 15])), W[j - 16])
                }
                T1 = safe_add(safe_add(safe_add(safe_add(h, Sigma1256(e)), Ch(e, f, g)), K[j]), W[j])
                T2 = safe_add(Sigma0256(a), Maj(a, b, c))
                h = g
                g = f
                f = e
                e = safe_add(d, T1)
                d = c
                c = b
                b = a
                a = safe_add(T1, T2)
            }
            HASH[0] = safe_add(a, HASH[0])
            HASH[1] = safe_add(b, HASH[1])
            HASH[2] = safe_add(c, HASH[2])
            HASH[3] = safe_add(d, HASH[3])
            HASH[4] = safe_add(e, HASH[4])
            HASH[5] = safe_add(f, HASH[5])
            HASH[6] = safe_add(g, HASH[6])
            HASH[7] = safe_add(h, HASH[7])
        }
        return HASH
    }
    
    function str2binb(str) {
        const bin = []
        const mask = (1 << chrsz) - 1
        for (let i = 0; i < str.length * chrsz; i += chrsz) {
            bin[i >> 5] |= (str.charCodeAt(i / chrsz) & mask) << (24 - i % 32)
        }
        return bin
    }
    
    function Utf8Encode(string) {
        string = string.replace(/\r\n/g, '\n')
        let utfText = ''
        for (let n = 0; n < string.length; n++) {
            const c = string.charCodeAt(n)
            if (c < 128) {
                utfText += String.fromCharCode(c)
            } else if ((c > 127) && (c < 2048)) {
                utfText += String.fromCharCode((c >> 6) | 192)
                utfText += String.fromCharCode((c & 63) | 128)
            } else {
                utfText += String.fromCharCode((c >> 12) | 224)
                utfText += String.fromCharCode(((c >> 6) & 63) | 128)
                utfText += String.fromCharCode((c & 63) | 128)
            }
        }
        return utfText
    }
    
    function binb2hex(binarray) {
        const hex_tab = hexcase ? '0123456789ABCDEF' : '0123456789abcdef'
        let str = ''
        for (let i = 0; i < binarray.length * 4; i++) {
            str += hex_tab.charAt((binarray[i >> 2] >> ((3 - i % 4) * 8 + 4)) & 0xF) +
                hex_tab.charAt((binarray[i >> 2] >> ((3 - i % 4) * 8)) & 0xF)
        }
        return str
    }
    
    s = Utf8Encode(s)
    return binb2hex(core_sha256(str2binb(s), s.length * chrsz))
}

const BASE_STEAM_IMG = 'https://steamcommunity-a.akamaihd.net/economy/image/'
const BASE_CSGO_STICKER = 'https://www.csgola.com/Public/img/csgo_stickers/'


export default {
    ...handleGradientColor, orderStatusMessage, calculationTargetTime, cancelOrderTime, md5Encryption,
    throttle, debounce, inputValueLimit, checkResult, tokensTotal, Less, checkInputValNumber,
    countDown, BASE_STEAM_IMG, Sum, getHandlingFee, checkUserBindInfo, BASE_CSGO_STICKER, SHA256,
    levelText, levelIcon, getCredibilityIcon, obj2strUrl, strUrl2obj, getUrlQueryValue, getStyle, averageWear,
    getCredibilityInfoData, checkInputValNumberAndPoint
}
