import React, {antdUI} from 'react';
import './sort.scss'

function Sort(props){
  const {setSort, curGame, sort} =props;
  const {Select} = antdUI;
  const {Option} =Select;

  const selectOptions=[
    {value:1,label:<span>时间<i className="iconfont icon-cs-jt-xs des"></i></span>,require:true},
    {value:2,label:<span>时间<i className="iconfont icon-cs-jt-xs asc"></i></span>,require:true},
    {value:3,label:<span>价格<i className="iconfont icon-cs-jt-xs des"></i></span>,require:true},
    {value:4,label:<span>价格<i className="iconfont icon-cs-jt-xs asc"></i></span>,require:true},
    {value:5,label:<span>磨损<i className="iconfont icon-cs-jt-xs des"></i></span>,require:false},
    {value:6,label:<span>磨损<i className="iconfont icon-cs-jt-xs asc"></i></span>,require:false}
  ]

  const onChange=(value)=>{
    setSort(value);
  }

  return(
    <div className='sort-tab-sort'>
      <Select size={'small'} defaultValue={sort} style={{ width: 105 }} onChange={onChange}>
        {Number(curGame) === 730?
          selectOptions.map((item,index)=>{
            return <Option key={index} value={item.value}>{item.label}</Option>
          })
          :selectOptions.map((item,index)=>{
            if(item.require)
              return <Option key={index} value={item.value}>{item.label}</Option>
            else
              return ''
          })
        }
      </Select>
    </div>
  )
}

export default Sort
