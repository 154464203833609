import React, { useState } from 'react';
import { Flowrecord, Mobi, Promote, Recharge, Withdraw } from './purseContent'
import { connect } from "react-redux";
import { checkUserSignIn, setUserSignIn, upDateUserAssets, } from "@/store/actions/userInfo";

const navData = [
    // { title: '充值', type: 'recharge' },
    { title: '提现', type: 'withdraw' },
    // { title: '推广', type: 'promote' },
    // { title: 'MOE管理', type: 'mobi' },
    { title: '流水记录', type: 'flowrecord' }
]

function PurseNav(props) {
    const { checkUserSignIn, upDateUserAssets } = props;
    const curNav = localStorage.getItem('PurseNavType');
    const [ currentNav, setNav ] = useState(curNav || 'withdraw');
    const navChange = (type) => {
        localStorage.setItem('PurseNavType', type)
        setNav(type);
        checkUserSignIn();
        upDateUserAssets()
    }
    const renderContent = () => {
        switch (currentNav) {
            case 'recharge':
                return <Recharge/>;
            case 'withdraw':
                return <Withdraw/>;
            case 'promote':
                return <Promote/>;
            case 'mobi':
                return <Mobi/>;
            case 'flowrecord':
                return <Flowrecord/>;
            default:
                break;
        }
    }
    
    return (
        <div className="purse-nav-box">
            <div className="purse-nav">
                <div className="purse-nav-container">
                    { navData.map((v, i) => {
                        let _cn = v.type === currentNav ? 'nav-item current' : 'nav-item'
                        return (
                            <div key={ i }
                                 style={ { marginLeft: i % 5 && '.5%' } }
                                 onClick={ navChange.bind(null, v.type) }
                                 className={ _cn }>
                                { v.title }
                            </div>
                        )
                    }) }
                </div>
                
                <div className="purse-nav-content">
                    { renderContent() }
                </div>
            </div>
        </div>
    );
}


export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo,
        };
    },
    function mapDispatchToProps(disatch) {
        return {
            setUserSignIn: () => disatch(setUserSignIn()),
            checkUserSignIn: () => disatch(checkUserSignIn()),
            upDateUserAssets: () => disatch(upDateUserAssets())
        };
    }
)(PurseNav);
