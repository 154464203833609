import React, {
    antdUI,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import "./sortTab.scss";
import { Sort, Pattern, PriceFilter, WearFilter, StyleSelection, Gradient } from './filterItem/index';


function SortTab(props) {
    const { changeFilter, cRef, asHashName, curGame, hashData, curExId } = props;
    const [ minP, setMinP ] = useState(hashData.minPrice || ''),
        [ maxP, setMaxP ] = useState(hashData.maxPrice || ''),
        [ minW, setMinW ] = useState(hashData.minfloatval || ''),
        [ maxW, setMaxW ] = useState(hashData.maxfloatval || ''),
        [ minPrice, setMinPrice ] = useState(hashData.minPrice || ''),
        [ maxPrice, setMaxPrice ] = useState(hashData.maxPrice || ''),
        [ minWear, setMinWear ] = useState(hashData.minfloatval || ''),
        [ maxWear, setMaxWear ] = useState(hashData.maxfloatval || ''),
        [ sort, setSort ] = useState(Number(hashData.sort || 3)),
        [ isInt, setIsInt ] = useState(false),
        [ patVal, setPatval ] = useState(hashData.patVal || ''),
        [ style, setStyle ] = useState(),
        [ marbleStyle, setMarbleStyle ] = useState(),
        [ colorVal, setColorVal ] = useState();

    const wearList = JSON.parse(localStorage.getItem('wearList') || '[]');

    const exterior = wearList.filter(item => {
        return item.id === Number(curExId)
    })?.[0]?.exterior || '';

    useImperativeHandle(cRef, () => ({
        filterPage: (curPage) => {
            changeFilter({
                sort,
                style,
                marbleStyle,
                colorVal,
                maxPrice,
                minPrice,
                minfloatval: minWear,
                maxfloatval: maxPrice,
                curPage,
                patVal,
                concentration: hashData.concentration
            })
        },
    }));

    const sortAndPatChange = () => {
        changeFilter({
            curPage: 1,
            minfloatval: minWear,
            maxfloatval: maxWear,
            minPrice,
            maxPrice,
            sort,
            patVal,
            style,
            marbleStyle,
            colorVal
        });
    };

    //重置筛选显示
    const resetFilter = () => {
        setMinW('')
        setMaxW('')
        setMinP('')
        setMaxP('')
        setPatval('')
    }

    useEffect(() => {
        if (! isInt) {
            setIsInt(true)
        } else {
            sortAndPatChange();
        }
        // eslint-disable-next-line
    }, [ sort, patVal, style, marbleStyle, minPrice, maxPrice, minWear, maxWear, colorVal ]);

    useEffect(() => {
        //当商品id发生变化时清除筛选条件
        resetFilter();
    }, [ curExId ])

    const floatValFilter = (min, max) => {

        // console.log(min, max)
        if (! min && ! max) {
            setMaxW("");
            setMinW("");
            setMaxWear("");
            setMinWear("");
            return;
        }

        if ((min === '' && max) || (max === '' && min)) {
            antdUI.message.warning("请正确输入磨损区间~");
            setMaxW("");
            setMinW("");
            return
        }

        if (Number(max) >= Number(min)) {
            setMaxWear(max);
            setMinWear(min);
        } else {
            antdUI.message.warning("请正确输入磨损区间~");
            setMaxW("");
            setMinW("");
        }
    };

    let priceFilter = (min, max) => {
        if ((! min && max) || (! max && min)) {
            antdUI.message.warning("请正确输入价格区间~");
            setMaxP("");
            setMinP("");
            return
        }
        if (Number(max) >= Number(min)) {
            setMaxPrice(max);
            setMinPrice(min);
        } else {
            antdUI.message.warning("请正确输入价格区间~");
            setMaxP("");
            setMinP("");
        }
    };

    return (
        <div className="sort-tab-container">
            <div className="sort-price-filter">
                <Sort sort={ sort } setSort={ setSort } curGame={ curGame }></Sort>
                {
                    Number(curGame) === 730 &&
                    asHashName.search('Sticker') === -1 &&
                    <Pattern asHashName={ asHashName } setMarbleStyle={ setMarbleStyle } patVal={ patVal }
                             setPatVal={ setPatval }></Pattern>
                }
                <PriceFilter
                    priceFilter={ priceFilter }
                    minPrice={ minP }
                    setMinPrice={ setMinP }
                    maxPrice={ maxP }
                    setMaxPrice={ setMaxP }
                ></PriceFilter>

            </div>
            { Number(curGame) === 730 &&
            <>
                <Gradient colorVal={ colorVal } asHashName={ asHashName } setColorVal={ setColorVal }></Gradient>
                <StyleSelection setPatVal={ setPatval } hashName={ asHashName || '' } setMarbleStyle={ setMarbleStyle }
                                setStyle={ setStyle } style={ style } marbleStyle={ marbleStyle }></StyleSelection>
            </>
            }
            {
                Number(curGame) === 730 && asHashName.search('Sticker') === -1 && exterior &&
                <div className="sort-wear-filter">
                    <WearFilter
                        exterior={ exterior }
                        floatValFilter={ floatValFilter }
                        minWear={ minW }
                        setMinWear={ setMinW }
                        maxWear={ maxW }
                        setMaxWear={ setMaxW }
                    ></WearFilter>
                </div>
            }

        </div>
    );
}

export default SortTab;
