import React from 'react';
import './listitem.scss'

function ListItem(props) {
    const { type, offData } = props;
    const { tradeofferid, offerCode, registrationTime } = offData || {}
    let message, offType, offLink = `https://steamcommunity.com/tradeoffer/${ tradeofferid }/`;
    switch (type) {
        case 'buyer':
            message = '请尽快接受报价';
            offType =
                offType = <p>[收货] 报价唯一验证码 <span
                    style={ { color: "#5cb9f3", fontWeight: "bold" } }> { offerCode } </span></p>
            break;
        case 'seller':
            message = `对方加入Steam日期: ${ registrationTime.slice(0, 10) }`;
            offType = <p>[发货] 报价唯一验证码 <span
                style={ { color: "#5cb9f3", fontWeight: "bold" } }> { offerCode } </span></p>
            break;
        default:
            break;
    }
    
    return (
        <div className="offer-list-item">
            <div className="message">{ message }</div>
            <div className="off-type">{ offType }</div>
            <div className="handle-box">
                <a className="text" target="_blank"
                   href={ offLink } rel="noopener noreferrer"
                >{ type === 'buyer' ? '卖' : '买' }
                    家已发送交易报价，请前往Steam处理
                    <i className="iconfont icon-arrow-right"></i>
                    <i className="iconfont icon-arrow-right"></i>
                </a>
            </div>
        </div>
    )
}

export default ListItem
