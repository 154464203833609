import React, { antdUI, useEffect, useState } from 'react';
import { dopplerData } from '@/data/dopplerData'
function StyleSelection(props) {
  const { setStyle, hashName, setMarbleStyle, setPatVal, style, marbleStyle } = props
  const [selectOptions, setSelectOptions] = useState([]);
  const [isMarble, setIsMarble] = useState(false);


  const { Select } = antdUI;
  const { Option } = Select;
  useEffect(() => {
    let _hashName = ''
    for (const key in dopplerData) {
      if (Object.prototype.toString.call(dopplerData[key].value) === '[object Array]') {
        for (let item of dopplerData[key].value) {
          _hashName = hashName.replace(/\(.*?\)/g, "").trim()
          if (_hashName === item) {
            setIsMarble(true);
            setSelectOptions(dopplerData[key].children)
            break
          }
        }
      } else {
        if (hashName.match(dopplerData[key].value)) {
          // console.log({has:hashName,val:dopplerData[key].value})
          setIsMarble(false);
          setSelectOptions(dopplerData[key].children)
        }
      }
    }

  }, [hashName])

  const styleChange = (value) => {
    // value = Object.prototype.toString.call(value) === '[object String]' ? JSON.parse(value) : value;
    if (isMarble) {
      setMarbleStyle(value); setPatVal();
    } else {
      setStyle(value)
    }
  }

  // useEffect(()=>{
  //
  // },[])

  return (
    selectOptions.length !== 0 && <div className='style-selection'>
      <Select size={'small'} allowClear value={isMarble ? marbleStyle : style} style={{ width: '100px' }} placeholder={'款式筛选'} onChange={styleChange} >
        {
          selectOptions.map((item, index) => {
            return <Option value={item.value} key={index}>{item.label}</Option>
          })
        }
      </Select>
    </div>
  )
}

export default StyleSelection
//款式选择  用于多普勒 伽玛多普勒以及渐变大理石
