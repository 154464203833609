import React, {antdUI, $tools, useRef} from 'react';
import './priceFilter.scss'
function PriceFilter(props){

  const {priceFilter, minPrice, setMinPrice, maxPrice, setMaxPrice} = props

  const { checkInputValNumber,debounce} = $tools;
  const delayPriceFilter = useRef(debounce(priceFilter,1000,true)).current

  return(
    <div className='price-filter'>
      <antdUI.Input
        prefix="￥"
        size={"small"}
        value={minPrice}
        allowClear
        placeholder='价格'
        onChange={(_) => setMinPrice(checkInputValNumber(_.target.value))}
        style={{ width: "90px" }}
      ></antdUI.Input>
      <span style={{ color: "white" }}>—</span>
      <antdUI.Input
        prefix="￥"
        size={"small"}
        value={maxPrice}
        allowClear
        placeholder='价格'
        onChange={(_) => setMaxPrice(checkInputValNumber(_.target.value))}
        style={{ width: "90px" }}
      ></antdUI.Input>

      <antdUI.Button
        size={"small"}
        onClick={()=>delayPriceFilter(minPrice,maxPrice)}
        className="price-filter-btn"
        // size={"small"}
      >
        过滤
      </antdUI.Button>
    </div>


  )
}



export default PriceFilter
