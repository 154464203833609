import React, { $api, $tools, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { upDateUserAssets } from "@/store/actions/userInfo";
import FilterTab from "./filterTab/index";
import { Buying, OnSale, TransRecords, WearRanking } from "./typePage/index";
import "./index.scss";
import PriceTrend from './typePage/priceTrend';

function AccessoriesContainer(props) {
    let {
        statisticalData, setNavData, navData, userAssets, upDateUserAssets, userInfo, searchData, hashData, curExId,
        processFilterData, hash, setStatisticalData, pastDays, setPastDays, history
    } = props;
    const { curType, curGame } = searchData;
    
    const [ listType, setListType ] = useState(Number(hashData.tab) || 1), //1:出售中，2：求购中，3：成交记录，4：磨损排行
        [ saleData, setSaleData ] = useState([]),
        [ wearData, setWearData ] = useState([]),
        [ total, setTotal ] = useState(0),
        [ curPage, setCurPage ] = useState(1),
        [ asHashName, setHashName ] = useState(''),
        [ hasPast, setHasPast ] = useState(false),
        [ saleTotal, setSaleTotal ] = useState(0),
        [ tradeLoading, setTradeLoading ] = useState(true),
        [ wearLoading, setWearLoading ] = useState(true),
        [ buyingLoading, setBuyingLoading ] = useState(true),
        [ buyingTotal, setBuyingTotal ] = useState(0);
    
    const [ tableLoading, setTableLoading ] = useState(true);
    const childRef = useRef();
    const filterPage = childRef.current?.filterPage;
    
    const { alipay_amount, other_amount } = userAssets;
    
    
    const getWearData = async (data) => {
        let arr = [];
        data.forEach((item) => {
            arr.push({
                value: item.exterior,
                id: item.id,
                quality: item.quality,
                total: item.total,
                hashName: item.market_hash_name,
                price: item.price
            });
        });
        localStorage.setItem("wearList", JSON.stringify(data))    //缓存外观列表数据
        setWearData(arr);   //设置外观列表
    };
    
    //设置顶部属性
    const getNavData = (data) => {
        let obj = {};
        data = data.filter(item => item.id === Number(curExId))?.[0]
        const {
            market_name,
            exterior,
            quality,
            icon_url,
            img_name,
            rarity,
            id,
            price,
            itemType,
            type,
            weapon,
            market_hash_name
        } = data || {};
        market_name && (obj.market_name = market_name);
        exterior && (obj.exterior = exterior);
        quality && (obj.quality = quality);
        icon_url && (obj.icon_url = icon_url);
        img_name && (obj.img_name = img_name);
        rarity && (obj.rarity = rarity);
        id && (obj.id = id);
        price && (obj.price = price);
        itemType && (obj.itemType = itemType);
        type && (obj.type = type);
        weapon && (obj.weapon = weapon);
        market_hash_name && (obj.market_hash_name = market_hash_name);
        setNavData(obj);
    };
    
    const getTypePage = () => {
        switch (listType) {
            case 1:
                return (
                    <OnSale
                        navData={ navData }
                        curExId={ curExId } curGame={ curGame } total={ total } curPage={ curPage }
                        filterPage={ filterPage } alipay_amount={ alipay_amount }
                        other_amount={ other_amount } userInfo={ userInfo }
                        saleData={ saleData } searchData={ searchData } setSaleData={ setSaleData }
                        getDetailList={ getDetailList } upDateUserAssets={ upDateUserAssets }
                        setTableLoading={ setTableLoading } tableLoading={ tableLoading }
                    ></OnSale>
                );
            case 2:
                return (
                    <Buying navData={ navData } getDetailList={ getDetailList } buyingLoading={ buyingLoading }
                            searchData={ searchData }
                            saleData={ saleData } filterPage={ filterPage } curExId={ curExId }
                    ></Buying>)
            case 3:
                return <TransRecords total={ total } curPage={ curPage } filterPage={ getDetailList }
                                     curExId={ curExId }
                                     tradeLoading={ tradeLoading }
                                     dataSource={ listType === 3 ? saleData : [] }></TransRecords>;
            case 4:
                return <WearRanking
                    total={ total } curPage={ curPage } filterPage={ getDetailList } curExId={ curExId }
                    wearLoading={ wearLoading } dataSource={ listType === 4 ? saleData : [] }></WearRanking>
            case 5:
                return <PriceTrend curType={ searchData.curType } statisticalData={ statisticalData }
                                   setPastDays={ setPastDays } pastDays={ pastDays }></PriceTrend>
            default:
                return <OnSale filterPage={ filterPage }></OnSale>;
        }
    };
    const getData = async () => {
        const { hashName, chooseWeapon } = await getDetailList(curExId, null, 1);
        
        setHashName(hashName || '')
        
        let wearList = JSON.parse(localStorage.getItem('wearList') || '[]');
        
        if (wearList.findIndex(item => item.id === Number(curExId)) !== -1) {
            await getWearData(wearList);
            await getNavData(wearList);
            return;
        }
        
        let _asHashName, res;
        if (Number(curGame) === 730 && hashName) {
            if (! hashName.match('Sticker')) {
                _asHashName = hashName.replace(/\(.*?\)/g, "").replace('★', "").replace("StatTrak™", "").replace(/(^\s*)/g, "").replace("Souvenir ", "")
            } else {
                _asHashName = hashName
            }
            const conf = { hash_name: _asHashName, appid: curGame }
            chooseWeapon === "weapon_bayonet" && (conf.chooseWeapon = "weapon_bayonet")
            res = await $api.market.getAccessoriesList(conf);
        } else {
            hashName && (_asHashName = formatHashName(hashName));
            if (_asHashName) {
                res = await $api.market.getAccessoriesList({
                    hash_name: JSON.stringify(_asHashName),
                    appid: curGame,
                });
            }
        }
        if ($tools.checkResult(res)) {
            await getWearData(res?.data);
            await getNavData(res.data)
        }
        
    }
    const getDetailList = async (id, page, obj) => {
        let {
            curPage: _curPage, sort, patVal, minPrice,
            maxPrice, maxfloatval, minfloatval, style,
            marbleStyle, colorVal, concentration
        } = hashData;
        if (page || _curPage) {
            setCurPage(Number(page || _curPage));
        }
        
        let postData = { id, curPage: page || _curPage, appid: curGame };
        if (typeof id === 'object') {
            window.localStorage.removeItem('wearList');
            getData().then();
            return
        }
        if (concentration === 'quadrupleSticker' || concentration === 'fullSticker') {
            let conType = 1;
            switch (concentration) {
                case 'fullSticker':
                    conType = [ 4, 5, 10 ];
                    break;
                case 'quadrupleSticker':
                    conType = [ 10 ];
                    break;
                default:
                    break;
            }
            postData.sticker = JSON.stringify(conType);
        } else if (concentration === 'cato' || concentration === 'goldStickers') {
            let conType = 1;
            switch (concentration) {
                case 'cato':
                    conType = 'KATOWICE';
                    break;
                case 'goldStickers':
                    conType = '(Gold)';
                    break;
                default:
                    break;
            }
            postData.stickerStr = conType;
        }
        
        if (listType === 1) {
            setTableLoading(true);
        } else if (listType === 2) {
            postData.type = 'buying';
            setBuyingLoading(true);
        } else if (listType === 3) {
            postData.type = 'deal'
            setTradeLoading(true);
        } else if (listType === 4) {
            postData.type = 'wear'
            setWearLoading(true);
        }
        
        minPrice && (postData.minPrice = minPrice);
        maxPrice && (postData.maxPrice = maxPrice);
        maxfloatval && (postData.maxfloatval = maxfloatval);
        minfloatval && (postData.minfloatval = minfloatval);
        style && (postData.paintindex = style)
        marbleStyle && (postData.paintseed = marbleStyle)
        colorVal && (postData.paintseed = colorVal)
        if (! sort) sort = 3;
        sort && (postData.sort = sort);
        patVal && (postData.paintseed = patVal);
        
        pastDays && (postData.pastDays = pastDays);
        
        let res = {};
        setSaleData([])
        id && (res = await $api.market.getAccDetail(postData));
        if ($tools.checkResult(res)) {
            let { data } = res;
            setTotal(data?.total);
            if (Number(curGame) === 730) {
                const { OnSalePutItemCsgos: v } = data || {};
                v.forEach(_ => {
                    _.refPrice = data.price
                })
                v && setSaleData(v[0] ? JSON.parse(JSON.stringify(v)) : []);
            } else {
                const { OnSalePutItemDota2s: v } = data || {};
                v && setSaleData(v[0] ? JSON.parse(JSON.stringify(v)) : []);
            }
            if (listType === 1) {
                setSaleTotal(data?.total);
            }
            
            const saveId = localStorage.getItem('saveId');
            if (saveId !== id) {
                setStatisticalData(data?.yesdayFinalPrice)
                localStorage.setItem('saveId', id)
            }
            
            if (listType === 1) {
                setTableLoading(false);
            } else if (listType === 2) {
                setBuyingTotal(data.buyingCount);
                setBuyingLoading(false);
            } else if (listType === 3) {
                setTradeLoading(false);
            } else if (listType === 4) {
                setWearLoading(false);
            }
            if (obj) {
                return { hashName: data?.market_hash_name, chooseWeapon: data?.chooseWeapon }
            } else {
                return data?.market_hash_name;
            }
        }
    };
    
    
    useEffect(() => {
        setCurPage(1)
        if (listType === 5) {
            return;
        }
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ curType, curGame, listType, curExId, hash ]);
    
    useEffect(() => {
        if (hasPast) {
            getDetailList(curExId);
        } else {
            setHasPast(true);
        }
        // eslint-disable-next-line
    }, [ pastDays ])
    
    
    return (
        <div className="accessories-list tranding-list-buy">
            <FilterTab
                buyingTotal={ buyingTotal }
                processFilterData={ processFilterData }
                wearTabData={ wearData }
                listType={ listType }
                setListType={ setListType }
                totalOnSale={ saleTotal }
                curExId={ curExId }
                curType={ curType } cRef={ childRef }
                type={ navData.type }
                asHashName={ asHashName }
                curGame={ curGame }
                searchData={ searchData }
                hashData={ hashData }
                hash={ hash }
                history={ history }
            ></FilterTab>
            
            <div className="accessories-list-container">{ getTypePage() }</div>
        </div>
    );
}


export default connect(
    function mapStateToProps(state) {
        return {
            userAssets: state.user.userAssets,
            userInfo: state.user.userInfo,
        };
    },
    function mapDispatchToProps(disatch) {
        return {
            upDateUserAssets: () => disatch(upDateUserAssets()),
        };
    }
)(AccessoriesContainer);


function formatHashName(hashName) {
    let dotaQualities = [
        { label: '铭刻', value: 'Inscribed' }, { label: '冻人', value: 'Frozen' },
        { label: '纯正', value: 'Genuine' }, { label: '尊享', value: 'Exalted' },
        { label: '英雄传世', value: 'Heroic' }, { label: '融合', value: 'Infused' },
        { label: '吉祥', value: 'Auspicious' }, { label: '冥灵', value: 'Corrupted' },
        { label: '亲笔签名', value: 'Autographed' }, { label: '独特', value: 'Unusual' },
        { label: '凶煞', value: 'Cursed' }, { label: '上古', value: 'Elder' },
    ]
    let itemV;
    let nameArr = []
    let flag = false;
    for (const item of dotaQualities) {
        itemV = item.value;
        if (hashName.match(itemV)) {
            flag = true;
            hashName = hashName.replace(itemV, '')
        }
    }
    if (flag) {
        dotaQualities.forEach(item => {
            let name = item.value + hashName
            nameArr.push(name);
        })
        nameArr.push(hashName.trim())
    } else {
        dotaQualities.forEach(item => {
            let name = item.value + ' ' + hashName
            nameArr.push(name);
        })
        nameArr.push(hashName)
    }
    return nameArr;
}



