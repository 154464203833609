import React, { $csgoWearColor, $tools } from "react";
import { Link } from "react-router-dom";

function CsgoItems(props) {
    let { style: csgo_st, type, item: v, contentType } = props,
        img_bs = "https://steamcommunity-a.akamaihd.net/economy/image/";
    
    const { obj2strUrl } = $tools
    const { color: exterior_bcc, name: exteriorLabel } = $csgoWearColor(v?.exterior)
    
    const getPageType = (quality) => {
        if (quality === "strange" || quality === "unusual_strange") {
            return 2;
        } else if (quality === 'tournament') {
            return 3;
        } else {
            return 1;
        }
    }
    
    const getConcentration = () => {
        let data = JSON.parse(localStorage.getItem('rememberFilter'));
        let obj = { tab: contentType === 'onSale' ? 1 : 2 };
        window.location.pathname === '/home' && (obj.tab = 1);
        if (data) obj.concentration = data?.concentration?.[0] || 'unlimited';
        return obj2strUrl(obj, '#');
    }
    
    return (
        <Link
            to={
                {
                    pathname: '/marketPlace/accessoriesList',
                    search: obj2strUrl({
                        id: v.id,
                        curGame: '730',
                        curType: getPageType(v.quality),
                    }, '?'),
                    hash: getConcentration()
                }
            }
            
            style={ csgo_st }
            className="csgo-items"
        >
            <div className="_img" style={ { paddingBottom: type === 'home' && '1rem' } }
                 onLoad={ (e) => (e.currentTarget.style.opacity = 1) }>
                { exterior_bcc && (
                    <div
                        style={ { backgroundColor: exterior_bcc } }
                        className="subscript"
                        alt={ exteriorLabel }
                    >
                        { exteriorLabel }
                    </div>
                ) }
                
                <img
                    className="item-img"
                    src={ img_bs + v.icon_url }
                    alt={ v.market_name }
                />
            </div>
            
            <p title={ v.market_name } className="item-name">{ v.market_name }</p>
            
            <p className="price">
                { v.price ? <><small>￥</small>{ v.price }</> : '' }
                {/*<small>*/ }
                {/*  {String(v.price).indexOf(".") > 0 &&*/ }
                {/*    String(v.price).slice(String(v.price).indexOf("."))}*/ }
                {/*</small>*/ }
            </p>
            
            <div className="end-time">
                <p style={ { fontWeight: 700 } }>{ v.saleCount } 件{ contentType === 'buying' ? '需求' : '在售' }</p>
            </div>
        </Link>
    );
}

export default CsgoItems;
