import React, { $tools, antdUI } from 'react';
import "./index.scss";
import { Link } from 'react-router-dom'

function Payment(props) {
    const {
        alipay_amount,
        buyPrice,
        notWorth,
        paySubmit,
        Loading,
        appid,
        paymentItemInfo = {}
    } = props;
    const { icon_url, floatvalue, img_name, market_name } = paymentItemInfo

    return (
        <div className="accessories-buyer">
            <div
                style={ {
                    position: 'absolute', width: '600px', textAlign: 'center',
                    top: '55px', left: '0', padding: '10px 0', backgroundColor: '#D2E9D7',
                    color: '#2DAF61'
                } }
            >你付款后须5分钟内在MOBI APP发起报价,卖家须12小时内完成发货.
            </div>

            <div className="goods-detail-box">
                <h3>商品详情</h3>
                <span className="line-columns"></span>
                <div className="box flex">
                    <div className="goods-icon"
                         style={ { background: Number(appid) === 570 && 'none', margin: '0 20px' } }>
                        <img src={ img_name ? `${ React.StaticBaseUrl }/market-csgo/${ img_name }.png`
                            : $tools.BASE_STEAM_IMG + icon_url }
                             alt={ "饰品" }/>
                    </div>

                    <div>
                        <h3 style={ { color: "#222222", fontSize: '20px' } }>{ market_name }</h3>
                        { floatvalue && <h4 style={ { color: "#666666", marginTop: "20px" } }>磨损： { floatvalue }</h4> }
                    </div>
                </div>
            </div>


          <div className="pay-method-con">
            <h3>支付方式</h3>
            <div className="balance-tip">
              <div className="tip-prop">
                <img style={{width: '30px', marginRight: '6px'}} src={require('../../../../images/tradingMarket/banlance-icon.png')} alt=""/>
                <span>余额</span>
              </div>
              <div className="balance-detail">
                {
                  notWorth?
                    <p className="not-worth">余额不足 <Link to="/profile/wallet">前往充值</Link></p>: ''
                }
                <p>￥{alipay_amount}</p>
              </div>
            </div>
          </div>
            <div className="accessories-buyer-summary">
                <p>
                    应付款：
                    <span className="summary-icon">
                    ￥<span className="summary-money">{ buyPrice }</span>
                     </span>
                </p>


                { notWorth ? (
                    <antdUI.Button
                        size="large"
                        className="accessories-buyer-btn"
                        style={ { backgroundColor: "#bbc0b8", border: "none", width: '160px' } }
                        type={ "primary " }
                    >
                        余额不足
                    </antdUI.Button>
                ) : (
                    <antdUI.Button
                        style={{ backgroundColor: "#58AD69", border: "none", width: '160px' } }
                        onClick={ () => paySubmit() }
                        loading={ Loading }
                        size="large"
                        className="accessories-buyer-btn"
                        type={ "primary " }
                    >
                        确认付款
                    </antdUI.Button>
                ) }
            </div>
        </div>
    );
}

export default Payment;
