import React, { antdUI } from "react";
import Nav from "@/components/nav";
import BottomBar from "@/components/bottomBar";
import "./index.scss"

import { connect } from "react-redux";
import { checkUserSignIn, setUserSignIn, upDateUserAssets, } from "@/store/actions/userInfo";

import { upDateFavoritesData } from '@/store/actions/favoritesData'

import { BrowserRouter as Router, Switch } from "react-router-dom";
import FrontendAuth from "./FrontenAuth";

// import Home from "@/pages/Home";
// import Home from "@/pages/ExampleHome";
import TradingMarket from "@/pages/TradingMarket";
import MyInventory from "@/pages/MyInventory";
import PersonalCenter from "@/pages/PersonalCenter";
import AccessoriesList from "@/pages/AccessoriesList";
import HelpCenter from "@/pages/HelpCenter";
import MySale from "@/pages/MySale";
import MyPur from "@/pages/MyPur";
// import FormulaSquare from "@/pages/FormulaSquare";
import NotFound from "@/components/404";
// import MotGo from "@/pages/MOEGo";
// import PopularItem from "@/components/home/PopularItem";
import MobiModal from "@/components/MobiModal";
// import ContactUs from '@/pages/ContactUs'
// import RollRoom from '@/pages/RollRoom'
// import RollRoomDetail from '@/pages/RollRoom/detail.js'
// import Promoted from '@/pages/promoted/'

// function t_test(props) {
//     return <PopularItem className={ props.match.path.slice(1) }></PopularItem>;
// }

const routerConfig = [
    { title: "首页", path: "/", component: TradingMarket },
    { title: "首页", path: "/home", component: TradingMarket },
    { title: "饰品交易市场", path: "/marketPlace", component: TradingMarket, },
    { title: "饰品交易市场详情", path: "/marketPlace/accessoriesList", component: AccessoriesList, },
    // { title: "MOBI联盟计划", path: "/equityPlan", component: AffiliateProgram, },
    // { title: "MOBI权益计划", path: "/affiliateProgram", component: AffiliateProgram },
    { title: "我的库存", auth: true, path: "/inventory", component: MyInventory },
    { title: "我的出售", auth: true, path: "/mySale", component: MySale },
    { title: "我的购买", auth: true, path: "/myPur", component: MyPur },
    { title: "个人中心", auth: true, path: "/profile", component: PersonalCenter, },
    { title: "个人中心", auth: true, path: "/profile/userConfig", component: PersonalCenter, },
    { title: "个人中心", auth: true, path: "/profile/wallet", component: PersonalCenter, },
    // { title: "个人中心", auth: true, path: "/profile/reputation", component: PersonalCenter, },
    // { title: "个人中心", auth: true, path: "/profile/rate", component: PersonalCenter, },
    { title: "个人中心", auth: true, path: "/profile/favorites", component: PersonalCenter, },
    { title: "个人中心", auth: true, path: "/profile/news", component: PersonalCenter, },
    { title: "个人中心", auth: true, path: "/profile/customerService", component: PersonalCenter, },
    // { title: "炼金配方(精确)", path: "/formulaSquare", component: FormulaSquare },
    // { title: "Roll房", path: "/rollRoom", component: RollRoom },
    // { title: "交易佣金奖励", path: "/promoted", component: Promoted },
    // { title: "Roll房详情", path: "/rollRoom/detail", component: RollRoomDetail },
    // { title: "MOE治理与激励和白皮书", path: "/moeGo", component: MotGo },
    // { title: "舆情报告", path: "/insteadOf", component: t_test },
    // { title: "库存估价", path: "/inStock", component: t_test },
    // { title: "联系我们", path: "/ContactUs", component: ContactUs },
    { title: "帮助", path: "/help", component: HelpCenter },
    { title: "404", path: "/404", component: NotFound }
];

function Routers(props) {
    const pathname = window.location.pathname
    const sw = pathname === '/ContactUs'
    const { userInfo, setUserSignIn, checkUserSignIn, upDateUserAssets, upDateFavoritesData } = props;
    const isiPhone = /iPhone/i.test(navigator?.userAgent);
    const isAndroid = /Android/i.test(navigator?.userAgent);
    // useEffect(_ => {
    //     // antdUI.message.warning('APP发货功能正在维护，预计5月底完善发货功能并上线包含汰换模拟功能在内的APP新版本，欢迎加QQ群860316044了解更多资讯！')
    // })
    return (
        <Router>
            <div className="download-title-bar" style={ { display: (isiPhone || isAndroid) ? 'flex' : 'none' } }>
                <img src={ require("@/images/favicon1.jpg") } alt=""/>
                <div className="title-text">
                    <h1>PZ饰品交易平台</h1>
                    <p>支持DOTA2，CSGO等热门游戏</p>
                </div>
                <div className="download-title-bar-btn">
                    { isAndroid && <antdUI.Button type={ "primary" } id="J-open-app" onClick={ () => {
                        window.location.href = "https://static.pzhappy.com/download/android"
                    } }>下载
                    </antdUI.Button> }
                    { isiPhone && <antdUI.Button type={ "primary" } onClick={ () => {
                        antdUI.message.warning("暂时无法下载IOS App！")
                        // window.location.href = "https://api.pahappy.com/webres/app/download/"
                    } }>下载
                    </antdUI.Button> }
                </div>
            </div>
            <div className="mobi">
                <MobiModal/>
                { ! sw && <Nav/> }
                <Switch>
                    <FrontendAuth
                        userInfo={ userInfo }
                        upDateUserAssets={ upDateUserAssets }
                        checkUserSignIn={ checkUserSignIn }
                        setUserSignIn={ setUserSignIn }
                        config={ routerConfig }
                        upDateFavoritesData={ upDateFavoritesData }
                    />
                </Switch>
                <BottomBar/>
            </div>
        </Router>
    );
}

export default connect(
    // store state
    // reducer
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo,
        };
    },
    function mapDispatchToProps(disatch) {
        return {
            setUserSignIn: () => disatch(setUserSignIn()),
            checkUserSignIn: () => disatch(checkUserSignIn()),
            upDateUserAssets: () => disatch(upDateUserAssets()),
            upDateFavoritesData: () => disatch(upDateFavoritesData())
        };
    }
)(Routers);
