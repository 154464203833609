import React, { useEffect, useState, $api, $tools, useRef } from "react";
import TradingMarketNav from "@/components/tradingMarket/traMarNav";
import TradingMarketContainer from "@/components/tradingMarket/traMarContainer/index.js";
import "./index.scss";

export default function TradingMarkets() {
    const loCurNav = localStorage.getItem("curNav");
    const priceConfig = JSON.parse(localStorage.getItem("rememberPrice")) || {};
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ total, setTotal ] = useState(0);
    const [ itemData, setItemData ] = useState([]);
    const [ currentNav, setNav ] = useState(loCurNav || "730");
    const [ contentType, setContentType ] = useState("onSale"); //onSale:出售中   buying；求购中
    const [ marketLoading, setMarketLoading ] = useState(true);
    const [ inputMinPrice, setInputMinPrice ] = useState(priceConfig.minPrice || "");
    const [ inputMaxPrice, setInputMaxPrice ] = useState(priceConfig.maxPrice || "");
    const pageSize = 20;
    
    const childRef = useRef();
    
    const getDataForPage = childRef.current?.getData;
    
    const handleGetItemsOp = ({
                                  dQuality,
                                  dRarity,
                                  heroName,
                                  rarity,
                                  exterior,
                                  itemType,
                                  quality,
                                  type,
                                  weapon,
                                  searchContent,
                                  priceSort,
                                  collection,
                                  currentPage, concentration
                              }) => {
        
        let postData = {};
        
        const rememberFilterData = JSON.parse(localStorage.getItem('rememberFilter'));
        const {
            type: rType, weapon: rWeapon, searchContent: rSearchContent, concentration: rConcentration,
            exterior: rExterior, quality: rQuality, rarity: rRarity, collection: rCollection, priceSort: rPriceSort
        } = rememberFilterData || {};
        
        exterior = rExterior || exterior
        quality = rQuality || quality
        rarity = rRarity || rarity
        
        const {
            heroName: rHeroName,
            searchContent: rSearchContent2
        } = JSON.parse(localStorage.getItem('rememberFilterd2')) || {};
        currentNav === "730" &&
        (type ? (postData.type = type) : (postData.type = rType || ''));
        
        inputMinPrice && (postData.minPrice = inputMinPrice)
        inputMaxPrice && (postData.maxPrice = inputMaxPrice)
        
        if (currentNav === '730') {
            weapon ? (postData.weapon = weapon) : (rWeapon && (postData.weapon = rWeapon));
        }
        
        priceSort ? (postData.priceSort = priceSort) : (rPriceSort && (postData.priceSort = rPriceSort));
        
        if (currentNav === '730') {
            searchContent ? (postData.searchContent = searchContent) : (rSearchContent && (postData.searchContent = rSearchContent))
        } else {
            searchContent ? (postData.searchContent = searchContent) : (rSearchContent2 && (postData.searchContent = rSearchContent2))
        }
        
        if (rarity && rarity[0] && rarity[0] !== 'unlimited') {
            if (rarity[0] === 'Type_CustomPlayer') {
                postData.rarity = rarity[1]
            } else {
                postData.rarity = rarity[0]
            }
        }
        exterior &&
        exterior[0] &&
        exterior[0] !== "unlimited" &&
        (postData.exterior = exterior[0]);
        quality &&
        quality[0] &&
        quality[0] !== "unlimited" &&
        (postData.quality = quality[0]);
        itemType && itemType[0] && (postData.itemType = itemType[0]);
        heroName ? (postData.hero = heroName) : (rHeroName && (postData.hero = rHeroName));
        dQuality && (postData.quality = dQuality);
        dRarity && (postData.rarity = dRarity);
        postData.curPage = currentPage;
        currentPage ? setCurrentPage(currentPage) : setCurrentPage(1);
        
        let _concentration = rConcentration?.[0] || concentration?.[0]
        
        if (_concentration === 'quadrupleSticker' || _concentration === 'fullSticker') {
            let conType = 1;
            switch (_concentration) {
                case 'fullSticker':
                    conType = [ 4, 5, 10 ];
                    break;
                case 'quadrupleSticker':
                    conType = [ 10 ];
                    break;
                default:
                    break;
            }
            postData.sticker = JSON.stringify(conType)
        } else if (_concentration === 'cato' || _concentration === 'goldStickers') {
            let conType = 1;
            switch (_concentration) {
                case 'cato':
                    conType = 'KATOWICE';
                    break;
                case 'goldStickers':
                    conType = '(Gold)';
                    break;
                default:
                    break;
            }
            postData.stickerStr = conType
        }
        
        postData.itemSet = rCollection?.[1] || collection?.[1];
        
        postData.marketType = contentType;
        return postData;
    };
    
    const getData = async (config = {}) => {
        setItemData([]);
        let postData = handleGetItemsOp(config);
        setMarketLoading(true);
        setTotal(0)
        const res = await $api.market.getMarketData({
            appid: currentNav,
            pageSize,
            ...postData,
        })
        
        if ($tools.checkResult(res)) {
            localStorage.setItem('rememberPrice', JSON.stringify({ minPrice: inputMinPrice, maxPrice: inputMaxPrice }))
            setTotal(res.data?.total);
            setTimeout(() => {
                setMarketLoading(false);
                setItemData(res.data?.items);
            }, 100);
        }
    };
    
    useEffect(() => {
        // console.log(currentNav, contentType)
        // localStorage.removeItem("wearList");  //回到市场清空顶部外观列表
        getData().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ currentNav, contentType ]);
    
    return (
        <div className="trading-market">
            <img
                className="tranding-market-banner"
                src={ require("@/images/tradingMarket/banner2@2x.png") }
                alt=""
            />
            <TradingMarketNav
                cRef={ childRef }
                currentNav={ currentNav }
                setNav={ setNav }
                contentType={ contentType }
                setContentType={ setContentType }
                filterChange={ getData }
                inputMinPrice={ inputMinPrice }
                setInputMinPrice={ setInputMinPrice }
                inputMaxPrice={ inputMaxPrice }
                setInputMaxPrice={ setInputMaxPrice }
                setCurrentPage={ setCurrentPage }
            />
            
            <TradingMarketContainer
                getDataForPage={ getDataForPage }
                itemData={ itemData }
                itemType={ currentNav }
                contentType={ contentType }
                currentPage={ currentPage }
                setCurrentPage={ setCurrentPage }
                total={ total }
                marketLoading={ marketLoading }
                pageSize={ pageSize }
            ></TradingMarketContainer>
        </div>
    );
}

