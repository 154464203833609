import React, { antdUI, $tools, useState, useEffect } from "react";
import { AWPColor } from "@/data/gradientColor";
import './gradient.scss'
function Gradient(props) {
  const { colorVal, setColorVal,asHashName } = props
  const [colorNumber, setColorNumber] = useState();
  const [selectOptions, setSelectOptions] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [minGradient, setMinGradient] = useState();
  const [maxGradient, setMaxGradient] = useState();

  const { defaultFormat, customizeFormat } = $tools;

  const gradientChange = (val) => {
    if (val === "customize") {
      setIsModalVisible(true);
    } else {
      setColorNumber(val)
      setColorVal(val);
    }
  };

  const setCustomizeVal = () => {
    if (!minGradient || !maxGradient) {
      antdUI.message.warning("请正确输入渐变区间");
      setColorNumber('');
      return;
    }
    if (minGradient > maxGradient) {
      antdUI.message.warning("请正确输入渐变区间");
      setMaxGradient('')
      setMinGradient('')
      setColorNumber('')
      return
    }
    const res = customizeFormat({ min: minGradient, max: maxGradient, target: AWPColor })
    setColorNumber(`${minGradient}%-${maxGradient}%`)
    setIsModalVisible(false)
    setColorVal(res)
  }

  const cusFilter = (val, type) => {
    let num;
    if (val < 79) {
      num = 79
    } else if (val > 100) {
      num = 100
    } else {
      return
    }
    type === 'min' ? setMinGradient(num) : setMaxGradient(num);
  }

  const resetColor = () => {
    setMaxGradient('')
    setMinGradient('')
  }

  useEffect(() => {
    if(asHashName.replace(/\(.*?\)/g, "").trim()==='AWP | Fade'){
      let selectArr = defaultFormat(AWPColor);
      selectArr.push({ label: "自定义", value: "customize" });
      setSelectOptions(selectArr);
    }else {
      setSelectOptions([])
    }
    // eslint-disable-next-line
  }, []);

  const { Select, Input, Button } = antdUI;
  const { Option } = Select;

  return (
    selectOptions.length!==0&&
    <div className='sort-tab-gradient'>
      <antdUI.Modal
        width="360px"
        maskClosable={false} // 点击蒙层是否可以关闭
        footer={false} // 是否需要按钮
        title="自定义渐变区间(%)"
        visible={isModalVisible}
        onCancel={(_) => { setIsModalVisible(false); setColorNumber(colorVal) }}
      >
        {/*<h1 className='gradient-title'>自定义渐变区间(%)</h1>*/}
        <div className='gradient-input'>
          <Input value={minGradient} onBlur={(e) => cusFilter(e.target.value, 'min')} onChange={(_) => setMinGradient(_.target.value)} style={{ width: "130px" }}></Input>-
          <Input value={maxGradient} onBlur={(e) => cusFilter(e.target.value, 'max')} onChange={(_) => setMaxGradient(_.target.value)} style={{ width: "130px" }}></Input>
        </div>
        <div className='gradient-btn'>
          <Button className='gradient-btn-cancel' onClick={() => resetColor()} style={{ width: '140px' }}>重置</Button>
          <Button className='gradient-btn-submit' onClick={() => setCustomizeVal()} style={{ width: '140px' }}>确认</Button>
        </div>
      </antdUI.Modal>
      <Select
        size="small"
        allowClear
        value={colorNumber}
        style={{ width: "120px" }}
        placeholder="渐变程度"
        onChange={gradientChange}
      >
        {selectOptions.map((item, index) => {
          return (
            <Option value={item.value} key={index}>
              {item.label}
            </Option>
          );
        })}
      </Select>
    </div>
  );
}

export default Gradient;
