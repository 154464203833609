import React, { $api, $CodeLogin, $tools, antdUI, useState } from 'react';
import { connect } from 'react-redux';
import { setUserSignIn } from '@/store/actions/userInfo';

function SecuritySettings(props) {
    const { userInfo, setUserSignIn } = props
    const [ changeType, setChangeType ] = useState('device')
    const [ visible, setVisible ] = useState(false);
    const [ addVisible, setAddVisible ] = useState(false);
    const [ unlockModal, setUnlockModal ] = useState(false);
    const [ currentProvince, setCurrentProvince ] = useState();
    const [ currentCity, setCurrentCity ] = useState();
    const [ phoneCode, setPhoneCode ] = useState();
    const [ codeLoa, setCodeLoa ] = useState(false);
    const [ btnText, setBtnText ] = useState('发送验证码');
    const { checkResult, inputValueLimit, debounce } = $tools
    const signOut = async (index) => {
        if (userInfo?.loginType !== 'code') {
            antdUI.message.warning('您必须使用手机验证码登录，才可进行当前操作！')
            return $CodeLogin()
        }
        let result = await $api.user.signOut({ index })
        if (checkResult(result)) {
            antdUI.message.success('登出成功');
            setUserSignIn()
        }
    }
    const sendCode = async codeType => {
        if (btnText !== '发送验证码') return
        let countdown = 60,
            timer = null;
        setCodeLoa(true)
        let result = await $api.user.sendPhoneCode({ type: codeType });
        setCodeLoa(false)
        if (! checkResult(result)) return
        antdUI.message.success("发送成功");
        setBtnText(`重新发送(${ countdown }s)`);
        timer = setInterval(() => {
            countdown--
            if (countdown === 0) {
                clearInterval(timer);
                setBtnText('发送验证码');
            } else {
                setBtnText(`重新发送(${ countdown }s)`);
            }
        }, 1000)
    }
    
    const addCommonly = async () => {
        setAddVisible(true);
        let result = await $api.user.getIpcity()
        if (checkResult(result)) {
            let { city, province } = result.data
            setCurrentProvince(province)
            setCurrentCity(city)
        }
    }
    const saveFrequentlyCity = async () => {
        let result = await $api.user.saveFrequentlyCity({ phoneCode })
        if (checkResult(result)) {
            antdUI.message.success('保存成功！');
            setUserSignIn();
            setPhoneCode()
            setTimeout(() => setAddVisible(false), 300)
        }
    }
    const removeCity = async (city) => {
        if (userInfo?.loginType !== 'code') {
            antdUI.message.warning('您必须使用手机验证码登录，才可进行当前操作！')
            return $CodeLogin()
        }
        let result = await $api.user.removeFrequentlyCity({ city })
        if (checkResult(result)) {
            antdUI.message.success('移除成功！');
            setUserSignIn()
        }
    }
    
    const unlockPassLogin = async () => {
        let result = await $api.user.unlockPassLogin({ phoneCode })
        if (checkResult(result)) {
            antdUI.message.success('解锁成功！');
            setUserSignIn();
            setPhoneCode()
            setTimeout(() => setUnlockModal(false), 300)
        }
    }
    
    return (
        <div className="security-settings">
            <div className="title">
                <h1>安全设置</h1>
            </div>
            
            <div className="dividing-line"></div>
            <div className="online-device">
                <p style={ { width: '180px' } }>登录设备管理</p>
                <antdUI.Button
                    style={ { marginLeft: '70px' } } className="antd-button-sm" type="primary"
                    onClick={ () => {
                        setChangeType('device');
                        setVisible(true)
                    } }>查看
                </antdUI.Button>
            </div>
            
            {
                ! <>
                    <div className="dividing-line"></div>
                    <div className="commonly-used">
                        <p style={ { width: '180px' } }>常用地管理</p>
                        <antdUI.Button
                            style={ { marginLeft: '70px' } } className="antd-button-sm" type="primary"
                            onClick={ () => {
                                setChangeType('commonly');
                                setVisible(true)
                            } }>查看
                        </antdUI.Button>
                        
                        <antdUI.Button
                            style={ { marginLeft: '20px' } } className="antd-button-sm" type="primary"
                            onClick={ () => addCommonly() }>新增
                        </antdUI.Button>
                    </div>
                    <div className="dividing-line"></div>
                    <div className="commonly-used">
                        {
                            userInfo.passlocking === 1 ?
                                <>  <p style={ { width: '180px', color: 'red' } }>账户密码登录已锁定</p>
                                    <antdUI.Button
                                        style={ { marginLeft: '70px' } } className="antd-button-sm" type="primary"
                                        onClick={ () => setUnlockModal(true) }>解锁
                                    </antdUI.Button>
                                
                                </> : <p style={ { color: '#000' } }>账户密码登录未锁定</p>
                        }
                    </div>
                </>
            }
            
            <antdUI.Modal
                title={ changeType === 'device' ? "登录设备管理" : "常用地管理" } visible={ visible }
                onCancel={ () => setVisible(false) }
                okText="确认" bodyStyle={ { minHeight: '180px' } } cancelText="取消" maskClosable={ false }
                footer={ null }
            >
                {
                    changeType === 'device' ?
                        <div className="security-settings-modal-box">
                            { userInfo && userInfo.logindata && userInfo.logindata.map(((item, idx) => {
                                let aequipment
                                item.aequipment && item.aequipment.replace(/\((.*?)\)/, (node, key) => { aequipment = key })
                                aequipment || (aequipment = item.aequipment)
                                if (item.ip) {
                                    return <div key={ idx } className="security-list flex ait jtyspbe" style={ {
                                        marginBottom: '15px',
                                        padding: '5px 10px',
                                        boxShadow: "0px 0px 4px 3px #eee"
                                    } }>
                                        <div>
                                            <p style={ { color: item.native ? '#49a55d' : '#000' } }>
                                                { aequipment } { item.native ? '(本机)' : "" }</p>
                                            <p style={ { color: '#989898' } }>
                                                { item.province }{ item.city } &nbsp;
                                                {/* {item.ip}  */ }
                                                { item.signInTime }</p>
                                        </div>
                                        <antdUI.Button type="primary" onClick={ _ => signOut(idx) }>登出
                                        </antdUI.Button>
                                    </div>
                                } else {
                                    return ''
                                }
                            })) }
                        </div> : <div className="security-settings-modal-box">
                            { userInfo?.userCity?.[0] && userInfo.userCity.map(((item, idx) => {
                                if (item) {
                                    return <div key={ idx } className="security-list flex ait jtyspbe"
                                                style={ {
                                                    marginBottom: '15px',
                                                    padding: '5px 10px',
                                                    boxShadow: "0px 0px 4px 3px #eee"
                                                } }>
                                        <p>{ item }</p>
                                        <antdUI.Button type="primary" onClick={ _ => removeCity(item) }>移除</antdUI.Button>
                                    </div>
                                } else {
                                    return ''
                                }
                            })) }
                        </div>
                }
            </antdUI.Modal>
            
            
            <antdUI.Modal
                title="新增常用地" visible={ addVisible } onCancel={ () => setAddVisible(false) }
                onOk={ debounce(saveFrequentlyCity, 2000, true) }
                bodyStyle={ { minHeight: '180px' } } cancelText="取消" okText="保存常用地" maskClosable={ false }
            >
                <div>
                    <p style={ { textAlign: 'center' } }>当前常用地: { currentProvince } { currentCity }</p>
                    <p style={ { textAlign: 'center', color: '#989898', fontSize: '18px', marginTop: '8px' } }>向
                        &nbsp;{ userInfo.phone ? userInfo.phone : '' }&nbsp;
                        发送验证码</p>
                    <div style={ { width: '60%', margin: '20px auto' } }>
                        <antdUI.Input.Search
                            placeholder="请输入验证码"
                            value={ phoneCode }
                            onChange={ (e) => setPhoneCode(inputValueLimit(e.target.value, 6)) }
                            enterButton={ <antdUI.Button style={ { backgroundColor: "#2DAF61", color: "#fff" } }
                                                         loading={ codeLoa }>{ btnText }</antdUI.Button> }
                            size="large"
                            onSearch={ debounce(sendCode.bind(null, 'addCity'), 2000, true) }
                        />
                    </div>
                </div>
            </antdUI.Modal>
            
            
            <antdUI.Modal
                title="解锁密码登录" visible={ unlockModal } onCancel={ () => setUnlockModal(false) }
                onOk={ debounce(unlockPassLogin, 2000, true) }
                bodyStyle={ { minHeight: '180px' } } cancelText="取消" okText="解锁" maskClosable={ false }
            >
                <div>
                    <p style={ { textAlign: 'center', color: '#989898', fontSize: '18px', marginTop: '8px' } }>向
                        &nbsp;{ userInfo.phone ? userInfo.phone : '' }&nbsp;
                        发送验证码</p>
                    <div style={ { width: '60%', margin: '20px auto' } }>
                        <antdUI.Input.Search
                            placeholder="请输入验证码"
                            value={ phoneCode }
                            onChange={ (e) => setPhoneCode(inputValueLimit(e.target.value, 6)) }
                            enterButton={ <antdUI.Button style={ { backgroundColor: "#2DAF61", color: "#fff" } }
                                                         loading={ codeLoa }>{ btnText }</antdUI.Button> }
                            size="large"
                            onSearch={ debounce(sendCode.bind(null, 'unlockPass'), 2000, true) }
                        />
                    </div>
                </div>
            </antdUI.Modal>
        </div>
    )
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    },
    function mapDispatchToProps(disatch) {
        return {
            setUserSignIn: () => disatch(setUserSignIn())
        }
    }
)(SecuritySettings);
