function statciFormat(target) {
  let data = []
  for (const key in target) { data.push({ [key]: target[key] }) }
  data.sort((a, b) => {
    a = Number(Object.values(a)[0].replace('%', ''))
    b = Number(Object.values(b)[0].replace('%', ''))
    return a - b
  })
  return data
}

function defaultFormat(target) {
  let data = statciFormat(target);
  let children = [], label = ['80%-90%', '90%-93%', '93%-95%', '95%-97%', '97%-99%', '99%-100%'];
  let list = [], val;
  data.forEach(_ => {
    val = Number(Object.values(_)[0].replace('%', ''))
    if (val >= 80 && val < 90) { list[0] += `${list[0] && ','}${Object.keys(_)[0]}` }
    if (val >= 90 && val < 93) { list[1] += `${list[1] && ','}${Object.keys(_)[0]}` }
    if (val >= 93 && val < 95) { list[2] += `${list[2] && ','}${Object.keys(_)[0]}` }
    if (val >= 95 && val < 97) { list[3] += `${list[3] && ','}${Object.keys(_)[0]}` }
    if (val >= 97 && val < 99) { list[4] += `${list[4] && ','}${Object.keys(_)[0]}` }
    if (val >= 99 && val <= 100) { list[5] += `${list[5] && ','}${Object.keys(_)[0]}` }
  })
  list.forEach((item, index) => {
    let value = JSON.stringify(item.replace(/[a-z]/g, '').split(',').map(Number));
    children.push({ label: label[index], value })
  })
  return children;
}

function customizeFormat({ min, max, target: data }) {
  let target = statciFormat(data)
  let result = '', vale;
  target.forEach(_ => {
    vale = Number(Object.values(_)[0].replace('%', ''))
    if (vale >= min && vale <= max) {
      result += `${result && ','}${Object.keys(_)[0]}`
    }
  })
  return JSON.stringify(result.split(',').map(Number))
}

export default {
  defaultFormat, customizeFormat
}
