import React, { antdUI, useEffect, useState, $tools } from 'react';
import './scss/transactionRecord.scss'

function TransRecords(props) {
    const { dataSource, tradeLoading, total, curPage, filterPage, curExId } = props;
    
    const [ tradeData, setTradeData ] = useState([]);
    
    const paginationProps = {
        position: [ "bottomCenter" ],
        pageSize: 10,
        current: curPage,
        total: total, //数据的总的条数
        onChange: (current) => filterPage(curExId, current), //点击当前页码
    };
    
    function initData() {
        let data = [];
        dataSource.forEach((item, index) => {
            let { icon_url, market_name, name, floatvalue, sellingPrice, endtime } = item;
            data.push({
                key: index,
                name: (
                    <div className='shop-items flex ait' style={ { width: 320 } }>
                        <div className='bg-icon' style={ { width: 120 } }>
                            <img src={ $tools.BASE_STEAM_IMG + icon_url } alt={ name }/>
                        </div>
                        <div style={ { display: "inline-block" } }>
                            <p style={ { textAlign: "left" } }>{ market_name }</p>
                            { floatvalue ? <p style={ { textAlign: "left" } }>磨损：{ floatvalue }</p> : "" }
                        </div>
                    </div>
                ),
                type: '出售',
                price: <div className='price'>￥<span className='price-num'>{ sellingPrice }</span></div>,
                time: <p className='end-time'>{ endtime }</p>
            })
        });
        setTradeData(data);
    }
    
    useEffect(() => {
        if (dataSource) {
            initData();
        }
        // eslint-disable-next-line
    }, [ dataSource ])
    
    return (
        <div className="buying-container">
            <antdUI.Table
                loading={ tradeLoading }
                pagination={ paginationProps }
                dataSource={ tradeData }
                columns={ columns }
            ></antdUI.Table>
        </div>
    );
}

let titleStyle = { textAlign: "center", fontWeight: 900, fontSize: "14px" };
const columns = [
    {
        title: <p style={ titleStyle }>饰品名称</p>,
        dataIndex: "name",
        key: "name",
        align: "center",
        width: '40%'
    },
    {
        title: <p style={ titleStyle }>类型</p>,
        dataIndex: "type",
        key: "type",
        align: "center",
        width: '20%'
    },
    {
        title: <p style={ titleStyle }>价格</p>,
        dataIndex: "price",
        key: "price",
        align: "center",
        width: '20%'
    },
    {
        title: <p style={ titleStyle }>时间</p>,
        dataIndex: "time",
        key: "time",
        align: "center",
        width: '20%'
    },
];
export default TransRecords;
