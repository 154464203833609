import React from "react";
import "./FilterImage.scss";
import { dota2 } from "@/data/classification.js";
function FilterImage(props) {
  const {
    barHeroHeadName,
    setBarHeroHeadName,
    headName,
    changeHero,
    heroCurrIdx,
    index,
    setHeroName,
    resetOther,
  } = props;

  const setBarName = (value, label) => {
    if (label === barHeroHeadName) {
      resetOther();
      setHeroName("");
    } else {
      setHeroName(value);
      setBarHeroHeadName(label);
      let rememberData = JSON.parse(localStorage.getItem('rememberFilterd2')) || {};
      if (headName === '力量') {
        rememberData.LbarHeroHeadName = label;
        rememberData.MbarHeroHeadName = '';
        rememberData.ZbarHeroHeadName = '';
      } else if (headName === '敏捷') {
        rememberData.MbarHeroHeadName = label;
        rememberData.LbarHeroHeadName = '';
        rememberData.ZbarHeroHeadName = '';
      } else if (headName === '智力') {
        rememberData.LbarHeroHeadName = '';
        rememberData.MbarHeroHeadName = '';
        rememberData.ZbarHeroHeadName = label;
      }

      localStorage.setItem('rememberFilterd2', JSON.stringify(rememberData));
    }
  };

  const handleSetClass = (_index) => {
    if (_index === heroCurrIdx[1]) {
      changeHero([]);
    } else {
      changeHero([index, _index]);
    }
  };

  return (
    <div className="image-container">
      <div className="image-head">
        <span
          className={[
            barHeroHeadName ? "image-head-text cur" : "image-head-text",
          ]}
        >
          {barHeroHeadName || headName}
        </span>
        <i className="item arrow-bottom"></i>
      </div>
      <div className="image-body">
        {dota2[0].children[index].children.map((item, i) => {
          let imageClass =
            index === heroCurrIdx[0]
              ? i === heroCurrIdx[1]
                ? "images active"
                : "images"
              : "images";
          return (
            <img
              alt={item.label}
              title={item.label}
              src={item.heroAvatar}
              key={i}
              className={imageClass}
              onClick={() => {
                // item.label
                setBarName(item.value, item.label);
                resetOther(headName);
                handleSetClass(i);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

export default FilterImage;
