import React, { antdUI, useState } from 'react';
import MobiLoginContainer from './loginContainer';
import MobiSignUp from './signUp'
import { connect } from 'react-redux';

function MobiLoginModal(props) {
    const { setLoginM } = props
    const [ timer, setTimer ] = useState(null);
    const [ phone, setPhone ] = useState('');
    const setTitle = (type) => {
        switch (type.toLowerCase()) {
            case 'signin':
                props.setLoginTitle('登录/注册');
                break;
            case 'signup':
                props.setLoginTitle('注册');
                break;
            case 'updatepass':
                props.setLoginTitle('忘记密码');
                break;
            default:
                break;
        }
    }
    // useEffect(() => { userInfo.phone && setPhone(userInfo.phone) }, [userInfo])
    return (
        <div className="mobi-login-modal">
            <antdUI.Modal
                title={ props.title }
                visible={ props.loginMo }
                footer={ false }
                onCancel={ () => {
                    clearInterval(timer)
                    setPhone('')
                    setLoginM(false)
                } }
                width="393px"
                maskClosable={ false }
                destroyOnClose={ true }
                centered={ true }
            >
                { (props.title === '登录/注册' || props.title === '手机验证码登录认证') &&
                    <MobiLoginContainer phone={ phone } type="signIn" title={ props.title } setLoginM={ setLoginM }
                                        setTitle={ setTitle }/> }
                { props.title === '注册' &&
                    <MobiSignUp setPhone={ setPhone } type="signUp" setLoginM={ setLoginM } setTimer={ setTimer }
                                setTitle={ setTitle }/> }
                { props.title === '忘记密码' &&
                    <MobiSignUp setPhone={ setPhone } type="updatePass" setTimer={ setTimer } setTitle={ setTitle }/> }
            </antdUI.Modal>
        </div>
    );
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    },
)(MobiLoginModal);
