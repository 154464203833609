import React, { $csgoWearColor } from "react";
import ItemWear from "@/components/itemContainer/itemWear";
import './supplyBox.scss'

function SupplyBox(props) {
    let { csData: v, style: csgo_st, selected: _sel, changeItem, currentGame } = props;
    const img_bs = "https://steamcommunity-a.akamaihd.net/economy/image/";
    const {
        sellingPrice,
        market_name,
        actions,
        icon_url,
        market_item_detail = {},
        tags
    } = v;
    
    const { stickers, paintseed, floatvalue } = market_item_detail || {};
    
    const exterior = tags.filter(item => item.category === 'Exterior')?.[0]?.internal_name;
    const quality = tags.filter(item => item.category === 'Quality')?.[0]?.internal_name;
    
    
    let quality_name, quality_color = '#b0c3d9';
    
    const { color: exterior_bcc, name: exterior_name } = $csgoWearColor(exterior)
    
    switch (quality) {
        case 'normal':
            quality_name = "普通";
            break;
        case 'strange':
            quality_name = 'StatTrak™';
            quality_color = 'rgb(207,106,50)';
            break;
        case 'tournament':
            quality_name = '纪念品';
            quality_color = 'rgb(255,215,0)';
            break;
        case 'unusual':
            quality_name = '★';
            quality_color = 'rgb(134, 80, 172)';
            break;
        case 'unusual_strange':
            quality_name = '★ StatTrak™';
            quality_color = 'rgb(134, 80, 172)';
            break;
        default:
            break;
    }
    
    return (
        <div style={ csgo_st } className="csgo-items csgo-item" onClick={ () => changeItem(v) }>
            {
                _sel && <div className='select-mask'>
                    <i className='iconfont icon-gou select'></i>
                </div>
            }
            <div
                className="_img"
                style={ {
                    backgroundImage: Number(currentGame) !== 730 && 'none',
                    padding: Number(currentGame) !== 730 && '0',
                    minHeight: Number(currentGame) !== 730 && '140px'
                } }
                onLoad={ (e) => (e.currentTarget.style.opacity = 1) }
            >
                <div className="item-quality">
                    { Number(currentGame) === 730 && <p
                        value={ quality }
                        style={ {
                            color: `#${ quality_color }`,
                            padding: "0px 6px",
                            height: " 20px",
                            lineHeight: "20px"
                        } }
                        className="item_quality"
                    >
                        { quality_name }
                    </p> }
                    { Boolean(paintseed) && <p className="item_paint_seed" style={ {
                        padding: "0px 6px",
                        height: " 20px",
                        lineHeight: "20px"
                    } }>{ paintseed }</p> }
                    { Number(currentGame) === 730 && <img
                        className="link-icon"
                        onClick={ () => window.open(`${ actions }`) }
                        src={ require("@/images/inventory/yanjing@2x.png") }
                        alt="actions"
                    /> }
                </div>
                
                { Boolean(exterior_bcc) && (
                    <div
                        style={ {
                            backgroundColor: exterior_bcc,
                            padding: "0px 6px",
                            height: " 20px",
                            lineHeight: "20px"
                        } }
                        className="subscript"
                        alt={ exterior }
                    >
                        { exterior_name }
                    </div>
                ) }
                
                { Number(currentGame) !== 730 ? <img
                    className="item-img"
                    style={ { transform: 'none' } }
                    src={ `${ img_bs + icon_url }` }
                    alt={ market_name }
                /> : <img
                    className="item-img"
                    src={ `${ img_bs + icon_url }` }
                    alt={ market_name }
                /> }
                
                { Boolean(floatvalue) && (
                    <div className="item-wear-container">
                        <ItemWear
                            wearValue={ floatvalue || 0.384765399 }
                            market_item_detail={ { msg: { stickers } } }
                        />
                    </div>
                ) }
                
                {/*{*/ }
                {/*  descriptions && <Rune descriptions={descriptions}></Rune>*/ }
                {/*}*/ }
            </div>
            { sellingPrice && (
                <p className="price">
                    <small>￥</small>
                    { parseInt(sellingPrice) }
                    <small>
                        { String(sellingPrice).indexOf(".") > 0 &&
                            String(sellingPrice).slice(String(sellingPrice).indexOf(".")) }
                    </small>
                </p>
            ) }
        </div>
    );
}

export default SupplyBox;
