import React from 'react';
import './index.scss'

const wearStyle = {
        position: 'static',
        padding: 0
    },
    pointerColor = {
        borderTopColor: '#fff'
    };

function Wear(props) {
    const wearData = React.$wearData1
    let { wearValue, type, size } = props;
    let pob = type === 'detailPopover' ? wearStyle : {},
        pib = type === 'detailPopover' ? pointerColor : {};
    return (
        <div className="item-wear" style={ { ...pob, bottom: size === 'mini' ? '3px' : "" } }>
            <h6 className="wear-value" style={ {
                transform: size === 'mini' && 'scale(0.7)',
                margin: size === 'mini' && '2px 0',
                whiteSpace: "nowrap"
            } }>磨损：{ String(wearValue).slice(0, 20) }</h6>
            <div className="wear-pointer">
                <i className="wear_pointer" style={ { left: `${ wearValue * 100 }%`, ...pib } }></i>
            </div>
            <div className="wear-show" style={ { height: size === 'mini' ? '4px' : "" } }>
                { wearData.map((item, index) => {
                    return <div className="wear_el" key={ index }
                                style={ { width: `${ item.ratio }%`, backgroundColor: item._bcc } }
                                title={ item.title }></div>
                }) }
            </div>
        </div>
    )
}


export default Wear;
