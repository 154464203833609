import React from 'react';
import CNav from './cNav';
import TopNav from './topNav';
import './sass/index.scss'

function HeadNav() {
  return (
    <div className="head-nav">
      <TopNav />
      <CNav />
    </div>
  );
}



export default HeadNav;