import React, { useImperativeHandle, useRef } from "react";
import "./index.scss";

import CsgoClsif from "./navContainer/csgo";
import Dota2Clsif from "./navContainer/dota2";

const navData = [
    // {
    //     title: "CSGO精选物品",
    //     icon: require("@/images/home/csgoweixuanzhong@2x.png"),
    //     select_icon: require("@/images/home/csgoxuanzhong@2x.png"),
    //     type: "733",
    // },
    {
        title: "CSGO饰品",
        icon: require("@/images/home/csgoweixuanzhong@2x.png"),
        select_icon: require("@/images/home/csgoxuanzhong@2x.png"),
        type: "730",
    },
    // {
    //     title: "DOTA2饰品",
    //     icon: require("@/images/home/dota2.png"),
    //     select_icon: require("@/images/home/dota2c.png"),
    //     type: "570",
    // },
];

function TradingMarketNav(props) {
    const {
        currentNav,
        setNav,
        filterChange,
        setCurrentPage,
        cRef,
        setContentType,
        contentType,
        inputMaxPrice,
        inputMinPrice,
        setInputMaxPrice,
        setInputMinPrice
    } = props;
    const childRef = useRef();
    
    useImperativeHandle(cRef, () => ({
        getData: childRef?.current?.getData,
    }));
    
    const renderContent = () => {
        switch (currentNav) {
            case "730":
                return (
                    <CsgoClsif
                        contentType={ contentType }
                        setContentType={ setContentType }
                        cRef={ childRef }
                        inputMaxPrice={ inputMaxPrice }
                        inputMinPrice={ inputMinPrice }
                        setInputMaxPrice={ setInputMaxPrice }
                        setInputMinPrice={ setInputMinPrice }
                        setCurrentPage={ setCurrentPage }
                        filterChange={ filterChange }
                    />
                );
            case "570":
                return (
                    <Dota2Clsif
                        cRef={ childRef }
                        setCurrentPage={ setCurrentPage }
                        filterChange={ filterChange }
                        inputMaxPrice={ inputMaxPrice }
                        inputMinPrice={ inputMinPrice }
                        setInputMaxPrice={ setInputMaxPrice }
                        setInputMinPrice={ setInputMinPrice }
                    />
                );
            default:
                break;
        }
    };
    
    return (
        <div className="trading-market-nav">
            <div className="market-nav-container">
                { navData.map((v, i) => {
                    let _cur = v.type === currentNav,
                        _cn = _cur ? "nav-item current" : "nav-item";
                    return (
                        <div
                            key={ i }
                            onClick={ () => {
                                setNav(v.type);
                                localStorage.setItem("curNav", v.type);
                                if (v.type === "730") {
                                    localStorage.removeItem('rememberFilterd2')
                                } else {
                                    localStorage.removeItem('rememberFilter')
                                }
                            } }
                            className={ _cn }
                        >
                            <img
                                className="market-nav-icon"
                                src={ _cur ? v.select_icon : v.icon }
                                alt=""
                            />
                            { v.title }
                        </div>
                    );
                }) }
            </div>
            <div className="market-clsif-container">{ renderContent() }</div>
        </div>
    );
}

export default TradingMarketNav;
