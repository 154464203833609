import React, {
    $api,
    $bindSteamApiKey,
    $bindSteamLink,
    $login,
    $toBindSteam,
    $tools,
    antdUI,
    useEffect,
    useRef,
    useState
} from 'react';
import './index.scss';
import { CsgoFilter, Dota2Filter } from '@/components/itemFilter';
import OnShelfContainer from '@/components/onShelf/onShelf'
import OrderContainer from './orderContainer'
import HandleOffer from '@/components/handleOffer'
import { connect } from "react-redux";

function SaleContainer(props) {
    const { userInfo } = props
    const { getUrlQueryValue } = $tools
    const game = getUrlQueryValue('game') || '730'
    const nav = getUrlQueryValue('nav') || 'onSale'
    const saleNav = getUrlQueryValue('saleNav') || 'sale'
    const [ currentGame, setGame ] = useState(game),
        [ currentNav ] = useState(nav),
        [ headCls, setHadCls ] = useState('sale-container-head'),
        [ onSaleCurNav ] = useState(saleNav),
        [ _onShelfData, set_onShelfData ] = useState([]),
        [ saleData, setSaleData ] = useState(''),
        [ cancelChangePrice, setCancelChangePrice ] = useState(true), // 用于关闭改价窗口恢复选中数据
        [ modalShow, setModalShow ] = useState(false),
        [ currentPage, setCurrentPage ] = useState(1),
        [ total, setTotal ] = useState(0),
        [ saleDataLoading, setSaleDataLoading ] = useState(true),
        [ offLoading, setOffLoading ] = useState(false),
        [ selectAllCur, setSelectAllCur ] = useState(false),
        [ pageSize ] = useState((saleNav === 'ship' || nav === 'record' || nav === 'intermediary') ? 10 : 50),
        [ init, setInit ] = useState(false),
        [ trandOffer, setTrandOffer ] = useState([]);
    
    const [ allRefPrice, setAllRefPrice ] = useState(0)
    const [ allSellingPrice, setAllSellingPrice ] = useState(0)
    
    const { checkResult, debounce } = $tools;
    const childRef = useRef();
    
    const getOffer = async () => {
        setTrandOffer([])
        if (Number(currentGame) !== 730) return
        setOffLoading(true)
        const result = await $api.tradeoffer.getSellerPendingOffer();
        if (checkResult(result)) {
            setTrandOffer(result.data)
        }
        setOffLoading(false)
    }
    const selectAll = () => {
        if (saleData.filter(_ => _.orderItemsStatus === 5).toString() === _onShelfData.toString()) {
            setSelectAllCur(false)
            set_onShelfData([]);
        } else {
            setSelectAllCur(true)
            set_onShelfData(JSON.parse(JSON.stringify((saleData.filter(_ => _.orderItemsStatus === 5)))))
        }
    }
    const getData = async (config = {}) => {
        setSaleData('');
        setTotal(0);
        setAllSellingPrice(0)
        setAllRefPrice(0)
        let {
            currentPage: _currentPage,
            searchContent,
            itemType,
            rarity,
            exterior,
            quality,
            collection,
            hero
        } = config;
        _currentPage = _currentPage || currentPage;
        let op = {
            appid: currentGame, curPage: _currentPage,
            type: itemType?.[0], weapon: itemType?.[1],
            rarity: rarity?.[0] !== 'unlimited' ? rarity?.[0] === 'Type_CustomPlayer'
                ? rarity?.[1] : rarity?.[0] : '',
            exterior: exterior?.[0] !== 'unlimited' ? exterior?.[0] : '',
            quality: quality?.[0] !== 'unlimited' ? quality?.[0] : '',
            pageSize, searchContent, orderType: currentNav,
            orderItemsStatus: onSaleCurNav,
            hero: hero && hero[1] && hero[1],
            itemSet: (collection && collection[1]) ? collection[1] : ''
        }
        if (currentNav === 'record') delete op.orderItemsStatus
        let result = await $api.putShelf.getOrderData(op);
        if (checkResult(result)) {
            let { orderData, total, allSellingPrice, allRefPrice } = result.data;
            setTotal(total)
            setSaleData(orderData)
            setAllSellingPrice(allSellingPrice)
            setAllRefPrice(allRefPrice)
        } else {
            setSaleData([ 'no' ])
        }
    }
    const getSale = (currentPage) => {
        setCurrentPage(currentPage)
        childRef.current.getData(currentPage)
    }
    const changeHandleItems = async (type) => {
        switch (type) {
            case 'changePrice':
                if (! userInfo?.steam?.steamid && ! userInfo.uid) return $login() // 没登录
                if (userInfo?.steam?.steamid && ! userInfo.uid) return window.location.href = '/profile/userConfig#login'
                if (! userInfo?.steam?.steamid && userInfo.uid) return $toBindSteam()
                // if (userInfo.real_name_status !== 1) return $toReal()
                if (! userInfo?.steam?.transactionLink) return $bindSteamLink()
                if (! userInfo?.steam?.apiKey) return $bindSteamApiKey()
                if (_onShelfData.length) {
                    setModalShow(true)
                } else {
                    antdUI.message.warning('请先勾选需要改价的物品')
                }
                break;
            case 'offShelf':
                if (_onShelfData.length) {
                    // const cacheOp = { "730": "csgoInventoryCache", "570": "dota2InventoryCache" }
                    // let cacheData = localStorage.getItem(cacheOp[currentGame])
                    const orderNumber = _onShelfData.map(item => item.orderNumber);
                    const result = await $api.putShelf.onSaleOffShelf({ appid: currentGame, orderNumber })
                    if (checkResult(result)) {
                        // if (cacheData) {
                        //     cacheData = JSON.parse(cacheData)
                        //     cacheData.forEach(item => {
                        //         if (_onShelfData.map(item => item.assetid).includes(item.assetid)) {
                        //             item.putShelf = 0
                        //         }
                        //     })
                        //     localStorage.setItem(cacheOp[currentGame], JSON.stringify(cacheData))
                        // }
                        
                        antdUI.message.success('下架成功');
                        set_onShelfData([]);
                        setSelectAllCur(false)
                        childRef.current.getData(currentPage);
                    }
                } else {
                    antdUI.message.warning('请先勾选需要下价的物品')
                }
                break;
            default:
                break;
        }
    }
    useEffect(() => {
        if (! saleData) {
            setSaleDataLoading(true)
        } else {
            setSaleDataLoading(false)
        }
    }, [ saleData ])
    
    useEffect(() => {
        if (! init) getData().then()
        // currentGame !== 0 && getData()
        setInit(true)
        if (currentNav === 'onSale') {
            setSaleData('')
            setHadCls('sale-container-head on-sale')
        } else {
            setHadCls('sale-container-head')
        }
        if (currentNav === 'onSale' && onSaleCurNav === "ship") {
            getOffer().then()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ currentGame, currentNav, onSaleCurNav ])
    
    return (
        <div className="my-sale-container">
            
            { ! offLoading ? trandOffer[0] && currentNav === 'onSale' && saleNav === 'ship' &&
                <HandleOffer type="seller" trandOffer={ trandOffer }/> :
                <antdUI.Skeleton active/> }
            <div className={ headCls }
                 style={ { marginTop: (offLoading || trandOffer[0]) && currentNav === 'onSale' && '40px' } }>
                <div className="item-filter" style={ { padding: '6px 0 22px 0' } }>
                    { (currentNav === 'onSale' || currentNav === 'record') &&
                        (currentGame === '730' ?
                            <CsgoFilter type={ onSaleCurNav } cRef={ childRef } filterChange={ getData }/> :
                            currentGame === '570' ?
                                <Dota2Filter type={ onSaleCurNav }
                                             cRef={ childRef } filterChange={ getData }/> : '')
                    }
                </div>
                { currentNav === 'onSale' &&
                    <>
                        <div className="on-sale-nav">
                            <span onClick={ _ => {
                                window.location.href = `/mySale?game=${ currentGame }&nav=${ currentNav }&saleNav=sale`
                                // setOnSaleCurNav('sale'); setCurrentPage(1); setPageSize(50)
                            } } className={ onSaleCurNav === 'sale' ? 'btn' : 'active' }>自售中</span>
                            <span onClick={ _ => {
                                window.location.href = `/mySale?game=${ currentGame }&nav=${ currentNav }&saleNav=ship`
                                // setOnSaleCurNav('ship'); setCurrentPage(1); setPageSize(6)
                            } } className={ onSaleCurNav === 'ship' ? 'btn' : 'active' }>等待发货</span>
                            { onSaleCurNav === 'sale' &&
                                <div className="allPrice-title flex ait" style={ { gap: 20, paddingLeft: 20 } }>
                                    <span>共<span style={ { color: "orange", margin: 0 } }>{ total }</span>件</span>
                                    { Number(allSellingPrice) > 0 && <span>总价值：<span
                                        style={ { color: "orange", margin: 0 } }>¥{ allSellingPrice }</span></span>
                                    }
                                    { Number(allRefPrice) > 0 && <span>总参考价：<span
                                        style={ { color: "orange", margin: 0 } }>¥{ allRefPrice }</span></span>
                                    }
                                </div>
                            }
                        
                        </div>
                        { onSaleCurNav === 'sale' && < div className="on-sale-handle-box">
                            <div className={ [ `selectAll ${ selectAllCur ? 'current' : '' }` ] }
                                 onClick={ _ => selectAll() }>
                                全选
                            </div>
                            <div className="btn"
                                 onClick={ debounce(changeHandleItems, 2000, true).bind(null, 'changePrice') }>
                                改价
                            </div>
                            <div className="btn"
                                 onClick={ debounce(changeHandleItems, 2000, true).bind(null, 'offShelf') }>
                                下架物品
                            </div>
                        </div> }
                    </>
                }
            </div>
            
            < antdUI.Spin tip="正在加载 ..."
                          style={ { margin: '150px auto 0 auto', width: '100%' } }
                          size="large" spinning={ saleDataLoading }/>
            
            { saleData && cancelChangePrice && <OrderContainer
                currentGame={ currentGame }
                onSaleCurNav={ onSaleCurNav } onShelfData={ _onShelfData }
                set_onShelfData={ set_onShelfData } currentNav={ currentNav }
                getData={ getData } saleData={ saleData }/> }
            <div className="sale-pagination">
                { total ? <antdUI.Pagination
                    onChange={ (currentPage, pageSize) => getSale(currentPage, pageSize) }
                    showSizeChanger={ false } current={ currentPage } defaultCurrent={ 1 } total={ total }
                    pageSize={ pageSize }/> : '' }
            </div>
            
            <antdUI.Modal
                title="改价"
                visible={ modalShow }  // 是否展示的布尔值
                footer={ false } // 是否需要按钮
                onCancel={ () => {
                    setModalShow(false);
                    set_onShelfData([]);
                    setCancelChangePrice(false);
                    setTimeout(() => setCancelChangePrice(true));
                } }
                maskClosable={ false }  // 点击蒙层是否可以关闭
                destroyOnClose={ true } // 关闭之后销毁子节点
                centered={ true } // 居中
                width="1100px"
            >
                <OnShelfContainer type="changePrice" setSelectAllCur={ setSelectAllCur } changePrice={ childRef }
                                  setGame={ setGame }
                                  set_onShelfData={ set_onShelfData }
                                  setModalShow={ setModalShow } onShelfType={ currentNav } currentGame={ currentGame }
                                  currentPage={ currentPage }
                                  onShelfData={ _onShelfData }/>
            </antdUI.Modal>
        
        </div>
    );
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    },
)(SaleContainer);
