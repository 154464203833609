import React from "react";

function Dota2Box(props) {
  let { style: st, item: v, selected: _sel, changeItem } = props,
    img_bs = "https://steamcommunity-a.akamaihd.net/economy/image/";

  const selectChange = () => {
    _sel ? changeItem(v, "cancel") : changeItem(v, "select");
  };


  return (
    <div style={st} onClick={selectChange} className="dota2-items">
      <div className="_img" onLoad={(e) => (e.currentTarget.style.opacity = 1)}>
        <div className="item-quality">
          {/*{quality && quality.color && <p style={{ color: `#${quality.color}` }} className="item_quality">{quality.localized_tag_name}</p>}*/}
        </div>
        <img src={`${img_bs + v.icon_url}`} alt={v.market_name} />
      </div>
      <p className="item-name">{v.market_name}</p>
      {(
        <p className="price">
          {v.price ? <><small>￥</small>{v.price}</> : ""}
          <small>
            {String(v.price).indexOf(".") > 0 &&
              String(v.price).slice(String(v.price).indexOf("."))}
          </small>
        </p>
      )}
      <div className={[`item-select ${_sel ? "selected" : ""}`]}>
        <i className="iconfont icon-hook-selected"></i>
      </div>
    </div>
  );
}

export default Dota2Box;
