import React, { antdUI } from "react";
import "./index.scss";
import CsgoItems from "./items/csgo";
import Dota2Items from "./items/dota2";


function ItemContainer(props) {
    let { itemType, itemData, type, contentType } = props;
    
    return (
        <div className="item-container">
            { itemData ? (
                itemData[0] !== "no" ? (
                    itemData === "loading" ||
                    itemData.map((v, i) => {
                        return Number(itemType) === 570 ? (
                            <Dota2Items
                                type={ type }
                                style={ { marginLeft: i % 5 ? "1.5%" : "" } }
                                key={ i }
                                item={ v }
                            />
                        ) : Number(itemType) === 730 ? (
                            <CsgoItems
                                contentType={ contentType }
                                type={ type }
                                style={ { marginLeft: i % 5 ? ".5%" : "" } }
                                key={ i }
                                item={ v }
                            />
                        ) : (
                            ""
                        );
                    })
                ) : (
                    <antdUI.Empty
                        description="暂无数据"
                        style={ { width: "100%", margin: "100px auto 0 auto" } }
                    />
                )
            ) : (
                ""
            ) }
        </div>
    );
}

export default ItemContainer;
