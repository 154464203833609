import { get, post } from '@/utils/request.js';
import BASE_URL from '../../utils/base_url'

export default {
    // steam 登录
    steamLogin() {
        return `${ BASE_URL.fetchUrl }/auth/steam`
    },
    
    // 设置steam交易链接
    upDateSteamTransactionLink(data) {
        return post({ url: '/steam/transactionLink/set/', data })
    },
    
    // 设置 API KEY
    upDateSteamApiKey(data) {
        return post({ url: '/steam/apiKey/set/', data })
    },
    
    // 获取steam库存
    getSteamInventory(data) {
        return post({ url: '/steam/inventory/', data })
    },
    
    // 获取供应库存
    getSupplySteamInventory(params) {
        return get({ url: '/steam/supply/inventory/', params })
    },
    
    // 获取单个物品磨损鉴定
    getMarketItemDetail(data) {
        return post({ url: '/steam/market_item_detail/', data })
    },
    
    // 获取csgo库存解锁时间
    getAssetClassInfo(data) {
        return post({ url: '/steam/get_asset_classInfo/', data })
    },
    
    
    updateSteamAvatar() {
        return get({ url: '/steam/updateAvatar/' })
    },
    
    steamBind() {
        return `${ BASE_URL.fetchUrl }/auth/steam`
    },
    
    texture3D(data) {
        return post({ url: 'https://api.mobi2077.com/steam/texture/', data })
    },
    
    // 获取单个物品监视图
    getMarketItemScreenshot(data) {
        return post({ url: 'https://api.mobi2077.com/steam/market_item_screenshot/', data })
    },
    
    againMapsViews(data) {
        return post({ url: '/steam/mapsViews/again/', data })
    },
    
    switchSteam(data) {
        return post({ url: '/steam/switch/', data })
    }
}
