import React, { antdUI, useState, $tools, useEffect } from 'react';
import AccessoriesNav from "./accessoriesLNav";
import AccessoriesContainer from "./accessoriesContainer";
import "./index.scss";

function AccessoriesList(props) {
  let { search, hash } = props.location;
  const [navData, setNavData] = useState({});
  const { strUrl2obj, obj2strUrl } = $tools;

  const [statisticalData, setStatisticalData] = useState([])
  const [pastDays, setPastDays] = useState(30)

  const searchData = strUrl2obj(search, '?');
  const hashData = strUrl2obj(hash, '#');

  const processFilterData = (config = {}) => {
    props.history.push({
      pathname: props.location.pathname,
      search: search,
      hash: obj2strUrl(config, '#')
    })
  }

  useEffect(() => {
    //当不存在id或者当在csgo市场下不存在curType时，返回到市场
    if (!searchData.id || !searchData.curGame || (searchData.curGame === '730' && !searchData.curType)) {
      props.history.push({
        pathname: '/marketPlace'
      })
      antdUI.message.warning("无效的链接！返回至交易市场！");
    }
  }, [searchData.id, searchData.curType, props.history, searchData.curGame])


  return (
    <div className="accessories-container">
      <img
        className="accessories-banner"
        src={require("@/images/tradingMarket/banner2@2x.png")}
        alt=""
      />
      <AccessoriesNav statisticalData={statisticalData} navData={navData} setPastDays={setPastDays}
        pastDays={pastDays}
        curExId={searchData.id} curGame={searchData.curGame}></AccessoriesNav>
      <AccessoriesContainer setStatisticalData={setStatisticalData} navData={navData}
        statisticalData={statisticalData} hash={hash}
        pastDays={pastDays} setPastDays={setPastDays}
        processFilterData={processFilterData} searchData={searchData}
        hashData={hashData} curExId={searchData.id}
        setNavData={setNavData} history={props.history} ></AccessoriesContainer>
    </div>
  );
}
export default AccessoriesList;
