import React, { useEffect, useState } from "react";
import "./index.scss";
function Rune(props) {
  const { descriptions } = props;
  const [gems, setGems] = useState();

  const takeOut = (arr) => {
    let str = "";
    const egx = /url\((\S+)\)/g;
    arr.forEach((item) => {
      item.type === "html" && (str += item.value);
    });
    str = str.replace(/<p>(.*?)<\/p>/g, "");
    let result = [];
    str.replace(egx, (a, b) => {
      if (b.match("https://steamcdn-a.akamaihd.net/apps/570/icons")) {
        result.push(b);
      }
    });
    setGems(result);
  };

  useEffect(() => {
    takeOut(descriptions);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="sale-gem-container">
      {gems?.[0] && (
        <div className="sale-gem-unit-c">
          {gems?.[0] &&
            gems.map((item, index) => {
              return (
                <div key={index} className="sale-gem-unit">
                  <img style={{ filter: "contrast(0.756504)" }} src={item} alt="" />
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}

export default Rune;
