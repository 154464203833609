import React from 'react';
import OfferTopMessage from './topMessage';
import ListItem from './listitem'
import './index.scss';

function HandleOffer(props) {
    const { type, trandOffer } = props
    return (
        <div className="handle-offer-box">
            <OfferTopMessage/>
            <div className="offs-container">
                { trandOffer.map((item, index) => {
                    return <ListItem type={ type } offData={ item } key={ index }/>
                }) }
            </div>
        </div>
    )
}

export default HandleOffer
