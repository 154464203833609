import React, { antdUI, $tools, useRef, useState } from 'react';
import { csgo } from '@/data/classification.js'
import './wearFilter.scss'


const exteriorList = csgo[2].children


function WearFilter(props) {
    const { minWear, setMinWear, maxWear, setMaxWear, floatValFilter, exterior } = props
    const { debounce } = $tools
    
    const { Select, Modal, Input } = antdUI;
    const { Option } = Select;
    
    const [ cusDialog, setCusDialog ] = useState(false);
    // eslint-disable-next-line no-mixed-operators
    const [ wearVal, setWearVal ] = useState(minWear && maxWear && `${ minWear }-${ maxWear }` || undefined);
    
    function getPreList() {
        if (exterior) {
            return exteriorList.filter(item => {
                return item.value === exterior
            })?.[0]?.range
        }
    }
    
    function handleOk() {
        const minValue = JSON.parse(getPreList()?.[1].value)?.[0];
        const maxValue = JSON.parse(getPreList()?.[5].value)?.[1];
        if (minWear < minValue || minWear >= maxValue) {
            antdUI.message.warning('格式不正确！');
            setMinWear(minValue);
            return
        }
        
        if (maxWear > maxValue || maxWear <= minValue) {
            antdUI.message.warning('格式不正确!');
            setMaxWear(maxValue);
            return;
        }
        delayFvFilter(minWear, maxWear);
        setCusDialog(false);
    }
    
    function handleCancel() {
        setCusDialog(false)
    }
    
    const handleChange = (value) => {
        setWearVal(value)
        if (! value) {
            setMinWear('')
            setMaxWear('')
            delayFvFilter(0, 0);
            return;
        }
        if (value === 'unlimited') {
            setMinWear('')
            setMaxWear('')
            delayFvFilter(0, 0);
            return;
        } else if (value !== 'customize') {
            const val = JSON.parse(value)
            setMinWear(val?.[0])
            setMaxWear(val?.[1])
            delayFvFilter(val?.[0], val?.[1])
        } else {
            setCusDialog(true)
        }
        
    }
    
    const delayFvFilter = useRef(debounce(floatValFilter, 1000, true)).current
    const getPreListData = getPreList()
    return (
        <div style={ { display: 'flex', alignItems: 'center' } }>
            { getPreListData?.[0] && <Select
                size="small"
                allowClear={ true }
                placeholder="磨损区间"
                value={ wearVal }
                style={ { width: 120 } }
                onSelect={ handleChange }
                onClear={ handleChange }
            >
                {
                    getPreListData.map(item => {
                        return <Option value={ item.value } key={ item.value }>{ item.label }</Option>
                    })
                }
            
            </Select> }
            
            <Modal
                title="自定义磨损区间"
                visible={ cusDialog }
                okText="确认"
                cancelText="取消"
                onOk={ handleOk }
                style={ { marginTop: '20vh' } }
                onCancel={ handleCancel }
            >
                <div>
                    <Input
                        placeholder="磨损"
                        value={ minWear }
                        onChange={ (_) => setMinWear(_.target.value) }
                        // size={"small"}
                        allowClear
                        style={ { width: "150px" } }
                    ></Input>
                    <span style={ { color: "black", margin: '0 5px' } }>—</span>
                    <Input
                        placeholder="磨损"
                        value={ maxWear }
                        allowClear
                        onChange={ (_) => setMaxWear(_.target.value) }
                        style={ { width: "150px" } }
                    ></Input>
                </div>
            </Modal>
        </div>
    )
}


export default WearFilter
