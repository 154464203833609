//渐变大理石 图案模板（paintseed）
//多普勒 皮肤编号(paintindex)
// 新增 格洛克 18 型 | 伽玛多普勒
const Doppler = {
    label: '多普勒',
    value: 'Doppler',
    children: [
        {
            label: '红宝石',
            value: 415,
            color: '#B7625F'
        },
        {
            label: '蓝宝石',
            value: 416,
            color: '#4773C8'
        },
        {
            label: '黑珍珠',
            value: 417,
            color: '#5C546C'
        },
        {
            label: 'P1',
            value: 418,
            color: '#959595'
        },
        {
            label: 'P2',
            value: 419,
            color: '#959595'
        },
        {
            label: 'P3',
            value: 420,
            color: '#959595'
        },
        {
            label: 'P4',
            value: 421,
            color: '#959595'
        }
    ]
};
const GammaDoppler = {
    label: '伽玛多普勒',
    value: 'Gamma Doppler',
    children: [
        {
            label: '绿宝石',
            value: "[568, 1119]",
            color: '#529F53'
        },
        {
            label: 'P1',
            value: "[569, 1120]",
            color: '#959595'
        },
        {
            label: 'P2',
            value: "[570, 1121]",
            color: '#959595'
        },
        {
            label: 'P3',
            value: "[571, 1122]",
            color: '#959595'
        },
        {
            label: 'P4',
            value: "[572, 1123]",
            color: '#959595'
        },
    ]
}
const _MarbleFade = {
    label: [ '爪子刀（★） | 渐变大理石 ', '刺刀（★） | 渐变大理石 ', '折叠刀（★） | 渐变大理石 ', '穿肠刀（★） | 渐变大理石 ' ],
    value: [ '★ Karambit | Marble Fade', '★ Bayonet | Marble Fade', '★ Flip Knife | Marble Fade', '★ Gut Knife | Marble Fade' ],
    children: [
        {
            label: '一档',
            value: `[412]`
        },
        {
            label: '二档',
            value: `[359, 393, 602, 649, 701, 16, 146, 241, 541, 688]`
        },
        {
            label: '三档',
            value: `[281, 673, 743, 292, 344, 994, 152, 777, 628, 792]`
        },
        {
            label: '四档',
            value: `[332, 129, 918, 126, 787, 874, 908, 923, 48, 780]`
        },
        {
            label: '五档',
            value: `[204, 252, 652, 736, 832, 705, 182, 457, 660, 685, 522, 578, 988]`
        },
        {
            label: '六档',
            value: `[230, 982, 607, 773, 444, 452, 471, 631, 356, 340, 621, 761, 112, 876, 873]`
        },
        {
            label: '七档',
            value: `[14, 274, 614, 653, 795, 826, 405, 454, 683, 728, 8, 32, 58, 108, 233, 732, 770, 803, 867, 949, 213, 243, 702]`
        },
        {
            label: '八档',
            value: `[337, 378, 188, 202, 971, 5, 178, 406, 539, 696, 854, 966, 655, 461]`
        },
        {
            label: '九档',
            value: `[493, 922, 959, 997, 68, 149, 171, 637, 287, 165, 206, 370, 656, 766, 817, 499, 516, 672, 706, 121]`
        },
        {
            label: '十档',
            value: `[238, 545, 591, 177, 546, 589, 977, 764, 844, 156, 868, 402, 28, 791, 553, 559, 725, 810, 858, 972]`
        },
    ]
}


const dopplerData = { Doppler, GammaDoppler, _MarbleFade }
export {
    Doppler, GammaDoppler, _MarbleFade, dopplerData
}
