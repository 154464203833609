import { get, post } from "@/utils/request.js";

export default {
    // 中介上架交易
    intermediaryListing(data) {
        return post({ url: "/putShelf/intermediaryListing/", data });
    },
    
    // 获取中介订单和自售物品数据
    getOrderData(params) {
        return get({ url: "/putShelf/order/", params });
    },
    
    // 自售进行上架物品
    putOnSaleItems(data) {
        return post({ url: "/putShelf/onSale/items/put/", data });
    },
    
    // 自售改价
    changeOnSalePrice(data) {
        return post({ url: "/putShelf/onSale/change/price/", data });
    },
    
    // 自售下架
    onSaleOffShelf(data) {
        return post({ url: "/putShelf/onSale/offShelf/", data });
    },
    // 下架物品   在库存里使用 自动下架功能
    putShelfData(params) {
        return get({ url: '/putShelf/onSale/data/', params })
    },
    
    // 终止求购
    stopBuyingOrder(data) {
        return post({ url: '/putShelf/buying/stop/', data })
    },
    // 发布求购
    publishBuyingOrder(data) {
        return post({ url: '/putShelf/buying/publish/', data })
    },
    
    processedOrder() {
        return post({ url: '/putShelf/processed/order/' })
    },
};
