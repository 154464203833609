import React, {
    antdUI,
    $tools,
    useState,
    useEffect,
    $api,
    $login,
    $toBindSteam,
    $bindSteamLink,
    $bindSteamApiKey
} from 'react';
import SupplyBox from './buying/supplyBox';
import "./scss/buying.scss"
import { connect } from 'react-redux';
import { Doppler, GammaDoppler } from '../../../../data/dopplerData';

const dopplerData = [ ...Doppler.children, ...GammaDoppler.children ];

function matchDoppler(value) {
    return dopplerData?.filter(item => item.label === value)?.[0] || '';
}

function Buying(props) {
    const { saleData, buyingLoading, userInfo, getDetailList, curExId, navData } = props;
    const [ dataSource, setDataSource ] = useState([]);
    const [ isInt, setIsInt ] = useState(false);
    const [ supplyDialog, setSupplyDialog ] = useState(false);
    const [ supplyData, setSupplyData ] = useState({});
    const [ stockData, setStockData ] = useState([]);
    const [ selectItems, setSelectItems ] = useState([]);
    const [ income, setIncome ] = useState(0);
    const [ totalHandleFee, setTotalHandleFee ] = useState(0);
    const [ itemTotal, setItemTotal ] = useState(0);
    const [ loading, setLoading ] = useState(false);
    const [ realNeedTotal, setRealNeedTotal ] = useState(0);
    const [ tipDialog, setTipDialog ] = useState(false);
    
    const openDialogHandle = async (data) => {
        if (! userInfo.steamid || ! userInfo.uid) {
            antdUI.message.warning('请先完善绑手机绑定和Steam绑定！');
            return;
        }
        // if (!userInfo.real_name_status) { antdUI.message.warning('请先完成实名认证！'); return; }
        if (! userInfo.steam?.transactionLink) {
            return antdUI.message.warning('请先绑定 steam 交易链接！');
        }
        if (! userInfo.steam?.apiKey) {
            return antdUI.message.warning('请先绑定 ApiKey!')
        }
        const { total, receiptTotal, buyingTotal } = data;
        setSupplyDialog(true);
        setSupplyData(data);
        setRealNeedTotal(total - receiptTotal - buyingTotal);
        setStockData([]);
        setSelectItems([]);
        setLoading(true);
        const res = await $api.steam.getSupplySteamInventory({
            market_name: data.market_name,
            itemStyle: data?.itemStyle
        })
        setLoading(false);
        if ($tools.checkResult(res)) {
            setItemTotal(res?.data.result.length);
            setStockData(res?.data.result)
        }
    }
    
    const supplyItemsHandle = async () => {
        const preData = JSON.parse(JSON.stringify(selectItems));
        if (preData.length === 0) {
            antdUI.message.warning('请选择供应的饰品!');
            return;
        }
        let postData = {
            itemData: [],
            buyerid: supplyData?.user?.uid,
            appid: 730, buyingid: supplyData.id
        }
        preData.forEach(item => {
            if (item.orderNumber && item.putShelf === 2) {
                postData.itemData.push({
                    orderNumber: item.orderNumber,
                    sellingPrice: supplyData?.unitPrice,
                })
            } else {
                const {
                    appid,
                    market_hash_name,
                    assetid,
                    classid,
                    actions,
                    instanceid,
                    market_name,
                    name,
                    icon_url,
                    descriptions,
                    customname
                } = item;
                const { floatvalue, paintindex, paintseed, stickers } = item?.market_item_detail || {};
                postData.itemData.push({
                    appid, market_hash_name, assetid, classid,
                    actions: actions?.[0]?.link, instanceid, market_name, name, icon_url,
                    descriptions: JSON.stringify(descriptions), customname, sellingPrice: supplyData?.unitPrice,
                    floatvalue, paintseed, paintindex, stickers: JSON.stringify(stickers)
                })
            }
        })
        const res = await $api.market.supplyItems(postData);
        if ($tools.checkResult(res)) {
            antdUI.message.success('供应成功!');
            setSupplyDialog(false);
            setTipDialog(true);
            getDetailList({ id: curExId, curPage: 1 });
        }
    }
    
    async function stopBuying(id) {
        const res = await $api.putShelf.stopBuyingOrder({ id });
        if ($tools.checkResult(res)) {
            antdUI.message.warning('已终止该求购');
            getDetailList({ id: curExId, curPage: 1 });
        }
    }
    
    const refreshHandle = async () => {
        setStockData([]);
        setLoading(true);
        setSelectItems([]);
        const res = await $api.steam.getSupplySteamInventory({
            market_name: supplyData.market_name,
            itemStyle: supplyData?.itemStyle
        })
        setLoading(false);
        if ($tools.checkResult(res)) {
            setItemTotal(res?.data.result.length);
            setStockData(res?.data.result)
        }
    }
    
    const createBuyingData = (uid) => {
        let arr = [];
        saleData.forEach((item, _index) => {
            const {
                id, receiptTotal, buyingTotal,
                icon_url, unitPrice, total, userInfo: _userInfo,
                market_name, user, UserSteam, itemStyle,
            } = item;
            arr.push({
                name: (
                    <div className='acc-item'>
                        <div className="goods-icon">
                            <img
                                src={ navData.img_name ? `${ React.StaticBaseUrl }/market-csgo/${ navData.img_name }.png`
                                    : $tools.BASE_STEAM_IMG + icon_url }
                                alt={ "饰品" }
                            />
                        </div>
                        <div>
                            <p className='text-ov1'>{ market_name }</p>
                            { matchDoppler(itemStyle) && <div className='style-name'
                                                              style={ { backgroundColor: matchDoppler(itemStyle).color } }>{ matchDoppler(itemStyle).label }</div> }
                        </div>
                    </div>
                ),
                price: (
                    <span className="feature-price">
            <span className="feature-price-icon">￥</span>
                        { unitPrice }
          </span>
                ),
                num: <p>{ total - receiptTotal - buyingTotal }</p>,
                buyer: (
                    <div>
                        <img
                            className="avatar"
                            src={ UserSteam?.avatarfull }
                            alt=""
                        />
                        <span className="seller">
              { user?.nickname }
            </span>
                        {
                            _userInfo?.onlyBuyerSendoffer ? <> <br/> <span className="seller">
                该卖家只支持买家发送报价
              </span></> : ''
                        }
                    </div>
                ),
                key: id,
                operating: (
                    item?.User?.uid !== uid ?
                        <antdUI.Button onClick={ () => {
                            if (! userInfo?.steam?.steamid && ! userInfo.uid) return $login() // 没登录
                            if (userInfo?.steam?.steamid && ! userInfo.uid) return window.location.href = '/profile/userConfig#login'
                            if (! userInfo?.steam?.steamid && userInfo.uid) return $toBindSteam()
                            // if (userInfo.real_name_status !== 1) return $toReal()
                            if (! userInfo?.steam?.transactionLink) return $bindSteamLink()
                            if (! userInfo?.steam?.apiKey) return $bindSteamApiKey()
                            openDialogHandle(item)
                        } } type='primary' style={ { width: '100px' } }>供应</antdUI.Button>
                        : <antdUI.Button onClick={ () => stopBuying(item.id) } type='primary'
                                         style={ { width: '100px' } }>终止求购</antdUI.Button>
                ),
            });
        });
        setDataSource(arr);
    }
    
    const changeItem = (cItem) => {
        let data = JSON.parse(JSON.stringify(selectItems));
        const findIndex = data.findIndex(item => item.assetid === cItem.assetid);
        if (findIndex === -1) {
            if (selectItems.length >= realNeedTotal) {
                antdUI.message.warning('超出最大供应需求!')
                return
            }
            data.push(cItem);
        } else {
            data.splice(findIndex, 1);
        }
        setSelectItems(data);
    }
    
    
    useEffect(() => {
        if (isInt) {
            createBuyingData(userInfo.uid);
        } else {
            setIsInt(true);
        }
        // eslint-disable-next-line
    }, [ saleData, isInt, userInfo, navData ])
    
    useEffect(() => {
        const sellingPrice = Number(supplyData?.unitPrice);
        const proportion = $tools.getHandlingFee(sellingPrice, userInfo?.reputationPoints);
        const actualPrice = Math.floor((sellingPrice - sellingPrice * proportion) * 100) / 100;
        setTotalHandleFee((sellingPrice - actualPrice) * selectItems.length);
        setIncome(actualPrice * selectItems.length);
    }, [ selectItems, supplyData, userInfo ])
    
    return (
        <div className="buying-container">
            <antdUI.Table
                pagination={ { position: [ "bottomCenter" ], showSizeChanger: false } }
                dataSource={ dataSource }
                columns={ columns }
                loading={ buyingLoading }
            ></antdUI.Table>
            
            <antdUI.Modal
                title='供应求购'
                visible={ supplyDialog }
                onCancel={ () => setSupplyDialog(false) }
                footer={ false } maskClosable={ false }
                width={ 685 }
            >
                <div className='supply-con'>
                    <div className='acc-info'>
                        <div className='icon-img'>
                            <img src={ $tools.BASE_STEAM_IMG + supplyData?.icon_url } alt=""/>
                        </div>
                        <div className='acc-name'>
                            <p>求购饰品 &nbsp; <span
                                style={ { fontSize: '18px', color: '#222222' } }>{ supplyData.market_name }</span></p>
                            <div>
                                <p>求购单价  &nbsp; |  &nbsp; <span>￥{ supplyData.unitPrice }</span></p>
                                <p style={ { marginLeft: '30px' } }>求购数量  &nbsp;| &nbsp;
                                    <span>{ realNeedTotal }</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='stock-con'>
                        <div className='stock-bar'>
                            <span style={ { borderBottom: '2px solid #2DAF61' } }>steam库存</span>
                            <span onClick={ refreshHandle } style={ { cursor: 'pointer' } }><img
                                style={ { width: '20px' } }
                                src={ require('../../../../images/tradingMarket/shuaxin@2x.png') } alt=""/>
                                &nbsp;
                                { selectItems.length }/{ itemTotal }</span>
                        </div>
                        {/*{*/ }
                        {/*  !loading && stockData?.[0] &&*/ }
                        {/*  <antdUI.Empty image={antdUI.Empty.PRESENTED_IMAGE_SIMPLE} />*/ }
                        {/*}*/ }
                        {
                            <antdUI.Spin spinning={ loading }>
                                <div className='stock-list'>
                                    {
                                        stockData.map((item, index) => {
                                            return <SupplyBox changeItem={ changeItem }
                                                              selected={ selectItems.findIndex(itm => itm.assetid === item.assetid) !== -1 }
                                                              currentGame={ 730 } key={ index } csData={ item }/>
                                        })
                                    }
                                </div>
                            </antdUI.Spin>
                        }
                    </div>
                    <div className='operating'>
                        <div className='settle-accounts'>
                            预计收入：<span>￥{ income.toFixed(2) || 0 }</span>(已扣除￥{ totalHandleFee.toFixed(2) }手续费)
                        </div>
                        <antdUI.Button onClick={ $tools.debounce(supplyItemsHandle, 2000, true) } type='primary'>确认供应
                        </antdUI.Button>
                    </div>
                </div>
            </antdUI.Modal>
            <antdUI.Modal
                title='提示'
                style={ { marginTop: '25vh' } }
                visible={ tipDialog }
                okText={ '确定' }
                cancelButtonProps={ { style: { display: 'none' } } }
                onOk={ () => setTipDialog(false) }
                width={ 400 }
            
            >
                <p>请前往MOBI APP发送报价</p>
            </antdUI.Modal>
        </div>
    );
}

export default connect(
    function mapStateToProps(state) {
        return {
            userAssets: state.user.userAssets,
            userInfo: state.user.userInfo,
        };
    }
)(Buying);


let titleStyle = { textAlign: "center", fontWeight: 900, fontSize: "14px" };
const columns = [
    {
        title: <p style={ titleStyle }>饰品名称</p>,
        dataIndex: "name",
        key: "name",
        width: '400px',
        align: "center",
    },
    {
        title: <p style={ titleStyle }>买家</p>,
        dataIndex: "buyer",
        key: "buyer",
        align: "center",
    },
    {
        title: <p style={ titleStyle }>数量</p>,
        dataIndex: "num",
        key: "num",
        align: "center",
    },
    {
        title: <p style={ titleStyle }>求购单价</p>,
        dataIndex: "price",
        key: "price",
        align: "center",
    },
    {
        title: <p style={ titleStyle }>操作</p>,
        dataIndex: "operating",
        key: "operating",
        align: "center",
    },
];
