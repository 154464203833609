import React, { $tools } from "react";
import { Link } from 'react-router-dom';

// import "./dota2.scss";

function Dota2Items(props) {
    const { obj2strUrl } = $tools
    let { style: st, item: v } = props,
        img_bs = "https://steamcommunity-a.akamaihd.net/economy/image/";
    
    return (
        <Link style={ st } className="dota2-items" to={
            {
                pathname: '/marketPlace/accessoriesList',
                search: obj2strUrl({
                    id: v.id,
                    curGame: '570'
                }, '?')
            }
        }>
            <div className="_img" onLoad={ (e) => (e.currentTarget.style.opacity = 1) }>
                <div className="item-quality">
                    {/*{quality && quality.color && <p style={{ color: `#${quality.color}` }} className="item_quality">{quality.localized_tag_name}</p>}*/ }
                </div>
                <img style={ { height: '100%' } } src={ `${ img_bs + v.icon_url }` } alt={ v.market_name }/>
            </div>
            <p className="item-name">{ v.market_name }</p>
            { (
                <p className="price">
                    { v.price ? <><small>￥</small>{ v.price }</> : '' }
                </p>
            ) }
            <div className="end-time">
                <p style={ { fontSize: '700' } }>{ v.saleCount || 0 }件在售</p>
            </div>
        </Link>
    );
}

export default Dota2Items;
