import React, { useState, antdUI, $api, $tools, useEffect } from 'react';
import QRCode from 'qrcode.react'
import './scss/recharge.scss';
import { connect } from "react-redux";
import { upDateUserAssets } from '@/store/actions/userInfo'

const typeData = [
    { type: 'zfb', icon: require('@/images/profile/zhifubao@2x.png'), tip: '单笔充值1-20000，0手续费' },
    // { type: 'wx', icon: require('@/images/profile/weixin@2x.png'), tip: "0手续费" },
    // { type: 'yl', icon: require('@/images/profile/yinlian@2x.png'), tip: "0手续费，单笔最高充值5000" },
    {
        type: 'xyhb',
        icon: require('@/images/profile/huaban@2x.png'),
        tip: "收取1%手续费，最低0.01元由第三方收取，该充值方式目前暂不支持退款"
    }
];
const amountData = [
    { amount: 50 }, { amount: 100 }, { amount: 200 }, { amount: 500 }, { amount: 1000 }
];

const promptData = [
    { text: '早鸟期，元旦，春季期间所有用户任意交易金额的交易费率统一低至0.75%，提现手续费低至0%', color: "orange" },
    { text: '充值消费小提示' },
    { text: '1.由于每笔充值，支付宝、微信等各服务商都会根据金额大小按比例收取手续费，所以退款会收取0.6%服务费（目前早鸟期不收取该费用）。' },
    { text: '2.单笔充值限额为1-20000元。' },
    { text: '3.按照反洗钱政策，用户充值金额只能用于网站购买，不能直接提现。用户出售所得为可提现余额，可以提现至用户支付宝或者银行储蓄卡。' },
    { text: "4.用户用于求购的金额，会在用户求购锁定余额中。" },
    { text: "5.违规资金将冻结处理。" },
    { text: "6.支付宝（余额、余额宝、储蓄卡）充值后的退款功能已经在PC端上线，可以到电脑网页个人中心-我的钱包-充值页面的充值记录中进行退款操作，退款有效时间为3天，超过3天的充值订单不支持退款。花呗信用卡充值不支持退款。" }
];

const iconGou = require('@/images/profile/gou@2x.png');

function Recharge(props) {
    const pageSize = 10
    const { upDateUserAssets, userInfo, userAssets } = props;
    const [ currentType, setType ] = useState('zfb'),
        [ currentAmount, setCurrentAmount ] = useState(),
        [ rechRecord, setRechRecord ] = useState({ total: 0, items: [] }),
        [ amount, setAmount ] = useState(""),
        [ xyhbVal, setXyhbVal ] = useState(0),
        [ qrCodeShow, setQrCodeShow ] = useState(false),
        [ qrVal, setQrVal ] = useState({}),
        [ orderEnd, setOrderEnd ] = useState(""),
        [ rechLoa, setRechLoa ] = useState(false),
        [ ylOrEndTimer, setYlOrEndTimer ] = useState(0),
        [ ylBtnLoa, setYlBtnLoa ] = useState(false),
        [ rRPage, setRRPage ] = useState(0),
        [ rRecord, setrRecord ] = useState(false),
        [ rRecordLoa, setrRecordLoa ] = useState(false);
    
    const [ refundCheckView, setRefundCheckView ] = useState(false)
    const [ refundData, setRefundData ] = useState({})
    const [ partialAmount, setPartialAmount ] = useState(0)
    
    function tabItem({
                         type,
                         text,
                         channel,
                         handlingfee,
                         tradeNumber,
                         actualAmount,
                         refundAmount,
                         deductionAmount,
                         refundTime,
                         isRefund
                     }) {
        let _st = { textAlign: 'center' }
        switch (type) {
            case 'amount':
                _st.color = '#2EB062';
                break;
            case 'state':
                switch (text) {
                    case 1:
                        if (isRefund) {
                            text = <>
                                <span>支付成功</span>
                                <antdUI.Button type="primary" style={ { marginLeft: 10 } } onClick={ _ => {
                                    if (Number(userAssets.alipay_amount) < 0.02) return antdUI.message.warning('当前余额不足！')
                                    setRefundCheckView(true)
                                    setRefundData({ tradeNumber, actualAmount })
                                    if (Number(userAssets.alipay_amount) < Number(actualAmount)) setPartialAmount(userAssets.alipay_amount)
                                } }>退款
                                </antdUI.Button>
                            </>;
                        } else {
                            text = "支付成功"
                        }
                        _st.color = '#2EB062';
                        break;
                    case 2:
                        text = '支付失败';
                        _st.color = `red`;
                        break;
                    case 3:
                        text = '';
                        text = <span>支付成功(已退款)
                            <antdUI.Popover placement="right" content={ <div className="apikey-box-text" style={ {
                                padding: '14px', width: '260px', backgroundColor: '#191919',
                                boxShadow: `0px 0px 6px 1px #191919`
                            } }>
                                <p className="title"
                                   style={ { fontSize: '14px', color: '#8c9298', marginBottom: '8px' } }>提示：</p>
                                <p style={ {
                                    color: '#fff',
                                    fontSize: '14px'
                                } }>退款到账金额: { refundAmount }</p>
                                { Math.round((deductionAmount - refundAmount) * 100) / 100 > 0 ? <p style={
                                    { color: '#fff', fontSize: '14px' }
                                }>退款手续费: { Math.round((deductionAmount - refundAmount) * 100) / 100 }</p> : "" }
                                <p style={
                                    { color: '#fff', fontSize: '14px' }
                                }>退款时间: { refundTime }</p>
                            </div> }>
                        <i style={ { marginLeft: 10, color: "#000" } } className="iconfont icon-wenhao"></i>
                    </antdUI.Popover>
                        </span>;
                        _st.color = `#989898`;
                        break;
                    case 4:
                        text = '等待支付';
                        break;
                    case 5:
                        text = '充值下单失败';
                        _st.color = `red`;
                        break;
                    case 6:
                        text = '退款中...';
                        _st.color = `#000`;
                        break;
                    case 7:
                        text = '退款失败';
                        _st.color = `red`;
                        break;
                    default:
                        text = '';
                        break;
                }
                break;
            case 'payType':
                switch (text) {
                    case 1:
                        text = '【支付宝】';
                        switch (channel) {
                            case "ALIPAYACCOUNT":
                                if (handlingfee <= 0) {
                                    text += "(支付宝账户)"  // 包含余额宝 银行卡等
                                } else {
                                    text += "(信用卡)"
                                }
                                break;
                            case "PCREDIT":
                                text += "(花呗)";
                                break;
                            default:
                                break;
                        }
                        
                        break;
                    case 2:
                        text = "【银联】";
                        switch (channel) {
                            case "ACP":
                                text += "(云闪付)";
                                break;
                            case "WXPay":
                                text += "(微信)";
                                break;
                            case "Alipay 2.0":
                                text += "(支付宝)";
                                break;
                            default:
                                break;
                        }
                        break;
                    case 3:
                        text = "【微信】";
                        switch (channel) {
                            case "NATIVE":
                                text += "(扫码支付)";
                                break;
                            case "APP":
                                text += "(APP支付)";
                                break;
                            case "Alipay 2.0":
                                text += "(支付宝)";
                                break;
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }
                break;
            case 'handlingFee':
                _st.color = '#FF9E40';
                break;
            default:
                break;
        }
        
        return <div style={ _st }>{ text }</div>
    }
    
    const columns = [
        {
            title: <p style={ { textAlign: 'center' } }>订单号</p>,
            dataIndex: 'tradeNumber',
            key: 'tradeNumber',
            render: text => tabItem({ text })
        },
        {
            title: <p style={ { textAlign: 'center' } }>充值到账金额(元)</p>,
            dataIndex: 'actualAmount',
            key: 'actualAmount',
            render: text => tabItem({ text, type: 'amount' })
        },
        {
            title: <p style={ { textAlign: 'center' } }>充值手续费(元)</p>,
            dataIndex: 'handlingfee',
            key: 'handlingfee',
            render: text => tabItem({ text, type: 'handlingFee' })
        },
        {
            title: <p style={ { textAlign: 'center' } }>创建时间</p>,
            dataIndex: 'createTime',
            key: 'createTime',
            render: text => tabItem({ text })
        },
        {
            title: <p style={ { textAlign: 'center' } }>付款方式</p>,
            dataIndex: 'payType',
            key: 'payType',
            render: (text, record) => tabItem({
                text,
                type: 'payType',
                channel: record.channel,
                handlingfee: record.handlingfee
            })
        },
        {
            title: <p style={ { textAlign: 'center' } }>进度</p>,
            dataIndex: 'state',
            key: 'state',
            render: (text, record, index) => tabItem({ text, type: 'state', index, ...record })
            
        }
    ];
    
    const sendAmount = async () => {
        if (userInfo?.steam?.steamid && ! userInfo.uid) return window.location.href = '/profile/userConfig#login'
        if (! Number(amount) || amount <= 0) return antdUI.message.warning('请输入正确金额');
        let result;
        setRechLoa(true);
        
        switch (currentType) {
            case 'zfb':
                result = await $api.pay.alipay({ amount, type: currentType });
                break;
            case 'wx':
                result = await $api.pay.wechatPay({ amount });
                break;
            case 'xyhb':
                result = await $api.pay.alipay({ amount: xyhbVal, type: currentType });
                break;
            default:
                break;
        }
        setRechLoa(false);
        if ($tools.checkResult(result)) {
            if (currentType === 'yl' || currentType === 'wx') {
                setQrVal(result.data);
                setQrCodeShow(true)
                setYlOrEndTimer(setTimeout(() => { setOrderEnd("订单超时已取消"); }, 300000))
            } else {
                window.location.href = result.data.result
            }
        }
    }
    
    const getPayRecord = async (page = 1) => {
        setRechRecord({ total: 0, items: [] })
        rRPage && (page = rRPage)
        const result = await $api.user.rechargeRecord({ page, pageSize })
        if ($tools.checkResult(result)) {
            setRechRecord({
                total: result.data.total, items: result.data.items.map((_, i) => {
                    _.key = i;
                    return _
                })
            })
        }
    }
    
    const ylCheckOrder = async () => {
        setYlBtnLoa(true)
        const fn = (tradeNumber) => {
            return new Promise((res) => {
                setTimeout(async () => {
                    let re = await $api.pay.wechatCheckOrder({ tradeNumber });
                    res(re)
                }, 1500)
            })
        }
        let result = await fn(qrVal.tradeNumber);
        setYlBtnLoa(false)
        if ($tools.checkResult(result)) {
            if (result.data && typeof result.data === 'string') antdUI.message.success(result.data)
            clearInterval(ylOrEndTimer);
            setYlOrEndTimer(null)
            setOrderEnd("充值成功，3秒后自动关闭弹窗");
            upDateUserAssets()
            setTimeout(() => {
                setQrCodeShow(false);
                clearInterval(ylOrEndTimer);
                setYlOrEndTimer(null);
                setOrderEnd("")
            }, 3000)
        }
    }
    
    const onChangeType = (e) => {
        setType(e.target.value)
    }
    
    useEffect(() => {
        if (rRPage >= 1) {
            setRechRecord({ total: rechRecord.total, items: [] })
            getPayRecord().then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ rRPage ])
    
    const cancelRefund = _ => {
        setRefundCheckView(false);
        setPartialAmount(0)
        setRefundData({})
    }
    
    return (
        <div className="recharge">
            <div className="recharge-amount">
                <p className="title">充值金额</p>
                <br/>
                <span
                    style={ { color: "orange" } }>建议在购买前先使用“在库检测”功能（网页端在饰品市场购买按钮下方；APP在点击购买后，确认付款页面的右上角），该功能可以检查该饰品是否仍在卖家库存，确定饰品在卖家库存后再进行充值购买。</span>
                <div className="amount-container">
                    <antdUI.Input
                        onChange={ (e => {
                            setCurrentAmount();
                            if (e.target.value === '') {
                                setAmount('');
                                setXyhbVal(Math.ceil(0 * 101) / 100)
                            } else {
                                const val = Number(e.target.value);
                                setAmount(val);
                                setXyhbVal(Math.ceil(val * 101) / 100)
                            }
                        }) }
                        onBlur={ (e) => {
                            setCurrentAmount();
                            if (e.target.value === '') {
                                setAmount('');
                                setXyhbVal(Math.ceil(0 * 101) / 100)
                            } else {
                                const val = Number(e.target.value);
                                setAmount(val.toFixed(2));
                                setXyhbVal(Math.ceil(val * 101) / 100)
                            }
                        } }
                        type="number" placeholder="输入金额" style={ { width: '35%' } }
                        value={ amount }/>
                    
                    { amountData.map((v, i) => {
                        let _cn = i === currentAmount ? 'amount-op current' : 'amount-op';
                        return (
                            <div
                                onClick={ () => {
                                    setCurrentAmount(i)
                                    setAmount(v.amount)
                                    setXyhbVal(Math.ceil(v.amount * 101) / 100)
                                } }
                                key={ i } className={ _cn }>
                                ￥{ v.amount }
                                { i === currentAmount && <img className="gou" src={ iconGou } alt=""/> }
                            </div>
                        )
                    }) }
                </div>
            </div>
            <div className="recharge-type">
                <p className="title">充值方式</p>
                <div className="type-container">
                    <antdUI.Radio.Group onChange={ onChangeType } value={ currentType }>
                        {
                            typeData.map((v, i) => {
                                return (
                                    <antdUI.Radio value={ v.type } key={ v.type }>
                                        <div className="type-item">
                                            <img className="icon-type" src={ v.icon } alt={ v.type }/>
                                            { v.tip &&
                                                <span className="tip"
                                                      style={ { marginLeft: "10px" } }>({ v.tip })</span> }
                                        </div>
                                    </antdUI.Radio>
                                )
                            })
                        }
                    </antdUI.Radio.Group>
                </div>
                {
                    currentType === "xyhb" ?
                        <div className="xyhb-tips" style={ { color: "#888", fontSize: "15px", marginTop: "10px" } }>
                            实际支付:<span
                            style={ { marginRight: "20px", fontSize: '20px', color: "rgb(46, 176, 98)" } }>
                ￥{ xyhbVal }
              </span>
                            含服务费￥ { Math.round((xyhbVal - (amount ?? 0)) * 100) / 100 }
                        </div>
                        : ""
                }
                <antdUI.Button loading={ rechLoa }
                               type="primary"
                               className="recharge-btn"
                               onClick={ () => { sendAmount() } }>确认充值
                </antdUI.Button>
            </div>
            
            <div
                className="recharge-prompt">
                { promptData.map((v, i) => <p key={ i } style={ { color: v.color } }
                                              className="prompt-v">{ v.text }</p>) }
            </div>
            
            <div onClick={ () => {
                setRRPage(1)
                setrRecord(true)
            } } className="recharge-link">
                充值记录
                <i className="iconfont icon-arrow-right"></i>
                <i className="iconfont icon-arrow-right"></i>
            </div>
            
            <antdUI.Modal
                title="充值记录"
                maskClosable={ false }
                visible={ rRecord }
                footer={ false }
                onCancel={ () => setrRecord(false) }
                width="1200px"
            >
                <antdUI.Table pagination={ {
                    pageSize, showSizeChanger: false, total: rechRecord.total, onChange: (page) => {
                        setRRPage(page);
                    }
                } } dataSource={ rechRecord.items } columns={ columns }>
                </antdUI.Table>
            </antdUI.Modal>
            
            
            <antdUI.Modal
                title="扫码支付"
                maskClosable={ false }
                visible={ qrCodeShow }
                footer={ false }
                onCancel={ () => {
                    setQrCodeShow(false);
                    clearInterval(ylOrEndTimer);
                    setYlOrEndTimer(null);
                    setOrderEnd("")
                } }
                width="500px"
            >
                <div style={ { paddingBottom: "10px" } } className="ylsmf-content">
                    <p style={ { color: "#222222", fontSize: '14px', textAlign: "center" } }>
                        正在使用即时到账交易 , 交易将在5分钟后关闭，请及时付款！</p>
                    <p style={ { color: "#222222", fontSize: '14px', textAlign: "center", marginBottom: "30px" } }>
                        请使用 <span style={ { color: "red", fontSize: "20px" } }>微信App</span> 进行扫码支付</p>
                    <h2 style={ { textAlign: "center" } }>扫码支付: <span
                        style={ { color: "#FF4A2C" } }>{ qrVal?.totalAmount }元</span></h2>
                    <div className="ylqrcode-box flex jtyct" style={ { margin: "20px 0" } }>
                        { qrVal.code_url ? <><QRCode value={ qrVal.code_url }
                                                     size={ 200 }/></> : qrVal.code_url }
                        { orderEnd &&
                            <div style={ { color: `${ orderEnd.match('充值成功') ? "#2eb062" : "red" }` } }
                                 className="qrcode-mask">
                                { orderEnd.match('充值成功') ?
                                    <i className="iconfont icon-gou"
                                       style={ { fontSize: "50px", marginTop: "20px" } }></i>
                                    : <i className="iconfont icon-cha"
                                         style={ { fontSize: "50px", marginTop: "20px" } }></i> }
                                <p> { orderEnd }</p></div> }
                    </div>
                    <div className="flex jtyct" style={ { marginTop: '40px' } }>
                        <antdUI.Button type="primary" loading={ ylBtnLoa }
                                       className="recharge-btn"
                                       onClick={ () => ylCheckOrder() }>{ ylBtnLoa ? "正在检测..." : "我已充值" }</antdUI.Button>
                    </div>
                </div>
            </antdUI.Modal>
            
            <antdUI.Modal
                title="退款确认"
                maskClosable={ false }
                visible={ refundCheckView }
                footer={ false }
                onCancel={ () => cancelRefund() }
                width="500px"
            >
                <div className="flex jtyct ait" style={ { flexDirection: "column" } }>
                    <h2 style={ { marginBottom: 10 } }>您确认要退款?</h2>
                    
                    { Number(userAssets.alipay_amount) < Number(refundData.actualAmount) ?
                        <div className="flex jtyct ait" style={ { flexDirection: "column", marginBottom: 30 } }>
                            <h3>充值订单到账金额为：<span
                                style={ { color: "orange" } }>¥{ refundData.actualAmount }</span></h3>
                            <h5>您当前所剩余额小于该充值订单金额，您可以选择部分退款</h5>
                            <h5>(每一笔充值订单仅可退款一次)</h5>
                            <div className="flex jtyct ait">
                                <antdUI.InputNumber style={ { marginRight: 5 } }
                                                    min={ 0.01 }
                                                    max={ userAssets.alipay_amount }
                                                    value={ partialAmount } onChange={ _ => setPartialAmount(_) }/>
                                <span>¥{ userAssets.alipay_amount }(账户余额)</span>
                            </div>
                        </div> : <h3 style={ { marginBottom: 20 } }>退款金额为：<span
                            style={ { color: "orange" } }>¥{ refundData.actualAmount }</span></h3>
                    }
                    
                    <div className="flex jtyct ait">
                        <antdUI.Button loading={ rRecordLoa } type="primary" style={ { marginLeft: 10 } }
                                       onClick={ $tools.debounce(async _ => {
                                           const refundConfig = { tradeNumber: refundData.tradeNumber }
                                           partialAmount > 0 && (refundConfig.partialAmount = partialAmount)
                                           setrRecordLoa(true);
                                           const result = await $api.pay.alipayRefund(refundConfig)
                                           setrRecordLoa(false)
                                           if ($tools.checkResult(result)) {
                                               cancelRefund()
                                               antdUI.message.success('退款成功');
                                               getPayRecord().then()
                                           }
                                       }, 3000, true) }>退款
                        </antdUI.Button>
                        <antdUI.Button style={ { marginLeft: 10 } }
                                       onClick={ _ => cancelRefund() }>取消
                        </antdUI.Button>
                    </div>
                </div>
            
            </antdUI.Modal>
        </div>
    );
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo,
            userAssets: state.user.userAssets
        }
    },
    function mapDispatchToProps(disatch) {
        return {
            upDateUserAssets: () => disatch(upDateUserAssets())
        }
    }
)(Recharge);
