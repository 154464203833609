import React, { useEffect, $api } from 'react';
import * as echarts from 'echarts/core'
import { csgo } from '../../../../data/classification';
import {
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    DataZoomComponent
} from 'echarts/components'
import {
    LineChart
} from 'echarts/charts'
import {
    CanvasRenderer
} from 'echarts/renderers'

echarts.use(
    [ TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, LegendComponent, DataZoomComponent, LineChart, CanvasRenderer ]
)

const exteriorType = csgo[2].children;

function separateData(value) {
    switch (value) {
        case "WearCategory0":
            return 1;
        case "WearCategory1":
            return 2;
        case "WearCategory2":
            return 3;
        case "WearCategory3":
            return 4;
        case "WearCategory4":
            return 5;
        case "WearCategoryNA":
            return 6;
        default:
            return 6;
    }
}

function initIdList(wearList, curType) {
    let normalArr = [];
    let starArr = [];

    wearList.forEach(item => {
        const { quality, exterior } = item;
        const label = exteriorType.filter(exs => item.exterior === exs.value)?.[0]?.label;
        const obj = {
            label,
            id: item.id,
            sId: separateData(exterior)
        }
        if (quality === "strange" || quality === "unusual_strange") {
            starArr.push(obj);
        } else {
            normalArr.push(obj);
        }
    })
    normalArr.sort(function (a, b) {
        return a.sId - b.sId
    })
    starArr.sort(function (a, b) {
        return a.sId - b.sId
    })

    return Number(curType) === 1 ? normalArr : starArr;
}


let myChart;

function getLegend(idList) {
    return idList.map(item => item.label);
}

function getSeries(idList, data) {
    function dealDataHandle(dealData) {
        const
          minDate = dealData[0]?.[0],
          maxDate = dealData[dealData.length - 1]?.[0];

        const resultData = dealData.filter(item => item[1] !== '0.00');
        resultData.unshift([minDate, resultData[0]?.[1]]);
        resultData.push([maxDate, resultData[resultData.length - 1]?.[1]]);
        return resultData;
    }

    const arr = []
    idList.forEach((item, index) => {
        arr.push({
            name: item.label,
            smooth: true, symbol: 'none',
            type: 'line', data: dealDataHandle(data[index]) || []
        })
    })
    return arr;
}

function formatTime(time) {
    if (! time) return ''
    let times = new Date(time?.replace(/-/g, '/'))
    return `${ times.getFullYear() }-${ times.getMonth() + 1 }-${ times.getDate() }`
}

function getType(data) {
    const startTime = data?.[0]?.[0];
    const endTime = data?.[data.length - 1]?.[0];
    return `${ formatTime(startTime) }-${ formatTime(endTime) }`;
}

function init(idList, data) {
    myChart.setOption({
        title: {
            text: `${ getType(data[0]) }`,
            textStyle: {
                color: '#2A2A2A',
                fontSize: '16px'
            },
            left: '70px'
        },
        tooltip: {
            trigger: 'axis',
            position(pt) {
                return [ pt[0], '10%' ]
            }
        },
        xAxis: { type: 'time' },
        yAxis: { type: 'value' },
        dataZoom: [ { start: 0, end: 100 } ],
        legend: {
            data: getLegend(idList)
        },
        series: getSeries(idList, data)
    })
}

function PriceTrend(props) {
    const { curType } = props;

    const wearList = JSON.parse(localStorage.getItem('wearList') || '[]');

    async function getSummaryData(itemId) {
        if (myChart != null && myChart !== '' && myChart !== undefined) {
            myChart.dispose();
        }
        myChart = echarts.init(document.getElementById('price-trend'));
        myChart.showLoading();
        const res = await $api.market.getPriceTrend({
            itemid: JSON.stringify(itemId),
            pastDays: 30
        })
        myChart.hideLoading();
        return res;
    }


    useEffect(() => {
        const idls = initIdList(wearList, curType);
        getSummaryData(idls.map(item => item.id)).then(res => {
            init(idls, res.data.yesdayFinalPrice);
        })
        // eslint-disable-next-line
    }, [ curType ])


    return (
        <div id='price-trend' style={ { width: '100%', height: '600px', marginTop: '30px' } }></div>
    )
}

export default PriceTrend
