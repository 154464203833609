import React, { antdUI, useImperativeHandle, useState, $api, $tools, useRef, useEffect } from 'react';
import './index.scss';
import {
    CsgoFilter, Dota2Filter,
    SearchFilter
} from '@/components/itemFilter';
import OrderContainer from './orderContainer';
import BuyingContainer from './buying';
import OrderDetail from './orderContainer/orderDetail';
// import HandleOffer from '@/components/handleOffer'
import { connect } from 'react-redux';

function BuyContainer({ cRef, userInfo }) {
    const { getUrlQueryValue } = $tools
    let game = getUrlQueryValue('game') || '730'
    let nav = getUrlQueryValue('nav') || 'onSale'
    const [ currentGame, setGame ] = useState(game),
        [ currentNav, setCurrentNav ] = useState(nav),
        [ buyData, setBuyData ] = useState(''),
        [ currentPage, setCurrentPage ] = useState(1),
        [ total, setTotal ] = useState(0),
        [ trandOffer, setTrandOffer ] = useState([]),
        [ buyDataLoading, setBuyDataLoading ] = useState(true),
        [ orderDetailView, setOrderDetailView ] = useState(false),
        [ offLoading, setOffLoading ] = useState(false),
        [ orderDetailData, setOrderDetailData ] = useState({});
    
    const { checkResult } = $tools;
    const pageSize = 10;
    const childRef = useRef();
    
    const getOffer = async () => {
        setTrandOffer([])
        if (Number(currentGame) !== 730) return
        setOffLoading(true)
        const result = await $api.tradeoffer.getBuyerPendingOffer();
        if (checkResult(result)) setTrandOffer(result.data)
        setOffLoading(false)
    }
    
    useImperativeHandle(cRef, () => ({
        // setFilter 就是暴露给父组件的方法
        setFilter: ({ currentNav, currentGame }) => {
            setGame(currentGame);
            setCurrentPage(1)
            setCurrentNav(currentNav);
        }
    }));
    
    const searchOrder = async (searchVal, _currentGame) => {
        let result = await $api.putShelf.getOrderData({
            appid: _currentGame || currentGame,
            orderNumber: searchVal,
            orderType: currentNav
        })
        if (checkResult(result)) {
            if (result.data.orderData[0]) {
                setOrderDetailData(result.data.orderData[0]);
                setOrderDetailView(true)
            } else {
                antdUI.message.warning('订单有误')
            }
        }
    }
    
    
    const getData = async (config = {}) => {
        setBuyData('');
        setTotal(0);
        let { currentPage: _currentPage, searchContent } = config;
        _currentPage = _currentPage || currentPage;
        let result = await $api.putShelf.getOrderData(
            {
                appid: currentGame,
                curPage: _currentPage,
                pageSize, searchContent,
                orderType: currentNav,
                buyer: 1
            }
        );
        if (checkResult(result)) {
            let { orderData, total } = result.data;
            setTotal(total)
            setBuyData(orderData)
        } else {
            setBuyData([ 'no' ])
        }
    }
    
    const getBuy = (currentPage) => {
        setCurrentPage(currentPage)
        childRef.current.getData(currentPage)
    }
    
    useEffect(() => {
        if (! buyData) {
            setBuyDataLoading(true)
        } else {
            setBuyDataLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ buyData ])
    
    useEffect(() => {
        if (currentNav !== 'buying') getData().then()
        if (currentNav === 'onSale') getOffer().then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ currentGame, currentNav ])
    
    const backHead = (userInfo) => {
        switch (currentNav) {
            case 'onSale':
                if (currentGame === '730') {
                    return <CsgoFilter type="buy" cRef={ childRef } filterChange={ getData }/>
                } else if (currentGame === '570') {
                    return <Dota2Filter type="buy" cRef={ childRef } filterChange={ getData }/>
                }
                break;
            case 'intermediary':
                return <SearchFilter type="buy"
                                     currentGame={ currentGame } cRef={ childRef }
                                     searchChange={ searchOrder }/>
            case 'record':
                if (currentGame === '730') {
                    return <CsgoFilter type="buy" cRef={ childRef } filterChange={ getData }/>
                } else if (currentGame === '570') {
                    return <Dota2Filter type="buy" cRef={ childRef } filterChange={ getData }/>
                }
                break
            default:
                break;
        }
    }
    
    
    return (
        <div className="my-pur-container">
            {
                currentNav === 'buying_english' ?
                    <BuyingContainer currentNav={ currentNav } currentGame={ currentGame }></BuyingContainer>
                    : <>
                        {/* ! offLoading ? (trandOffer[0] && currentNav === 'onSale' && <HandleOffer type="buyer" trandOffer={ trandOffer }/>) : <antdUI.Skeleton active/> */ }
                        <div className="pur-container-head"
                             style={ { marginTop: (offLoading || trandOffer[0]) && currentNav === 'onSale' && '40px' } }>
                            <div className="item-filter" style={ {
                                padding: '6px 0 22px 0',
                                justifyContent: currentNav === 'intermediary' && 'center'
                            } }
                            >
                                { backHead() }
                            </div>
                        </div>
                        
                        <antdUI.Spin tip="正在加载 ..."
                                     style={ { margin: '150px auto 0 auto', width: '100%' } }
                                     size="large" spinning={ buyDataLoading }/>
                        
                        { buyData && <OrderContainer cRef={ childRef } getData={ getData } currentGame={ currentGame }
                                                     orderData={ buyData } currentNav={ currentNav }/> }
                        <div className="pur-pagination">
                            { total ? <antdUI.Pagination
                                onChange={ (currentPage, pageSize) => getBuy(currentPage, pageSize) }
                                showSizeChanger={ false } current={ currentPage } defaultCurrent={ 1 } total={ total }
                                pageSize={ pageSize }/> : '' }
                        </div>
                        {/* </antdUI.Spin> */ }
                        { orderDetailView &&
                            <OrderDetail type="buy" currentGame={ currentGame } orderDetailView={ orderDetailView }
                                         getData={ getData }
                                         setOrderDetailView={ setOrderDetailView } orderDetailData={ orderDetailData }/> }
                    </>
            }
        </div>
    
    );
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo,
        }
    }
)(BuyContainer);
