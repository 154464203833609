import React, {
    $api,
    $bindSteamLink,
    $login,
    $toBindSteam,
    $tools,
    antdUI,
    useEffect,
    useState
} from 'react';
import { dopplerData } from '@/data/dopplerData'
import { connect } from 'react-redux';
import { upDateUserAssets } from "@/store/actions/userInfo.js";

function BuyingDialog(props) {
    const { csData: v, setReleaseDialog, releaseDialog, userInfo, upDateUserAssets } = props;
    const [ buyingPrice, setBuyingPrice ] = useState('');
    const [ buyingNum, setBuyingNum ] = useState(1);
    const [ selectOptions, setSelectOptions ] = useState([]);
    const [ styleValue, setStyleValue ] = useState();
    const [ totalPrice, setTotalPrice ] = useState(0);
    console.log(v)
    const computeTotalPrice = (price) => {
        const bPrice = Number(price) || Number(buyingPrice) || 0;
        const bNum = Number(buyingNum) || 0
        setTotalPrice(bPrice * bNum);
    }
    
    const handleCancel = () => {
        setReleaseDialog(false)
    }
    
    const styleChange = (val) => {
        setStyleValue(val);
    }
    
    const postBuying = async (price) => {
        if (! userInfo?.steam?.steamid && ! userInfo.uid) return $login() // 没登录
        if (userInfo?.steam?.steamid && ! userInfo.uid) return window.location.href = '/profile/userConfig#login'
        if (! userInfo?.steam?.steamid && userInfo.uid) return $toBindSteam()
        // if (userInfo.real_name_status !== 1) return $toReal()
        if (! userInfo?.steam?.transactionLink) return $bindSteamLink()
        // const minPrice = (price * 0.7).toFixed(2);
        if (! buyingPrice || ! Number(buyingNum)) return antdUI.message.warning('请填写完整所需要的信息!');
        if (selectOptions?.[0] && ! styleValue) return antdUI.message.warning('请选择款式信息!');
        // if (price && Number(buyingPrice) < Number(minPrice)) {
        //     setBuyingPrice(minPrice);
        //     computeTotalPrice(minPrice);
        //     return antdUI.message.warning(`求购单价不能低于${ minPrice }!`);
        // }
        if ((Number(price) || buyingPrice) <= 0.01) return antdUI.message.warning(`求购单价需要大于等于${ 0.02 } ！`);
        let postData = {
            itemid: v.id,
            total: buyingNum,
            unitPrice: buyingPrice
        };
        
        if (styleValue) {
            postData.itemStyle = styleValue;
        }
        
        const res = await $api.putShelf.publishBuyingOrder(postData);
        if ($tools.checkResult(res)) {
            upDateUserAssets()
            antdUI.message.success('发布成功！');
            setReleaseDialog(false);
        }
    }
    
    function getSelectOption() {
        let hashName = v.market_hash_name;
        let _hashName = '';
        for (const key in dopplerData) {
            if (Object.prototype.toString.call(dopplerData[key].value) === '[object Array]') {
                for (let item of dopplerData[key].value) {
                    _hashName = hashName.replace(/\(.*?\)/g, "").trim()
                    if (_hashName === item) {
                        setSelectOptions(dopplerData[key].children)
                        break
                    }
                }
            } else {
                if (hashName?.match(dopplerData[key].value)) {
                    setSelectOptions(dopplerData[key].children)
                }
            }
        }
    }
    
    useEffect(() => {
        getSelectOption();
        // eslint-disable-next-line
    }, [ v ])
    
    return (
        <antdUI.Modal
            visible={ releaseDialog }
            footer={ false }
            maskClosable={ false }
            onCancel={ handleCancel }
            title='发布求购'
            width={ 685 }
            destroyOnClose={ true }
        >
            <div className='initiate-con'>
                <div className='acc-info'>
                    <div className='icon-img'>
                        <img src={ $tools.BASE_STEAM_IMG + v?.icon_url } alt=""/>
                    </div>
                    <div className='acc-name'>
                        <p>求购饰品 &nbsp; <span
                            style={ { fontSize: '18px', color: '#222222' } }>{ v.market_name }</span>
                        </p>
                        <div>
                            {
                                (Number(v.maxPrice) <= 0) ? '' :
                                    <p style={ { marginRight: '10px' } }>求购最高价  &nbsp; |  &nbsp;
                                        <span>￥{ Number(v.maxPrice) ? Number(v.maxPrice).toFixed(2) : '暂无' }</span>
                                    </p>
                            }
                            <p>在售最低价  &nbsp;| &nbsp; <span>￥{ v.price || '暂无' }</span></p>
                        </div>
                    </div>
                </div>
                <div className='initiate-config'>
                    {
                        selectOptions.length !== 0 &&
                        <div className='config-item'>
                            <span>款式选择</span>
                            <div className='style-selection'>
                                <antdUI.Select allowClear value={ styleValue } style={ { width: '100px' } }
                                               placeholder={ '款式筛选' } onChange={ styleChange }>
                                    {
                                        selectOptions.map((item, index) => {
                                            return <antdUI.Select.Option value={ item.label }
                                                                         key={ index }>{ item.label }</antdUI.Select.Option>
                                        })
                                    }
                                </antdUI.Select>
                            </div>
                        </div>
                    }
                    
                    <div className='config-item'>
                        <span>求购单价</span>
                        <antdUI.Input value={ buyingPrice } onKeyUp={ () => computeTotalPrice() }
                                      onChange={ e => setBuyingPrice(e.target.value) } prefix="￥" suffix="RMB"
                                      style={ { width: '200px' } }></antdUI.Input>
                    </div>
                    <div className='config-item'>
                        <span>求购数量</span>
                        <antdUI.Input min={ 1 } onKeyUp={ () => computeTotalPrice() } value={ buyingNum }
                                      onChange={ e => setBuyingNum(e.target.value) }
                                      style={ { width: '200px' } }></antdUI.Input>
                    </div>
                </div>
                <div className='initiate-bottom'>
                    <div className='accounts'>
                        应付总额：<span>￥{ totalPrice.toFixed(2) }</span>
                    </div>
                    <antdUI.Button onClick={ () => postBuying(v.price) } type='primary'>确认求购</antdUI.Button>
                </div>
            </div>
        </antdUI.Modal>
    )
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    },
    function mapDispatchToProps(disatch) {
        return {
            upDateUserAssets: () => disatch(upDateUserAssets()),
        };
    }
)(BuyingDialog);
