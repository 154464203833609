import { createStore, combineReducers, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import userInfoReducer from './reducers/userInfo'
import userInfoState from './states/userInfo'
import favoritesDataReducer from './reducers/favoritesData'
import favoritesDataState from './states/favoritesData'

const allReducers = combineReducers({
  user: userInfoReducer, favoritesData: favoritesDataReducer
})

const store = createStore(allReducers, {
  user: userInfoState, favoritesData: favoritesDataState
}, applyMiddleware(ReduxThunk));

export default store;