import React, {$tools, antdUI, useEffect, useState} from 'react';
import './scss/wearRanking.scss'
import ItemDetailPopover from '../../../itemDetailPopover';
import Sticker from './sticker';

function WearRanking(props) {
  const { dataSource, filterPage, total, curPage, curExId, wearLoading } = props;

  const [wearRankingData, setWearRankingData] = useState([]);

  const paginationProps = {
    position: ["bottomCenter"],
    pageSize: 10,
    current: curPage,
    total: total, //数据的总的条数
    onChange: (current) => filterPage(curExId, current), //点击当前页码
  };

  function initData() {
    let data = [];
    dataSource.forEach((item, index) => {
      let {
        icon_url,
        floatvalue, market_name,
        sellingPrice, stickers} = item;
      //注入数据
      item.market_item_detail = {
        error: 0,
        msg: {
          floatvalue,
          stickers: stickers || [],
          paintseed: item.paintseed,
          paintindex: item.paintindex,
          customname: item.customname,
        },
      };
      data.push({
        key: index,
        ranking: <p className='ranking-num'>{(curPage -1) * 10 + index +1}</p>,
        name: (
          <div className='item-shop-name'>
            <div className='item-img'>
              <antdUI.Popover
                placement="rightTop"
                content={<ItemDetailPopover  csData={item} />}
              >
                <img src={$tools.BASE_STEAM_IMG + icon_url} alt="饰品" />
              </antdUI.Popover>
            </div>
            <div className='item-name-price'>
              <p>{market_name}</p>
              {
                sellingPrice &&
                <div className='on-sale-price'>
                  <span className='onSale-logo'>在售</span>
                  <span>￥{sellingPrice}</span>
                </div>
              }

            </div>
            <Sticker stickers={stickers}></Sticker>
          </div>
        ),
        wear: <p>{floatvalue}</p>
      })
    });
    setWearRankingData(data);
  }

  useEffect(()=> {
    if (dataSource) {
      initData();
    }
    // eslint-disable-next-line
  }, [dataSource])

  return (
    <div className="buying-container">
      <antdUI.Table
        loading={wearLoading}
        pagination={paginationProps}
        dataSource={wearRankingData}
        columns={columns}
      ></antdUI.Table>
    </div>
  );
}

let titleStyle = { textAlign: "center", fontWeight: 900, fontSize: "14px" };
const columns = [
  {
    title: <p style={titleStyle}>排名</p>,
    dataIndex: "ranking",
    key: "ranking",
    align: "center",
  },
  {
    title: <p style={titleStyle}>饰品名称</p>,
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: <p style={titleStyle}>磨损度</p>,
    dataIndex: "wear",
    key: "wear",
    align: "center",
  },
];

export default WearRanking;
