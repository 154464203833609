import React, { $bindSteamApiKey, $bindSteamLink, $login, $toBindSteam, useState } from 'react';
import ShopSettingBox from './shopSetting'
import { connect } from "react-redux";

function ShopSetting(props) {
    const { userInfo } = props
    const [ settingView, setSettingView ] = useState(false)
    return (
        <div className="shop-setting-text">
            <div className="box" onClick={ () => {
                if (! userInfo?.steam?.steamid && ! userInfo.uid) return $login() // 没登录
                if (userInfo?.steam?.steamid && ! userInfo.uid) return window.location.href = '/profile/userConfig#login'
                if (! userInfo?.steam?.steamid && userInfo.uid) return $toBindSteam()
                // if (userInfo.real_name_status !== 1) return $toReal()
                if (! userInfo?.steam?.transactionLink) return $bindSteamLink()
                if (! userInfo?.steam?.apiKey) return $bindSteamApiKey()
                setSettingView(true)
            } }>
                <i style={ { fontSize: '22px', marginRight: '4px' } }
                   className="iconfont icon-setting"></i>
                <p>店铺设置</p>
            </div>
            
            { settingView ? <ShopSettingBox settingView={ settingView } setSettingView={ setSettingView }/> : '' }
        </div>
    );
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    },
)(ShopSetting);
