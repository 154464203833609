import React, { antdUI, useState, useEffect, useImperativeHandle } from 'react';
import { dota2 } from '@/data/classification.js';
import SearchFilter from './search'
import './csgo.scss'
let CascaderStyle = {
  width: '104px',
  height: '33px',
  marginRight: '20px'
};
const inventorySelect = [
  {
    value: 'all',
    label: '显示全部'
  },
  {
    value: 'tradable',
    label: '显示可交易'
  },
  {
    value: 'sale',
    label: '显示可出售'
  },
]
function Dota2Filter(props) {
  const { type, setCurrentPage, filterChange, sort, maxPrice, minPrice } = props;
  const [isInt, setIsInt] = useState(true),
    [hero, sethero] = useState([]),
    [quality, setquality] = useState([]),
    [rarity, setrarity] = useState([]),
    [searchVal, setSearchVal] = useState(''),
    [searchChangeSt, setSearchChangeSt] = useState(0),
    [itemStatus, setItemStatus] = useState([]);

  useEffect(() => {
    if (isInt) {
      setIsInt(false)
    } else {
      setCurrentPage && setCurrentPage(1)
      filterChange({
        hero, quality, rarity,
        itemStatus, sort, minPrice, maxPrice,
        searchContent: searchVal, currentPage: 1
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hero, quality, rarity, itemStatus,
    sort, minPrice, maxPrice, searchChangeSt])

  useImperativeHandle(props.cRef, () => ({
    // setFilter 就是暴露给父组件的方法
    getData: (currentPage) => filterChange({
      hero, quality, rarity, itemStatus,
      searchContent: searchVal, currentPage, sort, maxPrice, minPrice
    })
  }));

  return (
    <>
      {
        (type !== 'ship' || type !== 'buy') &&
        // type !== 'onSalePut' &&
        dota2.map((v, i) => {
          return (
            <antdUI.Cascader
              key={v.value}
              dropdownRender={menus => <div className="mobi-item-filter-select">{menus}</div>}
              displayRender={label => label[label.length - 1]}
              style={CascaderStyle} options={v.children}
              expandTrigger="hover"
              value={(() => {
                switch (v.value) {
                  case "hero": return hero;
                  case "quality": return quality;
                  case "rarity": return rarity;
                  default: break;
                }
              })()}
              onChange={value => {
                let _v = value;
                switch (v.value) {
                  case "hero": sethero(_v); break;
                  case "quality": setquality(_v); break;
                  case "rarity": setrarity(_v); break;
                  default: break;
                }
              }} placeholder={v.label}
            />
          )
        })}

      {type === 'tradingmarket' || type === 'onSalePut' || type === 'sale' || type === 'ship' ||
        type === 'buy' ||
        <antdUI.Cascader
          options={inventorySelect}
          value={itemStatus}
          onChange={value => setItemStatus(value)}
          style={CascaderStyle} placeholder={inventorySelect[0].label} />}
      <SearchFilter setSearchVal={setSearchVal} searchChange={_ => {
        let num = ~~(Math.random() * 1000)
        setSearchChangeSt(num)
      }}
      />
    </>
  )
}

export default Dota2Filter;
