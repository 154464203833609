import React, { useEffect, $api, useState } from 'react';
import { HelpCenterNav, HelpCenterContent } from '@/components/helpCenter'
import './index.scss'

export default function HelpCenter() {
  const [ helpNavdata, setHelpNavData ] = useState([])
  const [ helpContent, setHelpContent ] = useState('')
  const [ articleTitle, setArticleTitle ] = useState('')
  const [ updateTime, setUpdateTime ] = useState('')
  const [ loading, setLoading ] = useState(true);
  const handleNavData = (nav, type, helpNavdata) => {
    let _data = JSON.parse(JSON.stringify(helpNavdata))
    _data.forEach((item) => {
      if (type === item.type) {
        item.current = true;
        for (const el of item.children) {
          if (el.nav === nav) {
            el.current = true
          } else {
            el.current = false
          }
        }
      } else {
        item.current = false;
        for (const el of item.children) {
          el.current = false
        }
      }
    })
    setHelpNavData(_data)
  }
  const getHelpData = async () => {
    let result = await $api.siteSetting.getHelp();
    if (result.data && result.data[0]) {
      let loc_hash = window.location.hash, type;
      if (loc_hash) {
        loc_hash = loc_hash.substr(1);
        let res = await $api.siteSetting.getHelp({ nav: loc_hash });
        setLoading(false)
        if (res.data?.content) {
          setHelpContent(res.data?.content)
          setArticleTitle(res.data?.articleTitle)
          setUpdateTime(res.data?.updateTime)
        } else {
          setHelpContent('not')
        }
      } else {
        setHelpContent(result.data[0].content);
        setArticleTitle(result.data[0].articleTitle)
        setUpdateTime(result.data[0].updateTime)
      }  // 设置 default
      result.data[0].type === "default" && result.data.splice(0, 1)
      setLoading(false)
      result.data.forEach(item => {
        for (const el of item.children) {
          if (el.nav === loc_hash) {
            type = item.type
          }
        }
      })
      handleNavData(loc_hash, type, result.data)
    }
    
  }
  useEffect(() => {
    getHelpData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
      <div className="help-center-page">
        <img
            className="tranding-market-banner"
            src={ require("@/images/tradingMarket/banner2@2x.png") }
            alt=""
        />
        <div className="help-center-container">
          <HelpCenterNav helpNavdata={ helpNavdata } setLoading={ setLoading } handleNavData={ handleNavData }
                         setArticleTitle={ setArticleTitle }
                         setUpdateTime={ setUpdateTime }
                         setHelpContent={ setHelpContent }/>
          <HelpCenterContent helpContent={ helpContent } articleTitle={ articleTitle } updateTime={ updateTime }
                             loading={ loading }/>
        </div>
      </div>
  )
}
