import React, { useEffect, useState } from "react";
import "./FilterBar.scss";
import { dota2 } from "@/data/classification.js";

export default function FilterBar(props) {
  const [barActive, setBarActive] = useState("bar-container"),
    [barHeadName, setBarHeadName] = useState("");
  let { type, headName, curType, setQuality, setRarity } = props;

  useEffect(() => {
    type === "block" && setBarActive("bar-container block");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let _dota2 = dota2.filter((item) => item.value === curType)[0];

  const handleTextClick = (name, value) => {
    if (name === "不限") {
      setBarHeadName("");
    } else {
      setBarHeadName(name);
    }
    if (curType === "quality") {
      value === "unlimited" ? setQuality("") : setQuality(value);
    } else if (curType === "rarity") {
      value === "unlimited" ? setRarity("") : setRarity(value);
    }
  };

  return (
    <>
      <div className={barActive}>
        <div className="bar-head">
          <span
            className={[barHeadName ? "bar-head-text cur" : "bar-head-text"]}
          >
            {barHeadName || headName}
          </span>
          <i className="item arrow-bottom"></i>
        </div>

        <div className="bar-body">
          {_dota2.children.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  backgroundColor: barHeadName === item.label && "#2DAF61",
                }}
                className={"bar-body-text"}
                onClick={handleTextClick.bind(null, item.label, item.value)}
              >
                {item.label}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
