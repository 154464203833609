import React, {
    $api,
    $bindSteamApiKey,
    $bindSteamLink,
    $login,
    $toBindSteam,
    $tools,
    antdUI,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import './index.scss'
import ItemContainer from '@/components/itemContainer';
import { CsgoFilter, Dota2Filter } from '../../itemFilter';
import OnShelf from '@/components/onShelf';
import { connect } from 'react-redux';

function InventoryContainer({ cRef, userInfo, type: inventoryBoxType, setMaterialData, materialData }) {
    const cacheGame = inventoryBoxType === 'replacing' ? "730" : localStorage.getItem('cacheGame') || '730'
    const [ currentGame, setGame ] = useState(cacheGame),
        [ inventoryData, setInventory ] = useState(''),
        [ allInventoryCs, setAllInventoryCs ] = useState([]),
        [ allInventoryDo, setAllInventoryDo ] = useState([]),
        [ CurrentPage, setCurrentPage ] = useState(1),
        [ total, setTotal ] = useState(0),
        [ totalDo, setTotalDo ] = useState(''),
        [ inventoryLoading, setInventoryLoading ] = useState(true), // 加载数据loading
        [ itemSelect, setItemSelect ] = useState([]),
        [ allRadio, setAllRadio ] = useState(false),
        [ modalShow, setModalShow ] = useState(false),
        [ onShelfTitle, setOnShelfTitle ] = useState('上架提示'),
        [ onShelfType, setonShelfType ] = useState(''),
        [ locType, setLocType ] = useState(cacheGame === '730' ?
            'csgoInventory' : cacheGame === '570' ?
                'dota2Inventory' : 'csgoInventory'),
        [ timeSolt, setTimeSolt ] = useState(0),
        [ priceSolt, setPriceSolt ] = useState(0),
        [ timeInt, setTimeInt ] = useState(true),
        [ inventoryLoding, setInventoryLoding ] = useState(false);  // 分页后面的加载文案loading
    
    
    const [ initCache, setInitCache ] = useState(false)
    const [ cPDLoading, setCPDLoading ] = useState(false)
    
    const [ csgoAllPrice, setCsgoAllPrice ] = useState(0)
    // const cacheOp = { "730": "csgoInventoryCache", "570": "dota2InventoryCache" }
    const pageSize = 50;
    const inventoryCount = 300;
    const selectLength = 100;
    const { checkResult } = $tools;
    const childRef = useRef();
    const handleCacheInventoryData = function (inventory) {
        if (inventory) {
            let loc_ident = localStorage.getItem(`identification${ userInfo.steamid }`);
            loc_ident = loc_ident ? JSON.parse(loc_ident) : '';
            let identification = {};
            inventory.forEach(item => {
                if (item.market_item_detail && item.market_item_detail.error === "0") {
                    let { assetid, market_item_detail } = item;
                    identification[assetid] = market_item_detail
                }
            })
            if (loc_ident) {
                for (let i in identification) {
                    loc_ident[i] = identification[i]
                }
                inventory.forEach(el => { el.market_item_detail = loc_ident[el.assetid] })
                localStorage.setItem(`identification${ userInfo.steamid }`, JSON.stringify(loc_ident))
            } else {
                localStorage.setItem(`identification${ userInfo.steamid }`, JSON.stringify(identification))
            }
            return inventory;
        } else {
            return [ 'no' ]
        }
    }
    
    useEffect(() => { // 切换库存类型时初始化全选和刷新库存数据
        if (currentGame === '730') {
            setLocType('csgoInventory')
        } else if (currentGame === '570') {
            setLocType('dota2Inventory')
        } else {
            setLocType('not')
        }
        setItemSelect([]);
        setInventory('');
        setTotal(0);
        setAllInventoryCs([]);
        setAllInventoryDo([]);
        setTotalDo('')
        
        setCsgoAllPrice(0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ currentGame ])
    
    useEffect(() => {
        if (userInfo.steamid) {
            setInventory()
            if (locType !== 'not') {
                getInventory().then();
                // const cacheInventoryTime = localStorage.getItem(`${ cacheOp[currentGame] }Time`)
                // let cacheInventory = localStorage.getItem(cacheOp[currentGame])
                // if (new Date().getTime() > Number(cacheInventoryTime) + 1000 * 60 * 60 * 6) {
                //     getInventory().then();
                // } else if (cacheInventory) {
                //     cacheInventory = JSON.parse(cacheInventory)
                //     if (currentGame === "730") {
                //         setAllInventoryCs(cacheInventory)
                //         setTotal(cacheInventory.length);
                //     } else if (currentGame === "570") {
                //         setAllInventoryDo(cacheInventory)
                //         setTotalDo(cacheInventory.length)
                //     }
                //     setInitCache(true)
                // } else {
                //     getInventory().then();
                // }
            }
        } else {
            setInventory([ 'no' ])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ locType, userInfo ])
    const getClassInfo = async (inventory) => {
        inventory = inventory.filter(item => ! item.tradable);
        let classids = '', count = inventory.length;
        for (let i = 0; i < count; i++) {
            classids += `classid${ i }=${ inventory[i].classid }&`
        }
        if (count === 0) return {}
        let result = await $api.steam.getAssetClassInfo({ count, classids, appid: currentGame })
        if (checkResult(result)) {
            return result.data.result;
        } else {
            return {}
        }
    }
    
    const getData = async (config = {}) => {
        let { currentPage: _currentPage } = config;
        _currentPage = _currentPage || CurrentPage || 1;
        let steamInventory;
        if (currentGame === '730') {
            if (allInventoryCs[0]) {
                steamInventory = JSON.parse(JSON.stringify(allInventoryCs))
            } else {
                return
            }
        } else if (currentGame === '570') {
            if (allInventoryDo[0]) {
                steamInventory = JSON.parse(JSON.stringify(allInventoryDo))
            } else {
                return
            }
        }
        // setTotal(0);  setTotalDo('')
        // setItemSelect([]);
        setInventory('');
        // console.log(config)
        if (! steamInventory[0]) return
        
        if (priceSolt === 1) {
            steamInventory = steamInventory.sort((a, b) => (b.price || b.refPrice) - (a.price || a.refPrice))
        } else if (priceSolt === 2) {
            steamInventory = steamInventory.sort((a, b) => (a.price || a.refPrice) - (b.price || b.refPrice))
        }
        if (timeSolt === 2) {
            steamInventory = steamInventory.reverse()
        }
        
        for (let z in config) {
            if ((config[z] && config[z][0]) || z === 'searchContent') {
                steamInventory = inventortFilter({ type: z, data: steamInventory, _fil: config[z] })
            }
        }
        if (steamInventory.length === 0) {
            setInventory([ 'no' ]);
            if (currentGame === '730') {
                setTotal(steamInventory.length)
            } else if (currentGame === '570') {
                setTotalDo(steamInventory.length)
            }
            return
        } // 筛选数据为空的情况
        let inventory = handleCacheInventoryData(steamInventory.slice((_currentPage - 1) * pageSize, _currentPage * pageSize));
        if (Number(currentGame) === 730) {
            let classInfo = await getClassInfo(inventory);
            inventory.filter(item => item._classInfo = classInfo[item.classid] || {})
        }
        setTimeout(() => {
            if (currentGame === '730') {
                setTotal(steamInventory.length)
            } else if (currentGame === '570') {
                setTotalDo(steamInventory.length)
            }
            if (inventory.length === 0) {
                setInventory('')
            } else {
                setInventory(inventory)
            }
        })
        setInitCache(false)
    }
    
    let desList = [], _desList = [];
    const getInventory = async (count = inventoryCount, oldAllCount = 0, assetid) => {
        const result = await $api.steam.getSteamInventory({ gameid: currentGame, assetid, count });
        if (checkResult(result)) {
            let { result: inventory, total } = result.data || {};
            if (! inventory) return setInventory([ 'no' ])
            inventory.forEach(_ => desList.push(_))
            _desList = JSON.parse(JSON.stringify(desList))
            if (currentGame === '730') {
                setAllInventoryCs(_desList)
                setTotal(desList.length);
            } else if (currentGame === '570') {
                setAllInventoryDo(_desList)
                setTotalDo(desList.length)
            }
            if ((count === inventoryCount && total > inventoryCount)) {
                setInventoryLoding(true)
                await getInventory(1000, desList.length, inventory[inventory.length - 1].assetid)
            } else {  // 闭包出口
                // if (currentGame === "730" || (currentGame === "570" && total <= 500)) {
                //     try {
                //         localStorage.setItem(cacheOp[currentGame], JSON.stringify(_desList))
                //         localStorage.setItem(`${ cacheOp[currentGame] }Time`, String(new Date().getTime()))
                //     } catch (e) {
                //         console.log(e)
                //     }
                // }
                if (currentGame === '730') {
                    let allPrice = 0
                    _desList.forEach(({ price, refPrice }) => {
                        allPrice += (isNaN(Number(price)) ? isNaN(Number(refPrice)) ? 0 : Number(refPrice) : price > 0 ? Number(price) : Number(refPrice))
                    })
                    setCsgoAllPrice(Math.round(allPrice * 100) / 100)
                }
                await checkShelfData(_desList)
                setInventoryLoding(false)
            }
        } else {
            oldAllCount !== 0 || setInventory([ 'no' ])
        }
    }
    
    const checkShelfData = async (_all) => {
        let shelfData = await $api.putShelf.putShelfData({ appid: currentGame })
        let res = shelfData.data;
        if (res && res[0]) {
            _all = _all.map(_ => _.assetid)
            res.forEach(el => {
                if (_all.indexOf(el.assetid) !== -1) el.check = true
            })
            const _res = res.filter(_ => ! _.check)
            if (_res[0]) {
                await $api.putShelf.onSaleOffShelf({
                    appid: currentGame,
                    orderNumber: _res.map(_ => _.orderNumber),
                    auto: true
                })
            }
        }
    }
    
    const changePage = (currentPage) => { // 分页获取库存
        setCurrentPage(currentPage)
        setAllRadio(2)
        childRef.current.getData(currentPage)
    }
    
    const curPageDataDetail = async () => {
        if (! inventoryData || ! inventoryData[0]) return antdUI.message.warning('请等待物品加载！')
        setCPDLoading(true)
        let notDetail = inventoryData.filter(item => {
            return [ 'CSGO_Type_Knife', 'CSGO_Type_Pistol', 'CSGO_Type_Rifle', 'CSGO_Type_SniperRifle',
                    'CSGO_Type_SMG', 'CSGO_Type_Shotgun', 'CSGO_Type_Machinegun', 'Type_Hands' ].includes(item.tags.filter(_ => _.category === "Type")[0].internal_name)
                && item.actions && ! item.market_item_detail
        })
        let op = []
        notDetail.forEach(item => op.push({ inspecturl: item.actions[0].link, assetid: item.assetid }))
        if (! op[0]) {
            setCPDLoading(false)
            return antdUI.message.warning('您当页可解析等物品已经解析完毕！')
        }
        await $api.steam.getMarketItemDetail({ tarData: op }).then(res => {
            if (res && res.status === 200 && JSON.stringify(res.data) !== '{}') {
            }
            const _market_item_detail = res?.data || [];
            const _data = inventoryData.map((item) => {
                _market_item_detail[item.assetid] && (item.market_item_detail = _market_item_detail[item.assetid])
                return item
            })
            setInventory(handleCacheInventoryData(_data))
            setCPDLoading(false)
            antdUI.message.success('解析完成！')
        })
    }
    
    useEffect(() => {
        if (allInventoryCs.length > 0 && allInventoryCs.length <= inventoryCount) {
            childRef.current.getData(CurrentPage)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ allInventoryCs ])
    
    useEffect(() => {
        initCache && childRef.current.getData(CurrentPage)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ initCache ])
    
    useEffect(() => {
        if (allInventoryDo.length > 0 && allInventoryDo.length <= inventoryCount) {
            childRef.current.getData(CurrentPage)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ allInventoryDo ])
    
    useEffect(() => { // 排序
        if (timeInt) {
            setTimeInt(false)
        } else {
            setCurrentPage(1)
            childRef.current.getData(1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ timeSolt, priceSolt ])
    
    useEffect(() => { // 全选 
        if (inventoryData && inventoryData[0]) {
            if (Number(allRadio) === 1) {
                const data = [ ...itemSelect, ...inventoryData.filter(item => {
                    if (item.tradable && item.putShelf !== 1 && item.putShelf !== 2
                        && itemSelect.invenOnIndexOf(item) === -1) {
                        return item
                    } else {
                        return false
                    }
                }) ]
                if (data.length >= selectLength) {
                    antdUI.message.warning(`至多选择${ selectLength }件物品上架`)
                }
                setItemSelect(data.splice(0, selectLength))
            } else if (Number(allRadio) === 2) {
            } else {
                setItemSelect(itemSelect.filter(item => {
                    if (inventoryData.invenOnIndexOf(item) === -1) {
                        return true
                    } else {
                        return false
                    }
                }))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ allRadio ])
    
    useEffect(() => { // 加载中loading
        if (! inventoryData || inventoryData === 'loading') {
            setInventoryLoading(true)
        } else {
            setInventoryLoading(false)
        }
    }, [ inventoryData ])
    
    useImperativeHandle(cRef, () => ({
        // setFilter 就是暴露给父组件的方法
        setFilter: (newVal) => {
            setGame(newVal);
            setCurrentPage(1)
        }
    }));
    
    const handlePutSu = res => {
        if (! res[0]) return
        const assetidList = res.map(_ => _.assetid)
        let newData
        switch (Number(res[0].appid)) {
            case 730:
                newData = allInventoryCs.map(_ => {
                    assetidList.includes(_.assetid) && (_.putShelf = 2)
                    return _
                })
                setAllInventoryCs(newData)
                // localStorage.setItem("csgoInventoryCache", JSON.stringify(newData))
                break;
            case 570:
                newData = allInventoryDo.map(_ => {
                    assetidList.includes(_.assetid) && (_.putShelf = 2)
                    return _
                })
                setAllInventoryDo(newData)
                // localStorage.setItem("dota2InventoryCache", JSON.stringify(newData))
                break;
            default:
                break
        }
        setInitCache(true)
        // childRef.current.getData(CurrentPage)
        setItemSelect([])
    }
    
    return (
        <div className="inventory-container">
            <div className="inventory-container-head">
                <div className="item-filter">
                    {
                        currentGame === '730' ?
                            <CsgoFilter cRef={ childRef } setCurrentPage={ setCurrentPage } filterChange={ getData }/> :
                            currentGame === '570' ?
                                <Dota2Filter
                                    cRef={ childRef } setCurrentPage={ setCurrentPage } filterChange={ getData }/> : ''
                    }
                </div>
                <div className="item-operating">
                    <div className="select-quantity">
                        { inventoryBoxType === 'myInventory' && <> 已选择：<span
                            style={ { color: '#2DAF61' } }>{ itemSelect.length }</span>/
                            {/* {inventoryData[0] === 'no' ? 0 : inventoryData.length} */ }
                            { selectLength }
                            <antdUI.Radio checked={ allRadio === 2 ? false : allRadio }
                                          onClick={ () => setAllRadio(allRadio === 2 ? true : ! allRadio) }
                                          style={ { margin: '0 20px' } }>全选
                            </antdUI.Radio>
                        </> }
                        <div className="time-solt" onClick={ () => {
                            let num = timeSolt === 1 ? 2 : 1
                            setTimeSolt(num)
                            setPriceSolt(0)
                        } }>按时间
                            { timeSolt === 0 ? <i className="iconfont icon-cs-jt-xs"></i> : timeSolt === 1 ?
                                <i className="iconfont icon-cs-jt-xs asc"></i> :
                                <i className="iconfont icon-cs-jt-xs des"></i> }
                        </div>
                        <div className="price-solt" onClick={ () => {
                            let num = priceSolt === 1 ? 2 : 1
                            setTimeSolt(0)
                            setPriceSolt(num)
                        } }>按价格
                            { priceSolt === 0 ? <i className="iconfont icon-cs-jt-xs"></i> : priceSolt === 1 ?
                                <i className="iconfont icon-cs-jt-xs asc"></i> :
                                <i className="iconfont icon-cs-jt-xs des"></i> }</div>
                        <div className="item-refresh" onClick={ () => {
                            const gameid = currentGame;
                            setGame("0");
                            // localStorage.setItem(`${ cacheOp[currentGame] }Time`, "")
                            setTimeout(() => setGame(gameid))
                        } }><img src={ require('@/images/inventory/refersh.png') } alt=""/>刷新
                        </div>
                        
                        {
                            currentGame === '730' ? <>
                                <div className="price-solt">件数：<span style={ { color: "orange" } }>{ total }</span>
                                </div>
                                { csgoAllPrice > 0 ? <div className="price-solt">库存估值：<span
                                    style={ { color: "orange", margin: 0 } }>¥{ csgoAllPrice }</span>
                                </div> : "" }</> : ""
                        }
                    </div>
                    { currentGame === '730' && <div className="put-shelf">
                        <div className="onSale">
                            <antdUI.Popconfirm icon={ false } title={ (<p
                                style={ { width: '200px', fontSize: '14px' } }>
                                一键解析获取当页Steam库存物品的磨损印花等数据
                            </p>) } placement="bottom">
                                <i></i>
                            </antdUI.Popconfirm>
                            <antdUI.Button loading={ cPDLoading } onClick={ async () => {
                                if (! userInfo?.steam?.steamid && ! userInfo.uid) return $login() // 没登录
                                if (userInfo?.steam?.steamid && ! userInfo.uid) return window.location.href = '/profile/userConfig#login'
                                if (! userInfo?.steam?.steamid && userInfo.uid) return $toBindSteam()
                                // if (userInfo.real_name_status !== 1) return $toReal()
                                if (! userInfo?.steam?.transactionLink) return $bindSteamLink()
                                if (! userInfo?.steam?.apiKey) return $bindSteamApiKey()
                                await curPageDataDetail()
                            } } type={ 'primary' }>一键解析当页物品
                            </antdUI.Button>
                        </div>
                    </div> }
                    
                    { inventoryBoxType === 'myInventory' && <div className="put-shelf">
                        <div className="onSale">
                            <antdUI.Popconfirm icon={ false } title={ (<p
                                style={ { width: '200px', fontSize: '14px' } }>
                                自售上架会上架你的饰品到交易市场等待买家购买后发货
                            </p>) } placement="bottom">
                                <i></i>
                            </antdUI.Popconfirm>
                            <div onClick={ () => {
                                const { suspended } = userInfo
                                if (
                                    suspended?.endDate &&
                                    new Date(suspended?.endDate).getTime() >= new Date().getTime()
                                )
                                    return antdUI.message.warning(`您的账号处于暂挂状态，请等待暂挂结束(${ suspended?.endDate })再进行上架`);
                                if (! userInfo?.steam?.steamid && ! userInfo.uid) return $login() // 没登录
                                if (userInfo?.steam?.steamid && ! userInfo.uid) return window.location.href = '/profile/userConfig#login'
                                if (! userInfo?.steam?.steamid && userInfo.uid) return $toBindSteam()
                                // if (userInfo.real_name_status !== 1) return $toReal()
                                if (! userInfo?.steam?.transactionLink) return $bindSteamLink()
                                if (! userInfo?.steam?.apiKey) return $bindSteamApiKey()
                                setonShelfType('onSale')
                                setModalShow(true)
                            } } className="btn">自售上架
                            </div>
                        </div>
                        <div className="intermediary" style={ { display: 'none' } }>
                            <antdUI.Popconfirm icon={ false } title={ (<p
                                style={ { width: '200px', fontSize: '14px' } }>
                                中介交易上架需要买卖双方私下达成协议，
                                网站提供中介人使用个人支付宝手动提供中介服务，
                                减少支付宝通道成本
                            </p>) } placement="bottom">
                                <i></i>
                            </antdUI.Popconfirm>
                            <div onClick={ () => {
                                if (! userInfo?.steam?.steamid && ! userInfo.uid) return $login() // 没登录
                                if (userInfo?.steam?.steamid && ! userInfo.uid) return window.location.href = '/profile/userConfig#login'
                                if (! userInfo?.steam?.steamid && userInfo.uid) return $toBindSteam()
                                // if (userInfo.real_name_status !== 1) return $toReal()
                                if (! userInfo?.steam?.transactionLink) return $bindSteamLink()
                                if (! userInfo?.steam?.apiKey) return $bindSteamApiKey()
                                setModalShow(true);
                                setonShelfType('intermediary')
                            } } className="btn">中介交易上架
                            </div>
                        </div>
                    </div> }
                
                </div>
            </div>
            
            <ItemContainer inventoryBoxType={ inventoryBoxType } setMaterialData={ setMaterialData }
                           materialData={ materialData } setRarity={ childRef?.current?.setRarity }
                           handleCacheInventoryData={ handleCacheInventoryData }
                           setInventory={ setInventory } itemData={ inventoryData } itemType={ currentGame }
                           itemSelect={ itemSelect } setItemSelect={ setItemSelect } selectLength={ selectLength }
            />
            <antdUI.Spin tip="正在加载 ..."
                         style={ { margin: '150px auto 0 auto', width: '100%' } }
                         size="large" spinning={ inventoryLoading }/>
            
            
            <div className="inventory-pagination">
                {
                    currentGame === '730' ? total ? <antdUI.Pagination
                            onChange={ (currentPage, pageSize) => changePage(currentPage, pageSize) }
                            showSizeChanger={ false } current={ CurrentPage } defaultCurrent={ 1 }
                            total={ total } pageSize={ pageSize }/> : ''
                        : totalDo ? <antdUI.Pagination
                            onChange={ (currentPage, pageSize) => changePage(currentPage, pageSize) }
                            showSizeChanger={ false } current={ CurrentPage } defaultCurrent={ 1 }
                            total={ totalDo } pageSize={ pageSize }/> : ''
                }
                { inventoryLoding && <p className="inven-loading">还有库存正在加载中....</p> }
            </div>
            { modalShow && <OnShelf modalShow={ modalShow }
                                    handlePutSu={ handlePutSu }
                // setGame={ setGame }
                                    itemData={ inventoryData }
                // setCurrentPage={setCurrentPage}
                                    currentGame={ currentGame }
                                    setInventory={ setInventory }
                                    title={ onShelfTitle }
                                    onShelfType={ onShelfType }
                                    setOnShelfTitle={ setOnShelfTitle }
                                    setModalShow={ setModalShow }
                                    onShelfData={ itemSelect }
                                    handleCacheInventoryData={ handleCacheInventoryData }
            /> }
        </div>
    );
}

export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    }
)(InventoryContainer);

const _filterData = (target, type, _type) => {
    if (_type === 'hero') {
        return target.filter(item => item.category === 'Hero')[0].internal_name.match(type);
    } else {
        return target.filter(item => {
            if (type === 'CSGO_Type_Rifle') {
                return item.internal_name === type || item.internal_name === 'CSGO_Type_SniperRifle'
            } else {
                return item.internal_name === type
            }
        })[0]
    }
}
const _other = [ 'CSGO_Type_Tool', 'CSGO_Type_Spray', 'CSGO_Type_Collectible',
    'CSGO_Type_Ticket', 'CSGO_Tool_GiftTag', 'CSGO_Type_MusicKit', 'CSGO_Type_WeaponCase', 'Type_CustomPlayer', 'CSGO_Tool_Patch' ]

function inventortFilter({ type, data, _fil }) {
    let t = false, _v = _fil[0];
    return data.filter(item => {
        switch (type) {
            case 'itemType':
                switch (_v) {
                    case 'Type_Hands':
                        _fil[1] ? t = item.market_hash_name.match(_fil[1]) : _fil.forEach(v => t = _filterData(item.tags, v))
                        break;
                    case 'other':
                        _fil[1] ? _fil.forEach(v => t = _filterData(item.tags, v)) : t = _other.indexOf(item.tags.filter(_ => _.category === 'Type')[0].internal_name) !== -1
                        break;
                    default:
                        _fil.forEach(v => t = _filterData(item.tags, v));
                        break;
                }
                
                break;
            case 'hero':
                t = _filterData(item.tags, _fil[1], type);
                break; // dota2
            case 'quality':
                _v === 'unlimited' ? t = true : t = _filterData(item.tags, _v);
                break; // csgo dota2
            case 'rarity':
                _v === 'unlimited' ? t = true : t = _filterData(item.tags, _v === 'Type_CustomPlayer' ? _fil[1] : _v);
                break; // dota2 csgo
            case 'collection':
                _v === 'unlimited' ? t = true : t =
                    item.tags.filter(_ => {
                        if (! _fil[1]) {
                            return _.category === 'ItemSet' && _.localized_category_name === '收藏品'
                        } else {
                            return _.category === 'ItemSet' && _.internal_name === _fil[1]
                        }
                    })[0];
                break;
            case 'exterior':
                _v === 'unlimited' ? t = true : t = _filterData(item.tags, _v);
                break; //csgo
            case 'itemStatus':  // csgo dota2
                switch (_v) {
                    case 'all':
                        t = true;
                        break;
                    case 'tradable':
                        t = item.tradable;
                        break;
                    case 'sale':
                        t = item.tradable && item.putShelf !== 1 && item.putShelf !== 2;
                        break; // 可出售需要去掉已上架的
                    default:
                        break;
                }
                break;
            case 'searchContent':
                t = item.market_name.replace(/[|()]/g, '').match(_fil.replace(/[|()]/g, ''));
                break;  // csgo dota2
            default:
                break;
        }
        return t;
    })
}
