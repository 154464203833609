import React, {
    $tools,
    antdUI,
    useEffect,
    useState,
    useImperativeHandle,
} from "react";
import "./dota2.scss";
import FilterBar from "./component/data2/FilterBar";
import FilterImage from "./component/data2/FilterImage";

export default function Dota2(props) {
    const { filterChange, cRef, inputMaxPrice, inputMinPrice, setInputMaxPrice, setInputMinPrice, setCurrentPage } = props;
    let rememberData = JSON.parse(localStorage.getItem('rememberFilterd2'));
    const {
        LbarHeroHeadName,
        MbarHeroHeadName,
        ZbarHeroHeadName,
        heroName: rHeroName,
        heroCurrIdx: rHeroCurrIdx,
        searchContent: rSearchContent
    } = rememberData || {};
    const [ heroCurrIdx, setHeroCurrIdx ] = useState(rHeroCurrIdx || []),
        [ heroName, setHeroName ] = useState(rHeroName || ""),
        [ dQuality, setQuality ] = useState(""),
        [ dRarity, setRarity ] = useState(""),
        [ searchContent, setSearchContent ] = useState(rSearchContent || ""),
        [ searchChangeSt, setSearchChangeSt ] = useState(0),
        [ barMHeadName, setBarMHeadName ] = useState(MbarHeroHeadName || ""),
        [ barLHeadName, setBarLHeadName ] = useState(LbarHeroHeadName || ""),
        [ barZHeadName, setBarZHeadName ] = useState(ZbarHeroHeadName || ""),
        [ isInt, setIsInt ] = useState(false);

    const { Input } = antdUI;
    const { Search } = Input

    const [ priceSort, setPriceSort ] = useState(0), //0  无排序 1 升序，2 降序
        // [ minPrice, setMinPrice ] = useState(""),
        // [ maxPrice, setMaxPrice ] = useState(""),
        [ contentType, setContentType ] = useState("onSale"); //onSale:出售中   buying；求购中

    const changeHero = (tar) => {
        setHeroCurrIdx(tar);
    };

    // const resetSort = () => {
    //     setMaxPrice("");
    //     setMinPrice("");
    // };
    const priceRangeFilter = (minPrice = '', maxPrice = '') => {
        setInputMinPrice(minPrice);
        setInputMaxPrice(maxPrice);
        setCurrentPage(1);

        setTimeout(() => {
            filterChange({
                currentPage: 1,
                heroName,
                dRarity,
                dQuality,
                priceSort,
                maxPrice,
                minPrice,
            });
        }, 100)
    };

    const reSetOther = (type) => {
        switch (type) {
            case "敏捷":
                setBarLHeadName("");
                setBarZHeadName("");
                break;
            case "力量":
                setBarZHeadName("");
                setBarMHeadName("");
                break;
            case "智力":
                setBarMHeadName("");
                setBarLHeadName("");
                break;
            default:
                setBarMHeadName("");
                setBarLHeadName("");
                setBarZHeadName("");
        }
    };
    const { checkInputValNumberAndPoint } = $tools;

    const getArrow = () => {
        switch (priceSort) {
            case 2:
                return <i className="iconfont icon-cs-jt-xs asc"></i>;
            case 1:
                return <i className="iconfont icon-cs-jt-xs des"></i>;
            default:
                return <i className="iconfont icon-cs-jt-xs des"></i>;
        }
    };

    const sortSwitch = () => {
        if (priceSort === 1) {
            setPriceSort(2);
        } else if (priceSort === 2) {
            setPriceSort(0);
        } else {
            setPriceSort(1)
        }
    };

    const onSearch = () => {
        let num = ~~(Math.random() * 1000)
        setSearchChangeSt(num)
    }


    useEffect(() => {
        if (isInt) {
            // resetSort();
            let remFilterData = JSON.parse(localStorage.getItem('rememberFilterd2')) || {};
            remFilterData.heroName = heroName;
            remFilterData.heroCurrIdx = heroCurrIdx;
            remFilterData.searchContent = searchContent;
            localStorage.setItem('rememberFilterd2', JSON.stringify(remFilterData));
            filterChange({ heroName, dQuality, dRarity, priceSort, searchContent });
        } else {
            setIsInt(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ heroName, dQuality, dRarity, priceSort, searchChangeSt ]);


    useImperativeHandle(cRef, () => ({
        getData: (currentPage) => {
            filterChange({ heroName, dQuality, dRarity, currentPage, searchContent, priceSort });
        },
    }));


    useEffect(() => {
        if (inputMinPrice === '' && inputMaxPrice === '' && isInt) {
            filterChange({
                currentPage: 1,
                heroName,
                dRarity,
                dQuality,
                priceSort,
                maxPrice: inputMaxPrice,
                minPrice: inputMinPrice,
            });
        }
        // eslint-disable-next-line
    }, [inputMinPrice, inputMaxPrice])


    return (
        <div className="dota2-container">
            <div className="filter-bar-box">
                <FilterBar
                    setQuality={ setQuality }
                    curType="quality"
                    index={ 9 }
                    type="block"
                    headName="品质"
                />
                <FilterBar
                    setRarity={ setRarity }
                    curType="rarity"
                    type="block"
                    headName="稀有度"
                />
            </div>
            <div className="filter-image-box">
                <FilterImage
                    barHeroHeadName={ barLHeadName }
                    setBarHeroHeadName={ setBarLHeadName }
                    setHeroName={ setHeroName }
                    changeHero={ changeHero }
                    heroCurrIdx={ heroCurrIdx }
                    resetOther={ reSetOther }
                    index={ 0 }
                    headName="力量"
                />
                <FilterImage
                    barHeroHeadName={ barMHeadName }
                    setBarHeroHeadName={ setBarMHeadName }
                    setHeroName={ setHeroName }
                    changeHero={ changeHero }
                    heroCurrIdx={ heroCurrIdx }
                    resetOther={ reSetOther }
                    index={ 1 }
                    headName="敏捷"
                />
                <FilterImage
                    barHeroHeadName={ barZHeadName }
                    setBarHeroHeadName={ setBarZHeadName }
                    setHeroName={ setHeroName }
                    changeHero={ changeHero }
                    heroCurrIdx={ heroCurrIdx }
                    resetOther={ reSetOther }
                    index={ 2 }
                    headName="智力"
                />
            </div>
            <div className="trading-market-heads">
                <div className="heads-btns">
                    <div
                        onClick={ () => setContentType("onSale") }
                        className={ contentType === "onSale" ? "activeBtn" : "normalBtn" }
                    >
                        出售中
                    </div>
                    {/*<div*/ }
                    {/*  onClick={() => setContentType("buying")}*/ }
                    {/*  className={contentType === "buying" ? "activeBtn" : "normalBtn"}*/ }
                    {/*>*/ }
                    {/*  求购中*/ }
                    {/*</div>*/ }
                </div>


                <div className="heads-filter">
          <span className="filter-sort" style={ { color: priceSort === 0 && 'gray' } } onClick={ () => sortSwitch() }>
            按价格
              { getArrow() }
          </span>

                    <div className="filter-interval">
                        <Search
                            value={ searchContent }
                            placeholder='请输入饰品名称'
                            size='small'
                            allowClear
                            onSearch={ onSearch }
                            onChange={ (e) => {setSearchContent(e.target.value)} }
                            style={ { width: '180px', borderRadius: '5px' } }
                        >
                        </Search>
                        <div className="interval-input-group">
                            <antdUI.Input
                                allowClear
                                onChange={ (_) =>
                                    setInputMinPrice(checkInputValNumberAndPoint(_.target.value))
                                }
                                placeholder={'最低价'}
                                value={ inputMinPrice }
                                size="small"
                                style={ { width: "140px", borderRadius: "5px", zIndex: 2 } }
                                prefix="￥"
                            />
                            <span style={{margin: '0 3px'}}>-</span>
                            <antdUI.Input
                                allowClear
                                size="small"
                                onChange={ (_) =>
                                    setInputMaxPrice(checkInputValNumberAndPoint(_.target.value))
                                }
                                placeholder={'最高价'}
                                value={ inputMaxPrice }
                                style={ { width: "140px", borderRadius: "5px", zIndex: 2 } }
                                prefix="￥"
                            />
                            <div className="focus-panel">
                                <button onClick={() => { setInputMinPrice('');setInputMaxPrice('')} } >重置</button>
                                <button onClick={() => priceRangeFilter(inputMinPrice, inputMaxPrice) }>筛选</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
